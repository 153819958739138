import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { SpendPaymentSchedule } from "graphql/generated";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";
import { FormatMoney } from "helpers/format-money";
import DatePicker from "shared-components/date-picker";
import Divider from "shared-components/divider";
import { SnapIcon, SnapSelectMenu } from "suit";

type ImportCardProps = {
  selectedPaymentSchedule: (SpendPaymentSchedule & { deleted: boolean })[];
  categories: SnapSelectMenuOption[];
  setSelectedPaymentSchedule: React.Dispatch<
    React.SetStateAction<(SpendPaymentSchedule & { deleted: boolean })[]>
  >;
  CalculateFee: (amountDue: number) => number;
};

function ImportCard({
  selectedPaymentSchedule,
  categories,
  setSelectedPaymentSchedule,
  CalculateFee,
}: ImportCardProps) {
  return (
    <>
      {selectedPaymentSchedule.map((paymentSchedule, idx) => {
        let options: SnapSelectMenuOption[] = categories.map(
          (cat: SnapSelectMenuOption) => {
            return {
              ...cat,
              selected: paymentSchedule.budgetItemId === cat.value,
            };
          }
        );
        return (
          <div
            key={paymentSchedule.id}
            className="border mx-1 mt-4 rounded-lg p-4"
          >
            <div className="flex justify-between gap-4">
              <div>
                <p className="texet-sm font-medium">
                  {paymentSchedule.description}
                </p>
                <p className="text-xs font-semibold text-gray-500">
                  {paymentSchedule.note}
                </p>
              </div>
              <SnapIcon icon="trash-solid" color="#2563EB" />
            </div>
            <Divider isVisibleOnMobile />
            <DatePicker
              className="mt-4"
              label={"Due Date"}
              date={getDatePickerValue(paymentSchedule.dueDate ?? "")}
              setDate={(e) => {
                const newDate = setDatePickerValue(e);
                selectedPaymentSchedule.splice(idx, 1, {
                  ...paymentSchedule,
                  dueDate: newDate,
                });
                setSelectedPaymentSchedule([...selectedPaymentSchedule]);
              }}
            />
            <SnapSelectMenu
              className="flex flex-col mt-4"
              label="Reconcile To"
              placeholder="Select a budget"
              selectMenuOptions={[...options]}
              onSnap-select-menu-updated={(e) => {
                const selected = e.detail.find((option) => option.selected);
                selectedPaymentSchedule.splice(idx, 1, {
                  ...paymentSchedule,
                  budgetItemId: selected?.value,
                });
                setSelectedPaymentSchedule([...selectedPaymentSchedule]);
              }}
            />
            <Divider isVisibleOnMobile />
            <div className="flex mt-3">
              <p className="mr-auto text-xs font-bold text-gray-500">
                Collecting
              </p>
              <p className="text-sm font-medium text-green-600">
                {FormatMoney(paymentSchedule.amountDue || 0)}
              </p>
            </div>
            <div className="flex mt-3">
              <p className="mr-auto text-xs font-bold text-gray-500">Fees</p>
              <p className="text-sm font-medium">
                {FormatMoney(CalculateFee(paymentSchedule.amountDue ?? 0))}
              </p>
            </div>
            <div className="flex mt-3">
              <p className="mr-auto text-xs font-bold text-gray-500">
                Guardian Pays
              </p>
              <p className="text-sm font-medium">
                {FormatMoney(
                  (paymentSchedule.amountDue ?? 0) -
                    CalculateFee(paymentSchedule.amountDue ?? 0)
                )}
              </p>
            </div>
            <div className="flex mt-3">
              <p className="mr-auto text-xs font-bold text-gray-500">
                Optional?
              </p>
              <p className="text-sm font-medium">
                {paymentSchedule.isOptional ? "Optional" : "Required"}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default ImportCard;
