import { useQuery } from "@apollo/client";
import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import { SpendReconciledBudgetTransaction } from "graphql/generated";
import { GET_RECONCILED_TRANSACTIONS } from "graphql/queries/budgets";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import {
  getTransactionTitle,
  getTransactionType,
  legacyTrx,
} from "helpers/transaction";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import TableRowLabelValue from "shared-components/table-row-label-value";
import { SnapBadge, SnapTable } from "suit";
import { budgetItemDetails } from "types/budget-summary";
import CustomModal from ".";

type BudgetItemDetailsProps = {
  isBudgetItemOpen: boolean;
  budgetItemToggle: () => void;
  levelType: "program" | "group";
  budgetItem: budgetItemDetails;
};

function BudgetItemDetails({
  isBudgetItemOpen,
  budgetItemToggle,
  levelType,
  budgetItem,
}: BudgetItemDetailsProps) {
  const Program = useContext(ProgramContext);
  const Group = useContext(GroupContext);
  const Display = useContext(DisplayContext);

  const containerStyle = "flex justify-between my-2";
  const mobileLabelStyle = "text-gray-500 text-xs font-semibold";
  const mobileValueStyle = "text-sm";

  const { loading, data } = useQuery(GET_RECONCILED_TRANSACTIONS, {
    variables: {
      spendBudgetId: budgetItem.budgetId,
      spendGroupId: levelType === "group" ? Group?.activeGroup?.id : undefined,
    },
  });

  const [recTransactions, setRecTransactions] = useState<
    SpendReconciledBudgetTransaction[]
  >([]);

  useEffect(() => {
    if (data && data.spendBudget) {
      setRecTransactions(data.spendBudget.budget.reconciledTransactions);
    }
  }, [loading, data]);

  let nameLabel = Group?.activeGroup?.name;
  if (levelType === "program") {
    nameLabel =
      Program?.organization?.nickname || Program?.organization?.legalName;
  }
  return (
    <CustomModal
      isOpen={isBudgetItemOpen}
      toggle={budgetItemToggle}
      title={`${budgetItem.type} Budget Item Details`}
      header={budgetItem.budgetItemName}
      customStyle="lg:mt-10 lg:h-[600px] overflow-auto"
    >
      <div className="modal-card">
        <table className="w-full lg:w-[50%]">
          <tbody>
            <TableRowLabelValue
              label={levelType}
              value={nameLabel}
              labelStyle="capitalize"
            />
            {levelType === "group" && (
              <TableRowLabelValue
                label="Season"
                value={
                  Group?.activeGroup?.latestSeason?.name ??
                  Group?.activeGroup?.seasons?.at(0)?.name
                }
                className="mt-4"
              />
            )}
            <TableRowLabelValue
              label="Category"
              value={budgetItem.category}
              className="mt-4"
            />
            <TableRowLabelValue
              label="Due Date"
              value={FormatDate(
                budgetItem.dueDate,
                DateFormatSupported.Numbers
              )}
              className="mt-4"
            />
            <TableRowLabelValue
              label="Budget Amount"
              value={FormatMoney(budgetItem.budgetAmount)}
              className="mt-4"
            />
            <TableRowLabelValue
              label="Reconciled Amount"
              value={FormatMoney(budgetItem.reconciledAmount)}
              className="font-bold mt-4"
              labelStyle="font-bold"
            />
          </tbody>
        </table>
        <Divider isVisibleOnMobile />
        <p className="mt-4 font-medium">Reconciled Transactions</p>
        {Display?.isMobile ? (
          <>
            {recTransactions &&
              recTransactions.map((rt, idx) => {
                const trx = rt.reconciledTransaction?.transaction;
                const legTrx = rt.reconciledTransaction?.legacyTransaction;
                let type = "";
                let description = "";
                let status = "";
                let createdAt = "";
                let dateSettled = "";
                if (trx) {
                  type = getTransactionType(trx ?? {});
                  description = getTransactionTitle(trx);
                  let historyLength = trx?.history?.length ?? 0;
                  status = trx?.history?.at(historyLength - 1)?.status ?? "";
                  createdAt = trx?.history?.at(0)?.date ?? "";
                  let settled = trx?.history?.find(
                    (trans) => trans?.status === "Settled"
                  );
                  dateSettled = settled?.date ?? "";
                } else if (legTrx) {
                  type = legacyTrx.getType(
                    legTrx,
                    Group?.activeGroup?.legacyAccountId!
                  );
                  description = legacyTrx.getTitle(
                    legTrx,
                    Group?.activeGroup?.legacyAccountId!
                  );
                  status = legTrx.status;
                  createdAt = new Date(legTrx.created).toISOString();
                  dateSettled = new Date(legTrx.effective).toISOString();
                }
                return (
                  <div
                    className="mt-4 border rounded-lg p-4"
                    key={rt.reconciledTransaction?.id ?? "" + idx}
                  >
                    <p className="font-medium">{type}</p>
                    <Divider isVisibleOnMobile className="mt-2" />
                    <p className="text-xs font-semibold mt-2 text-gray-500">
                      Description
                    </p>
                    <p className="text-blue-500 text-sm capitalize">
                      {description}
                    </p>
                    <Divider isVisibleOnMobile className="mt-2" />
                    <div className={containerStyle}>
                      <p className={mobileLabelStyle}>Status</p>
                      <SnapBadge
                        color={getBadgeColor(status)}
                        className="capitalize"
                      >
                        {status}
                      </SnapBadge>
                    </div>
                    <div className={containerStyle}>
                      <p className={mobileLabelStyle}>Date Created</p>
                      <p className={mobileValueStyle}>
                        {FormatDate(
                          createdAt ?? "",
                          DateFormatSupported.Numbers
                        )}
                      </p>
                    </div>
                    <div className={containerStyle}>
                      <p className={mobileLabelStyle}>Date Settled</p>
                      <p className={mobileValueStyle}>
                        {FormatDate(
                          dateSettled ?? "",
                          DateFormatSupported.Numbers
                        )}
                      </p>
                    </div>
                    <div className={containerStyle}>
                      <p className={mobileLabelStyle}>Amount</p>
                      <p className={mobileValueStyle}>
                        {FormatMoney(rt.reconciledTransaction?.amount ?? 0)}
                      </p>
                    </div>
                  </div>
                );
              })}
          </>
        ) : (
          <SnapTable>
            <table className="ui celled table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th colSpan={2} align="center">
                    Description
                  </th>
                  <th align="right">Status</th>
                  <th align="right">Date Created</th>
                  <th align="right">Date Settled</th>
                  <th className="action-cell">Amount</th>
                </tr>
              </thead>
              <tbody>
                {recTransactions &&
                  recTransactions.map((rt, idx) => {
                    const trx = rt.reconciledTransaction?.transaction;
                    const legTrx = rt.reconciledTransaction?.legacyTransaction;
                    let type = "";
                    let description = "";
                    let status = "";
                    let createdAt = "";
                    let dateSettled = "";
                    if (trx) {
                      type = getTransactionType(trx ?? {});
                      description = getTransactionTitle(trx);
                      let historyLength = trx?.history?.length ?? 0;
                      status =
                        trx?.history?.at(historyLength - 1)?.status ?? "";
                      createdAt = trx?.history?.at(0)?.date ?? "";
                      let settled = trx?.history?.find(
                        (trans) => trans?.status === "Settled"
                      );
                      dateSettled = settled?.date ?? "";
                    } else if (legTrx) {
                      type = legacyTrx.getType(
                        legTrx,
                        Group?.activeGroup?.legacyAccountId!
                      );
                      description = legacyTrx.getTitle(
                        legTrx,
                        Group?.activeGroup?.legacyAccountId!
                      );
                      status = legTrx.status;
                      createdAt = new Date(legTrx.created).toISOString();
                      dateSettled = new Date(legTrx.effective).toISOString();
                    }

                    return (
                      <tr key={rt.reconciledTransaction?.id ?? "" + idx}>
                        <td className="py-4">{type}</td>
                        <td colSpan={2} className="capitalize" align="center">
                          {description}
                        </td>
                        <td align="center">
                          <SnapBadge
                            className="flex justify-end"
                            color={getBadgeColor(status)}
                          >
                            <p className="w-10">{status}</p>
                          </SnapBadge>
                        </td>
                        <td align="right">
                          {FormatDate(
                            createdAt ?? "",
                            DateFormatSupported.Numbers
                          )}
                        </td>
                        <td align="right">
                          {FormatDate(
                            dateSettled ?? "",
                            DateFormatSupported.Numbers
                          )}
                        </td>
                        <td align="right">
                          {FormatMoney(rt.reconciledTransaction?.amount ?? 0)}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </SnapTable>
        )}
      </div>
    </CustomModal>
  );
}

export default BudgetItemDetails;
