import { ActiveUser } from "hooks/use-user";
import ParticipantDetails from "pages/people/participants/participant-details";
import Program from "pages/people/program";
import { useState } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";
import { Roles } from "types/roles-permissions";
import App from "./App";
import Dashboard from "./pages/dashboard";
import DisplayPage from "./pages/display-page";
import TeamBanking from "./pages/groups/banking";
import Budget from "./pages/groups/budget";
import Collections from "./pages/groups/collections";
import DebitCards from "./pages/groups/debit-cards";
import Groups from "./pages/groups/list";
import Settings from "./pages/groups/settings";
import Staff from "./pages/groups/staff";
import ParentInvoices from "./pages/invoices";
import PastDue from "./pages/past-due";
import PaymentMethods from "./pages/payment-methods";
import Group from "./pages/people/group";
import Participants from "./pages/people/participants";
import AllAcounts from "./pages/programs/all-accounts";
import AllBudgets from "./pages/programs/all-budgets";
import ProgramBanking from "./pages/programs/banking";
import ProgramBudget from "./pages/programs/budget";
import ProgramDebitCards from "./pages/programs/debit-cards";
import Invoices from "./pages/reporting/invoices";
import Transactions from "./pages/reporting/transactions";
import Budgeting from "./pages/settings/budgeting";
import NotificationSettings from "./pages/settings/notification-settings";
// import OrganizationSettings from "./pages/settings/organization-settings";
import SignUpAgreement from "./pages/settings/sign-up-agreements";
import OrganizationSettings from "pages/settings/organization-settings";
import ProgramApprovals from "pages/programs/approvals";
import GroupApprovals from "pages/groups/approvals";
import PieChart from "pages/programs/pie-chart";

type PermissionRouteObject = RouteObject & { roleRequirement?: string[] };

const default_routes = (): PermissionRouteObject[] => {
  return [
    {
      element: <Navigate to="/dashboard" />,
      roleRequirement: [
        Roles.programObserver,
        Roles.programStaff,
        Roles.programAdmin,
        Roles.guardian,
      ],
      index: true,
    },
    {
      element: <Navigate to="/group" />,
      roleRequirement: [
        Roles.groupObserver,
        Roles.groupStaff,
        Roles.groupStaffWithProgramBankRead,
      ],
      index: true,
    },
    {
      element: (
        <DisplayPage
          title="Dashboard"
          Tabs={[
            {
              ParentName: "dashboard",
              ChildName: "",
              name: "",
              value: "",
              selected: false,
              text: "",
            },
          ]}
        />
      ),
      path: "/dashboard",
      roleRequirement: [
        Roles.programObserver,
        Roles.programStaff,
        Roles.programAdmin,
        Roles.guardian,
      ],
      children: [{ element: <Dashboard />, path: "/dashboard" }],
    },
    {
      element: (
        <DisplayPage
          title="Programs"
          Tabs={[
            {
              ParentName: "programs",
              ChildName: "banking",
              name: "Program Banking",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "programs",
              ChildName: "debit-cards",
              name: "Program Debit Cards",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "programs",
              ChildName: "budget",
              name: "Program Budget",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "programs",
              ChildName: "pie-chart",
              name: "Program Pie Chart",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "programs",
              ChildName: "all-accounts",
              name: "All Accounts",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "programs",
              ChildName: "all-budgets",
              name: "All Budgets",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "programs",
              ChildName: "approvals",
              name: "Approvals",
              value: "",
              selected: false,
              text: "",
            },
          ]}
        />
      ),
      path: "/programs",
      roleRequirement: [
        Roles.programObserver,
        Roles.programStaff,
        Roles.programAdmin,
        Roles.groupStaffWithProgramBankRead,
      ],
      children: [
        {
          element: <ProgramBanking />,
          path: "/programs/banking",
          index: true,
        },
        { element: <ProgramDebitCards />, path: "/programs/debit-cards" },
        { element: <ProgramBudget />, path: "/programs/budget" },
        { element: <PieChart />, path: "/programs/pie-chart" },
        { element: <AllAcounts />, path: "/programs/all-accounts" },
        { element: <AllBudgets />, path: "/programs/all-budgets" },
        { element: <ProgramApprovals />, path: "/programs/approvals" },
      ],
    },
    {
      element: (
        <DisplayPage
          title="Groups"
          Tabs={[
            {
              ParentName: "groups",
              ChildName: "active",
              name: "Active",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "groups",
              ChildName: "archived",
              name: "Archived",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "groups",
              ChildName: "group-banking",
              name: "Group Banking",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "groups",
              ChildName: "collections",
              name: "Collections",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "groups",
              ChildName: "debit-cards",
              name: "Debit Cards",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "groups",
              ChildName: "budget",
              name: "Budget",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "groups",
              ChildName: "approvals",
              name: "Approvals",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "groups",
              ChildName: "staff",
              name: "Staff",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "groups",
              ChildName: "settings",
              name: "Settings",
              value: "",
              selected: false,
              text: "",
            },
          ]}
        />
      ),
      path: "/groups",
      roleRequirement: [
        Roles.programObserver,
        Roles.programStaff,
        Roles.programAdmin,
      ],
      children: [
        {
          element: <Groups type="Active" />,
          path: "/groups/active",
          index: true,
        },
        { element: <Groups type="Archived" />, path: "/groups/archived" },
        { element: <TeamBanking />, path: "/groups/group-banking" },
        { element: <Collections />, path: "/groups/collections" },
        { element: <DebitCards />, path: "/groups/debit-cards" },
        { element: <Budget />, path: "/groups/budget" },
        { element: <GroupApprovals />, path: "/groups/approvals" },
        { element: <Staff />, path: "/groups/staff" },
        { element: <Settings />, path: "/groups/settings" },
        {
          element: <ParticipantDetails />,
          path: "/groups/participant-details/:id",
        },
      ],
    },
    {
      element: (
        <DisplayPage
          title=""
          Tabs={[
            {
              ParentName: "group",
              ChildName: "group-banking",
              name: "Group Banking",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "group",
              ChildName: "collections",
              name: "Collections",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "group",
              ChildName: "debit-cards",
              name: "Debit Cards",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "group",
              ChildName: "budget",
              name: "Budget",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "group",
              ChildName: "staff",
              name: "Staff",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "group",
              ChildName: "settings",
              name: "Settings",
              value: "",
              selected: false,
              text: "",
            },
          ]}
        />
      ),
      path: "/group",
      roleRequirement: [
        Roles.groupObserver,
        Roles.groupStaff,
        Roles.groupStaffWithProgramBankRead,
      ],
      children: [
        { element: <TeamBanking />, path: "/group/group-banking", index: true },
        { element: <Collections />, path: "/group/collections" },
        { element: <DebitCards />, path: "/group/debit-cards" },
        { element: <Budget />, path: "/group/budget" },
        { element: <Staff />, path: "/group/staff" },
        { element: <Settings />, path: "/group/settings" },
        {
          element: <ParticipantDetails />,
          path: "/group/participant-details/:id",
        },
      ],
    },
    {
      element: (
        <DisplayPage
          title="People"
          Tabs={[
            {
              ParentName: "people",
              ChildName: "programs",
              name: "Program",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "people",
              ChildName: "group",
              name: "Group",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "people",
              ChildName: "participants",
              name: "Participants",
              value: "",
              selected: false,
              text: "",
            },
          ]}
        />
      ),
      path: "/people",
      roleRequirement: [
        Roles.programObserver,
        Roles.programStaff,
        Roles.programAdmin,
      ],
      children: [
        { element: <Program />, path: "/people/programs", index: true },
        { element: <Participants />, path: "/people/participants" },
        { element: <Group />, path: "/people/group" },
        {
          element: <ParticipantDetails />,
          path: "/people/participants/participant-details/:id",
        },
      ],
    },
    {
      element: (
        <DisplayPage
          title="Past Due Invoices"
          Tabs={[
            {
              ParentName: "past-due",
              ChildName: "",
              name: "",
              value: "",
              selected: false,
              text: "",
            },
          ]}
        />
      ),
      path: "/past-due",
      roleRequirement: [
        Roles.programObserver,
        Roles.programStaff,
        Roles.programAdmin,
        Roles.groupStaffWithProgramBankRead,
        Roles.groupObserver,
        Roles.groupStaff,
      ],
      children: [{ element: <PastDue />, path: "/past-due" }],
    },
    {
      element: (
        <DisplayPage
          title="Reporting"
          Tabs={[
            {
              ParentName: "reporting",
              ChildName: "invoices",
              name: "Invoices",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "reporting",
              ChildName: "transactions",
              name: "Transactions",
              value: "",
              selected: false,
              text: "",
            },
          ]}
        />
      ),
      path: "/reporting",
      roleRequirement: [
        Roles.programObserver,
        Roles.programStaff,
        Roles.programAdmin,
        Roles.groupStaffWithProgramBankRead,
        Roles.groupObserver,
        Roles.groupStaff,
      ],
      children: [
        { element: <Invoices />, path: "/reporting/invoices", index: true },
        { element: <Transactions />, path: "/reporting/transactions" },
      ],
    },
    {
      element: (
        <DisplayPage
          title="Settings"
          Tabs={[
            {
              ParentName: "settings",
              ChildName: "organization-settings",
              name: "Organization Settings",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "settings",
              ChildName: "notification-settings",
              name: "Notification Settings",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "settings",
              ChildName: "sign-up-agreement",
              name: "Sign Up Agreement",
              value: "",
              selected: false,
              text: "",
            },
            {
              ParentName: "settings",
              ChildName: "budgeting",
              name: "Budgeting",
              value: "",
              selected: false,
              text: "",
            },
          ]}
        />
      ),
      path: "/settings",
      roleRequirement: [
        Roles.programObserver,
        Roles.programStaff,
        Roles.programAdmin,
        Roles.groupStaff,
        Roles.groupStaffWithProgramBankRead,
      ],
      children: [
        {
          element: <OrganizationSettings />,
          path: "/settings/organization-settings",
          index: true,
        },
        {
          element: <NotificationSettings />,
          path: "/settings/notification-settings",
        },
        { element: <SignUpAgreement />, path: "/settings/sign-up-agreement" },
        { element: <Budgeting />, path: "/settings/budgeting" },
      ],
    },
    {
      element: (
        <DisplayPage
          title="Invoices"
          Tabs={[
            {
              ParentName: "Invoices",
              ChildName: "",
              name: "",
              value: "",
              selected: false,
              text: "",
            },
          ]}
        />
      ),
      path: "/invoices",
      roleRequirement: [Roles.guardian],
      children: [
        { element: <ParentInvoices />, path: "/invoices", index: true },
      ],
    },
    {
      element: (
        <DisplayPage
          title="Payment Methods"
          Tabs={[
            {
              ParentName: "payment-methods",
              ChildName: "",
              name: "",
              value: "",
              selected: false,
              text: "",
            },
          ]}
        />
      ),
      path: "/payment-methods",
      roleRequirement: [Roles.guardian],
      children: [
        {
          element: <PaymentMethods />,
          path: "/payment-methods",
          index: true,
        },
      ],
    },
    {
      element: <Navigate to="/group/collections" />,
      roleRequirement: [Roles.groupStaffWithProgramBankRead],
      path: "*",
    },
    {
      element: <Navigate to="/dashboard" />,
      roleRequirement: [
        Roles.programAdmin,
        Roles.programObserver,
        Roles.programStaff,
        Roles.guardian,
      ],
      path: "*",
    },
    {
      element: <Navigate to="/group" />,
      roleRequirement: [Roles.groupObserver, Roles.groupStaff],
      path: "*",
    },
  ];
};

function AppRouter() {
  const [routes, setRoutes] = useState<
    (RouteObject & { roleRequirement?: string[] })[]
  >([...default_routes()]);

  const updateChildren = (user: ActiveUser) => {
    const newroutes = default_routes().filter(
      (childRoute) =>
        childRoute.roleRequirement == null ||
        childRoute.roleRequirement?.some((r) => r === user.getRole())
    );
    setRoutes(newroutes);
  };

  const routeGen = [
    {
      element: <App updateRoutes={updateChildren} />,
      children: [...routes],
      path: "/",
    },
  ];
  return useRoutes(routeGen);
}

export default AppRouter;
