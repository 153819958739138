import { useSplitClient } from "@splitsoftware/splitio-react";

const activeSplits = {
  thirdPtOutage: "thirdparty-outage-banner",
  remGrossAmt: "spe-ffe-remove-gross-amount",
  se456: "SE-456-UX-Audit",
  se503: "SE-503-Toggle-external-transfer",
  se518: "SE-518-Adding-Notes-BTN",
  se579: "SE-579-Not-Saving-Publish",
  se698: "SE-698-Performance-Banner",
  spe1288: "SPE-1288-Dual-Approval",
  spe1554: "SPE-1554-BannerWarning",
  spe1741: "SPE-1741-stripe-webhooks",
  spe1754: "SPE-1754-Filter-Transactions-Date-Range",
  se1650: "SE-1650-Enable-Legacy-Attachment-Download",
  se1682: "SE-1682-HubspotWidgetController",
};

export type SplitIOType = {
  isReady: Promise<void> | undefined;
  getTreatments: any;
  getTreatment: (treatment: string, attributes?: any) => string;
  isTreatmentOn: (treatment: string, attributes?: any) => boolean;
  act: typeof activeSplits;
};
export default function useSplitIO(
  programId?: string | null,
  userId?: string | null
): SplitIOType {
  const client = useSplitClient().client;
  const getTreatment = (treatment: string, attributes?: any): string => {
    if (attributes == null) {
      attributes = {};
    }
    if (programId) {
      attributes["organizationId"] = programId;
    }
    if (userId) {
      attributes["spendId"] = userId;
    }
    return !!client
      ? client.getTreatments([treatment], attributes)[treatment]
      : "control";
  };
  const isTreatmentOn = (treatment: string, attributes?: any): boolean => {
    if (attributes == null) {
      attributes = {};
    }
    if (programId) {
      attributes["organizationId"] = programId;
    }
    if (userId) {
      attributes["spendId"] = userId;
    }
    return !!client
      ? client.getTreatments([treatment], attributes)[treatment] === "on"
      : false;
  };

  const getTreatments = (desiredSplits: string[], attributes?: any) => {
    if (attributes == null) {
      attributes = {};
    }
    if (programId) {
      attributes["organizationId"] = programId;
    }
    if (userId) {
      attributes["spendId"] = userId;
    }
    return client
      ? {
          isReady: client.ready(),
          treatments: client.getTreatments(desiredSplits, attributes) as any,
        }
      : { isReady: new Promise<void>(() => true), treatments: {} as any };
  };
  return {
    isReady: client?.ready(),
    getTreatment,
    getTreatments,
    isTreatmentOn,
    act: activeSplits,
  };
}
