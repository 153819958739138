import CustomModal, { BtnType } from "shared-components/modal";
import useModal from "hooks/use-modal";
import { SnapActionSheet, SnapButton } from "suit";
import {
  GET_GUARDIAN_HIGHLIGHT_2,
  USER_BANK_ACCOUNTS_LIST,
} from "graphql/queries/user";
import { useContext, useEffect, useState } from "react";
import {
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  useExternalAccountsListQuery,
  usePaymentsApiCustomerMutation,
  useSpendPaymentsCardDetachMutation,
  useSpendUserBankAccessTokenDeleteMutation,
} from "graphql/generated";
import { ChangePaymentModal } from "shared-components/modal/change-payment/change-payment-modal";
import VerticalLabelValue from "shared-components/vertical-label-value";
import Divider from "shared-components/divider";
import FloatingActionBtn from "shared-components/floating-action-btn";
import { MakePaymentModal } from "shared-components/modal/make-payment/make-payment-modal";
import {
  PaymentConfirmationModal,
  StripeResponse,
} from "shared-components/modal/payment-confirmation-modal";
import DisplayContext from "context/display-context";
import FixedModal from "shared-components/fixed-modal/fixed-modal";
import { extractBankLabel } from "helpers/banking";
import {
  GUARDIAN_INVOICES_V2,
  INVOICE_AUTOPAY,
} from "graphql/queries/invoices";
import AuthorizeAutoPay from "shared-components/modal/authorize-autopay";
import { AddPaymentModal } from "shared-components/modal/add-payment/add-payment-modal";
import Spinner from "shared-components/spinner";
import { Amex, Bank, Discover, Generic, MasterCard, Visa } from "assets";
import LinkBank from "shared-components/banking/link-bank";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import ToastContext from "context/toast-context";

function PaymentMethods() {
  const display = useContext(DisplayContext);
  const toast = useContext(ToastContext);
  const [fabOpen, setFabOpen] = useState(false);
  const { toggle, isOpen } = useModal();
  const { toggle: addPmOpen, isOpen: isAddPmOpen } = useModal();
  const { isOpen: authorizeAutoPayOpen, toggle: authorizeAutoPayToggle } =
    useModal();
  const { toggle: toggleConfirmDelete, isOpen: confirmDeleteOpen } = useModal();
  // eslint-disable-next-line
  const [stripeResponse, setStripeState] = useState<StripeResponse>({
    redirect_status: "spend_init",
  });
  const [plaidConfig, setPlaidConfig] = useState<
    PlaidLinkOptions & { isSuccess?: boolean }
  >({
    onSuccess: (public_token, metadata): boolean => {
      return false;
    },
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: "",
  });
  const { open, ready } = usePlaidLink(plaidConfig);
  const openPlaid = () => {
    if (ready) {
      open();
    }
  };

  const { toggle: ChangePaymentModalToggle, isOpen: PaymentModalIsOpen } =
    useModal();
  const { data: dataList, loading: loadingList } =
    useExternalAccountsListQuery();
  const [getCards, { data: userCards, loading: loadingUserCards }] =
    usePaymentsApiCustomerMutation();
  const [deleteUserBank] = useSpendUserBankAccessTokenDeleteMutation({
    refetchQueries: [
      {
        query: USER_BANK_ACCOUNTS_LIST,
      },
      {
        query: INVOICE_AUTOPAY,
      },
      {
        query: GUARDIAN_INVOICES_V2,
        fetchPolicy: "network-only",
      },
      {
        query: GET_GUARDIAN_HIGHLIGHT_2,
      },
    ],
  });
  const [deleteCard, { data: delData, loading: delLoading }] =
    useSpendPaymentsCardDetachMutation({
      refetchQueries: [
        {
          query: INVOICE_AUTOPAY,
        },
        {
          query: GUARDIAN_INVOICES_V2,
          fetchPolicy: "network-only",
        },
        {
          query: GET_GUARDIAN_HIGHLIGHT_2,
        },
      ],
    });

  const [userBankAccounts, setUserBankAccounts] = useState<SpendBankAccount[]>(
    []
  );
  const [userBankCards, setUserBankCards] = useState<
    PaymentsApiCustomerPaymentMethod[]
  >([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    | { id: string; name: string; type: "card" | "ach"; entityId?: string }
    | undefined
  >();
  useEffect(() => {
    getCards();
  }, [getCards]);
  useEffect(() => {
    if (!delLoading && delData) {
      getCards();
    }
  }, [delData, delLoading, getCards]);
  useEffect(() => {
    if (!loadingList && dataList && dataList.spendUserBankAccountsList) {
      setUserBankAccounts(
        dataList.spendUserBankAccountsList.externalAccounts || []
      );
    }
  }, [dataList, loadingList]);

  useEffect(() => {
    if (!loadingUserCards && userCards && userCards.paymentsApiCustomer) {
      setUserBankCards(
        userCards.paymentsApiCustomer
          .paymentMethods as PaymentsApiCustomerPaymentMethod[]
      );
    }
  }, [userCards, loadingUserCards]);

  const handleDeleteBank = (bankAccount: SpendBankAccount) => {
    setSelectedPaymentMethod({
      id: bankAccount.id || "",
      name: extractBankLabel(bankAccount) || "Ach Undefined",
      type: "ach",
      entityId: bankAccount.entityId,
    });
    toggleConfirmDelete();
  };
  const handleDeleteCard = (card: PaymentsApiCustomerPaymentMethod) => {
    setSelectedPaymentMethod({
      id: card.id,
      name: card.identifier,
      type: "card",
    });
    toggleConfirmDelete();
  };
  const btn1: BtnType = {
    text: "Change Payment Method",
    onClick: () => {
      toggle();
      ChangePaymentModalToggle();
    },
  };

  const btn2: BtnType = { text: "Cancel", onClick: toggle };

  return (
    <div className="card">
      <div className="lg:flex hidden justify-end">
        <SnapButton
          variant="primary"
          onClick={() => {
            addPmOpen();
          }}
        >
          + Add Payment Method
        </SnapButton>
      </div>
      {userBankAccounts.length === 0 && userBankCards.length === 0 && (
        <p className="mt-5">No payment methods available </p>
      )}
      {(loadingList || loadingUserCards) && <Spinner />}
      {userBankAccounts &&
        userBankAccounts.map((bankAccount, idx) => {
          return (
            <div
              key={`users_bank_account_${idx}`}
              className="border border-gray-200 py-2 px-4 rounded-lg mt-4 flex justify-between"
            >
              <div className="flex">
                <img
                  src={Bank}
                  alt="Bank immage for plaid"
                  className="h-20 w-20"
                />
                <p className="text-base font-semibold mr-auto self-center ml-5">
                  {extractBankLabel(bankAccount)}
                </p>
              </div>

              <div className="flex pt-2 items-center">
                {bankAccount.status === "pending_manual_verification" ||
                bankAccount.status === "item_login_required" ? (
                  <>
                    <LinkBank
                      openPlaid={openPlaid}
                      setPlaidConfig={setPlaidConfig}
                      toast={toast}
                      type={"user"}
                      hasLabel={false}
                      labelText={"Verify Account"}
                      groupId={undefined}
                      externalAuthId={bankAccount.entityId}
                    />
                    <SnapButton
                      variant="danger"
                      onClick={() => handleDeleteBank(bankAccount)}
                    >
                      Delete
                    </SnapButton>
                  </>
                ) : (
                  <SnapButton
                    variant="danger"
                    onClick={() => handleDeleteBank(bankAccount)}
                  >
                    Delete
                  </SnapButton>
                )}
              </div>
            </div>
          );
        })}
      {userBankCards &&
        userBankCards.map((card, idx) => {
          let expiration = `${card.expiration?.month}/${card.expiration?.year}`;
          let image = Generic;
          switch (card.brand) {
            case "visa":
              image = Visa;
              break;
            case "discover":
              image = Discover;
              break;
            case "mastercard":
              image = MasterCard;
              break;
            case "amex":
              image = Amex;
              break;
            default:
              image = Generic;
              break;
          }
          return (
            <div
              key={`users_debit_card_${idx}`}
              className="border border-gray-200 px-4 rounded-lg mt-4 lg:flex justify-between"
            >
              <div className="flex">
                <img src={image} alt="Card Logo" className="h-20 w-20" />
                <p className="text-base font-semibold mr-auto self-center ml-5 capitalize">
                  {card.identifier}
                </p>
              </div>
              <div className="flex flex-col-reverse lg:flex-row">
                <VerticalLabelValue
                  label={"Expiration Date"}
                  value={expiration}
                  customContainerStyle="flex w-full lg:text-right lg:w-auto lg:flex-col lg:mb-5 mb-0 py-2 lg:border-t-0 border-t-2 mt-3 border-gray-100 lg:mb-0 lg:border-r lg:pr-4 lg:mr-4"
                ></VerticalLabelValue>
                <div className="flex justify-end -mt-14 lg:mt-0 lg:self-center">
                  <SnapButton
                    variant="danger"
                    onClick={() => handleDeleteCard(card)}
                  >
                    Delete
                  </SnapButton>
                </div>
              </div>
            </div>
          );
        })}
      <FloatingActionBtn
        onClick={() => setFabOpen(true)}
        icon={"dots-horizontal-solid"}
      />
      {fabOpen && (
        <SnapActionSheet onClick={() => setFabOpen(false)}>
          <Divider isVisibleOnMobile className="mb-4" />
          <div className="mx-4">
            <SnapButton
              variant="primary"
              size="md"
              onClick={() => {
                setFabOpen(false);
                authorizeAutoPayToggle();
              }}
              fullWidth
              className="mb-4"
            >
              Authorize AutoPay
            </SnapButton>
            <SnapButton
              variant="primary"
              size="md"
              onClick={() => {
                setFabOpen(false);
                addPmOpen();
              }}
              fullWidth
              className="mb-4"
            >
              Add Payment Method
            </SnapButton>
            <SnapButton
              variant="primary"
              size="md"
              onClick={() => {
                setFabOpen(false);
                ChangePaymentModalToggle();
              }}
              fullWidth
              className="mb-4"
            >
              Change Payment Method
            </SnapButton>
            <SnapButton
              variant="primary"
              size="md"
              onClick={() => {
                setFabOpen(false);
                display?.setMakePaymentDataModalOpen({ isOpen: true });
              }}
              fullWidth
              className="mb-4"
            >
              Make Payment
            </SnapButton>
            {stripeResponse.redirect_from != null &&
              ["spend_init", "success"].includes(
                stripeResponse.redirect_status
              ) && <PaymentConfirmationModal stripeResponse={stripeResponse} />}
          </div>
        </SnapActionSheet>
      )}
      {display?.makePaymentDataModalOpen.isOpen && (
        <MakePaymentModal
          setFabOpen={setFabOpen}
          makePaymentDataModalOpen={display?.makePaymentDataModalOpen}
          setMakePaymentDataModalOpen={display?.setMakePaymentDataModalOpen}
        />
      )}
      {isAddPmOpen && (
        <AddPaymentModal isOpen={isAddPmOpen} toggle={addPmOpen} />
      )}

      {selectedPaymentMethod && (
        <CustomModal
          isOpen={isOpen}
          toggle={toggle}
          title={"Payment Method Associated With Unpaid Invoices"}
          btn1={btn1}
          btn2={btn2}
        >
          <div className="modal-card">
            <p className="text-base font-semibold">
              {`${selectedPaymentMethod?.name} cannot be removed because it is
            currently associated with unpaid invoices.`}
            </p>
            <p className="text-sm font-normal text-gray-500 pt-4">
              {`Click the "Change Payment Method" button below to specify a
            different payment method for your unpaid invoices. Then you will be
            able to remove ${selectedPaymentMethod?.name}`}
            </p>
          </div>
        </CustomModal>
      )}
      {selectedPaymentMethod && (
        <FixedModal
          isOpen={confirmDeleteOpen}
          toggle={toggleConfirmDelete}
          title={"Delete Payment Method"}
          icon={"exclamation-solid"}
          iconColor="#FEE2E2"
          btn1={{
            text: "Delete",
            onClick: () => {
              if (selectedPaymentMethod?.type === "ach") {
                deleteUserBank({
                  variables: {
                    counterpartyId: selectedPaymentMethod?.id,
                    id: selectedPaymentMethod?.entityId,
                  },
                  refetchQueries: [
                    {
                      query: USER_BANK_ACCOUNTS_LIST,
                    },
                  ],
                });
              } else if (
                selectedPaymentMethod?.type === "card" &&
                selectedPaymentMethod.id
              ) {
                deleteCard({
                  variables: {
                    input: {
                      paymentMethodId: selectedPaymentMethod.id,
                    },
                  },
                }).then(() => {
                  getCards();
                });
              } else {
                console.log("Unhandled payment method");
                return;
              }
              toggleConfirmDelete();
            },
            btnStyle: "danger",
          }}
          btn2={{
            text: "Cancel",
            onClick: () => {
              setSelectedPaymentMethod(undefined);
              toggleConfirmDelete();
            },
          }}
        >
          <div>
            <p className="text-center text-sm text-gray-500">
              Are you sure you want to delete {selectedPaymentMethod?.name}?
            </p>
            <br />
            <p className="text-center text-sm text-gray-500">
              This will deauthorize any invoices this payment method was
              associated with.
            </p>
            <br />
            <p className="text-center text-sm text-gray-500">
              Please note that pending transactions using this payment method
              may still go through.
            </p>
          </div>
        </FixedModal>
      )}
      {PaymentModalIsOpen && (
        <ChangePaymentModal
          isOpen={PaymentModalIsOpen}
          toggle={ChangePaymentModalToggle}
        />
      )}
      {authorizeAutoPayOpen && (
        <AuthorizeAutoPay
          isOpen={authorizeAutoPayOpen}
          toggle={authorizeAutoPayToggle}
        />
      )}
    </div>
  );
}

export default PaymentMethods;
