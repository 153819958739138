import { SpendBankAccount } from "graphql/generated";
import { isNullOrEmpty } from "./null-or-empty";

export const extractBankLabel = (
  linkedBank: SpendBankAccount | null | undefined
): string => {
  if (linkedBank == null) {
    return "";
  }
  let name = linkedBank.name;
  let mask = linkedBank.mask;
  let linkName = linkedBank.institution?.name || name;
  if (linkedBank.status === "pending_manual_verification") {
    linkName = "Manually Entered Bank";
    mask = isNullOrEmpty(linkedBank.entityId)
      ? "n/a"
      : linkedBank.entityId.slice(-4);
  }
  if (linkedBank.status === "item_login_required") {
    linkName = "Verify Bank Info";
    mask = isNullOrEmpty(linkedBank.entityId)
      ? "n/a"
      : linkedBank.entityId.slice(-4);
  }
  if (linkedBank.details) {
    name = linkedBank.details?.name;
    mask = linkedBank.details?.mask;
    linkName = linkedBank.details?.institution?.name || name;
  }
  return `${linkName} (${mask})`;
};
