import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import ToastContext from "context/toast-context";
import { useContextStrict } from "helpers/context-strict";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import BulkActions from "shared-components/bulk-actions";
import Divider from "shared-components/divider";
import FloatingActionBtn from "shared-components/floating-action-btn";
import SendEmail from "shared-components/modal/send-email";
import { SpendRosterResend } from "types/group-roster";
import { collectionData } from "types/programs";
import GroupOverview from "./group-overview";
import RemoveParticipant from "./modals/remove-participant";
import ParticipantRoster from "./participants-roster";
import ArchiveParticipant from "./modals/archive-participant";
import UnarchiveParticipant from "./modals/unarchive-participant";
import BulkActionsPlusArchiveAndUnarchive from "shared-components/bulk-actions-plus-archive-and-unarchive";
import AddInvoice from "./modals/add-invoice";

function Collections() {
  const Program = useContext(ProgramContext);
  const Group = useContext(GroupContext);
  const toast = useContextStrict(ToastContext);
  const [paymentScheduleLoading, setPaymentScheduleLoading] = useState(true);
  const [paymentScheduleStatus, setPaymentScheduleStatus] = useState("");
  const [isCollectionOptionsOpen, setIsCollectionOptionsOpen] = useState(false);
  const [isItemsChecked, setIsItemsChecked] = useState(false);
  const [totalItemsChecked, setTotalItemsChecked] = useState(0);
  const [seasonRoster, setSeasonRoster] = useState<collectionData[]>([]);
  const [selectedSeasonRoster, setSelectedSeasonRoster] = useState<
    collectionData[]
  >([]);
  const [selectedSeasonRosterForResend, setSelectedSeasonRosterForResend] =
    useState<SpendRosterResend[]>([]);
  const [isDefaultBulkAction, setIsDefaultBulkAction] = useState(true);
  const { isOpen: sendEmailOpen, toggle: sendEmailToggle } = useModal();
  const { isOpen: removeParticipantOpen, toggle: removeParticipantToggle } =
    useModal();
  const {
    isOpen: unArchiveParticipantOpen,
    toggle: unArchiveParticipantToggle,
  } = useModal();
  const { isOpen: archiveParticipantOpen, toggle: archiveParticipantToggle } =
    useModal();
  const { isOpen: isAddPlayerInvoiceOpen, toggle: addPlayerInvoiceToggle } =
    useModal();
  const handleDeselectAll = () => {
    let tempCollections = [...seasonRoster];
    tempCollections.forEach((collection) => (collection.isChecked = false));
    setSeasonRoster(tempCollections);
    setSelectedSeasonRoster([]);
    setTotalItemsChecked(0);
    setIsItemsChecked(false);
    setSelectedSeasonRosterForResend([]);
  };

  useEffect(() => {
    if (!Group?.activeGroup) {
      Group?.setAndStoreActiveGroup(Group?.groups?.at(0));
    }
  }, [Group, Program]);
  return (
    <div className="wrapper">
      <div className="card">
        <GroupOverview
          paymentScheduleStatus={paymentScheduleStatus}
          paymentScheduleLoading={paymentScheduleLoading}
        />
        <Divider isVisibleOnMobile className="mt-6 mb-8" />
        <ParticipantRoster
          isCollectionOptionsOpen={isCollectionOptionsOpen}
          setIsCollectionOptionsOpen={setIsCollectionOptionsOpen}
          setIsItemsChecked={setIsItemsChecked}
          setTotalItemsChecked={setTotalItemsChecked}
          seasonRoster={seasonRoster}
          setSeasonRoster={setSeasonRoster}
          setSelectedSeasonRoster={setSelectedSeasonRoster}
          setIsDefaultBulkAction={setIsDefaultBulkAction}
          setSelectedSeasonRosterForResend={setSelectedSeasonRosterForResend}
          paymentScheduleStatus={paymentScheduleStatus}
          setPaymentScheduleStatus={setPaymentScheduleStatus}
          setPaymentScheduleLoading={setPaymentScheduleLoading}
          selectedSeasonRoster={selectedSeasonRoster}
        />
      </div>
      {isItemsChecked ? (
        <>
          {isDefaultBulkAction ? (
            <BulkActions
              numOfItemsSelected={totalItemsChecked}
              deselectAllAction={handleDeselectAll}
              sendEmailToggle={sendEmailToggle}
              selectedItems={selectedSeasonRosterForResend}
              toast={toast}
              removeParticipantToggle={removeParticipantToggle}
              paymentScheduleStatus={paymentScheduleStatus}
              addPlayerInvoiceToggle={addPlayerInvoiceToggle}
            />
          ) : (
            <BulkActionsPlusArchiveAndUnarchive
              numOfItemsSelected={totalItemsChecked}
              deselectAllAction={handleDeselectAll}
              sendEmailToggle={sendEmailToggle}
              archiveParticipantToggle={archiveParticipantToggle}
              unarchiveParticipantToggle={unArchiveParticipantToggle}
              selectedItems={selectedSeasonRosterForResend}
              paymentScheduleStatus={paymentScheduleStatus}
              toast={toast}
            />
          )}
          <ArchiveParticipant
            archiveParticipantOpen={archiveParticipantOpen}
            archiveParticipantToggle={archiveParticipantToggle}
            selectedItems={selectedSeasonRosterForResend}
            deselectAllAction={handleDeselectAll}
          />
          <UnarchiveParticipant
            unarchiveParticipantOpen={unArchiveParticipantOpen}
            unarchiveParticipantToggle={unArchiveParticipantToggle}
            selectedItems={selectedSeasonRosterForResend}
            deselectAllAction={handleDeselectAll}
          />
          <RemoveParticipant
            removeParticipantOpen={removeParticipantOpen}
            removeParticipantToggle={removeParticipantToggle}
            selectedItems={selectedSeasonRosterForResend}
            deselectAllAction={handleDeselectAll}
          />
          <SendEmail
            sendEmailOpen={sendEmailOpen}
            sendEmailToggle={sendEmailToggle}
            selectedItems={selectedSeasonRoster}
            role="Participants"
          />
          {isAddPlayerInvoiceOpen && (
            <AddInvoice
              isAddPlayerInvoiceOpen={isAddPlayerInvoiceOpen}
              addPlayerInvoiceToggle={addPlayerInvoiceToggle}
              selectedItems={selectedSeasonRoster}
              deselectAllAction={handleDeselectAll}
            />
          )}
        </>
      ) : (
        !Group?.isArchived && (
          <FloatingActionBtn
            onClick={() => setIsCollectionOptionsOpen(true)}
            icon={"dots-horizontal-solid"}
          />
        )
      )}
    </div>
  );
}

export default Collections;
