import { gql } from "@apollo/client";

export const GET_GROUP = gql`
  query SpendGroup {
    spendGroup {
      organizationFees {
        achBaseFee
        achPercent
        cardBaseFee
        cardPercent
        spendBaseFee
        spendPercent
      }
      id
      isArchived
      name
      accountId
      sharedAccount
      discountAmount
      discountCutOffDate
      enableDiscount
      isRequireAgreement
      minimumDiscountPurchase
      legacyAccountId
      seasons {
        id
        name
        isBudgetShared
        startDateAt
        endDateAt
        isLinkEnabled
        groupRoster {
          id
          inviteId
          isArchived
          userId
          roster {
            email
            id
            name
          }
          rosterId
        }
        budgets {
          id
          description
          category {
            id
            type
            name
            createdAt
            isDefault
            isHidden
            organizationId
          }
          createdAt
          targetAmount
          targetDateAt
        }
      }
    }
  }
`;

export const GET_GROUPS_FILTERED = gql`
  query SpendGroupsFiltered(
    $where: SpendGroupsWhereInput
    $pagination: SpendPaginationInput
    $sort: SpendSortInput
  ) {
    spendGroupsFiltered(where: $where, pagination: $pagination, sort: $sort) {
      count
      groups {
        id
        name
        accountId
        organizationId
        name
        hasAccount
        isArchived
        sharedAccount
        discountAmount
        discountCutOffDate
        enableDiscount
        isRequireAgreement
        minimumDiscountPurchase
        seasons {
          id
          name
          startDateAt
          endDateAt
          playerCount
          paymentScheduleStatus
          isLinkEnabled
          isBudgetShared
          budgets {
            id
            description
            category {
              id
              type
              name
              createdAt
              isDefault
              isHidden
              organizationId
            }
            createdAt
            targetAmount
            targetDateAt
          }
          #          transactionTotals {
          #            paid
          #            processing
          #            upcoming
          #            pastDue
          #            credited
          #          }
          groupRoster {
            id
            inviteId
            isArchived
            roster {
              email
              id
              name
            }
            rosterId
            joinedAt
          }
        }
        latestSeason {
          id
          name
          paymentScheduleStatus
          isBudgetShared
          isLinkEnabled
          startDateAt
          endDateAt
          groupId
          playerCount
          transactionTotals {
            paid
            processing
            upcoming
            pastDue
            dueToday
            credited
          }
          #          budgets {
          #            category {
          #              id
          #              name
          #              createdAt
          #              isDefault
          #              isHidden
          #              organizationId
          #              type
          #            }
          #            description
          #            id
          #            createdAt
          #            targetAmount
          #            targetDateAt
          #          }
        }
        unitAmount
        legacyAccountId
      }
    }
  }
`;

export const GET_GROUPS = gql`
  query SpendGroups {
    spendGroups {
      groups {
        id
        accountId
        organizationId
        name
        hasAccount
        seasons {
          id
          name
          startDateAt
          endDateAt
          playerCount
          paymentScheduleStatus
          isLinkEnabled
          isBudgetShared
          budgets {
            id
            description
            category {
              id
              type
            }
          }
          transactionTotals {
            paid
            processing
            upcoming
            pastDue
            credited
          }
          groupRoster {
            id
            inviteId
            isArchived
            roster {
              email
              id
              name
            }
            rosterId
          }
        }
      }
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query SpendGroupById($spendGroupByIdId: String!) {
    spendGroupById(id: $spendGroupByIdId) {
      id
      accountId
      organizationId
      name
      hasAccount
      isArchived
      sharedAccount
      seasons {
        id
        name
        startDateAt
        endDateAt
        playerCount
        paymentScheduleStatus
        isLinkEnabled
        isBudgetShared
        budgets {
          id
          description
          category {
            id
            type
            name
            createdAt
            isDefault
            isHidden
            organizationId
          }
          createdAt
          targetAmount
          targetDateAt
        }
        transactionTotals {
          paid
          processing
          upcoming
          pastDue
          credited
        }
        groupRoster {
          id
          inviteId
          isArchived
          roster {
            email
            id
            name
          }
          rosterId
        }
      }
    }
  }
`;
export const GET_SIGNUP_GROUP_ID = gql`
  query SpendSignupGroupId($spendGroupByIdId: String!) {
    spendGroupById(id: $spendGroupByIdId) {
      organizationFees {
        achBaseFee
        achPercent
        cardBaseFee
        cardPercent
        spendBaseFee
        spendPercent
      }
      id
      name
      accountId
      sharedAccount
      discountAmount
      discountCutOffDate
      enableDiscount
      isRequireAgreement
      minimumDiscountPurchase
      legacyAccountId
      seasons {
        id
        name
        isBudgetShared
        groupRoster {
          id
          inviteId
          isArchived
          userId
          roster {
            email
            id
            name
          }
          rosterId
        }
      }
    }
  }
`;
export const GET_GROUP_BY_ID_SETTINGS = gql`
  query SpendGroupByIdSettings($spendGroupByIdId: String!) {
    spendGroupById(id: $spendGroupByIdId) {
      id
      accountId
      organizationId
      name
      latestSeason {
        id
        startDateAt
        endDateAt
        isLinkEnabled
        name
        paymentScheduleStatus
        transactionTotals {
          paid
          processing
          upcoming
          pastDue
          dueToday
          credited
        }
      }
    }
  }
`;

export const GET_GROUP_WITH_BUDGETS = gql`
  query SpendBudgetGroups {
    spendGroups {
      groups {
        id
        organizationId
        name
        accountId
        hasAccount
        seasons {
          id
          name
          budgets {
            id
            targetAmount
            category {
              type
            }
            invoices {
              id
              amount
              paid
              isReconciled
            }
          }
          isBudgetShared
        }
      }
    }
  }
`;

export const GET_GROUP_ROSTERS = gql`
  query SpendGroupRosters {
    spendGroupRosters {
      count
      groupRosters {
        archivedAt
        createdAt
        groupId
        id
        isArchived
        joinedAt
        roster {
          name
          email
          id
        }
        group {
          id
          name
          accountId
        }
        season {
          id
          name
          startDateAt
          endDateAt
        }
        rosterId
        userId
        paymentScheduleStatus
        invoices {
          id
          amount
          paid
          paidDate
          dueDate
          balanceDue
          description
          isOptional
          optedIn
          authorizedAt
          transaction {
            id
            invoiceId
            externalId
          }
          reconciledTransactions {
            id
          }
        }
      }
    }
  }
`;
export const GET_GUARDIAN_GROUP_ROSTERS = gql`
  query SpendGuardianGroupRosters {
    spendGuardianGroupRosters {
      groupRosters {
        archivedAt
        createdAt
        groupId
        id
        isArchived
        joinedAt
        roster {
          name
          email
          id
        }
        group {
          id
          name
          accountId
        }
        season {
          id
          name
          startDateAt
          endDateAt
        }
        rosterId
        userId
        paymentScheduleStatus
        invoices {
          id
          amount
          paid
          paidDate
          dueDate
          balanceDue
          description
          isOptional
          optedIn
          optedOutAt
          authorizedAt
          transaction {
            id
            invoiceId
            externalId
          }
          reconciledTransactions {
            id
          }
        }
      }
      count
    }
  }
`;
export const GET_GROUP_ROSTERS_AND_PAYMENT_SCHEDULE = gql`
  query SpendGroupRostersAndPaymentSchedule(
    $seasonId: String!
    $groupIdOrSeasonId: String!
  ) {
    spendGroupRosters(filterBy: seasonId, filterValue: $seasonId) {
      count
      groupRosters {
        inviteId
        invite {
          status
          isArchived
          id
        }
        archivedAt
        createdAt
        groupId
        id
        isArchived
        roster {
          name
          email
          id
        }
        group {
          id
          name
          accountId
        }
        season {
          id
          name
          startDateAt
          endDateAt
        }
        seasonId
        rosterId
        userId
        invoices {
          id
          amount
          paid
          paidDate
          dueDate
          balanceDue
          description
          status
          transaction {
            id
            invoiceId
            externalId
          }
          reconciledTransactions {
            id
          }
        }
      }
    }
    spendPaymentSchedules(groupIdOrSeasonId: $groupIdOrSeasonId) {
      id
      amountDue
      dueDate
      description
      status
      note
      isOptional
      budgetItemId
      seasonId
      groupId
      budgetItem {
        id
        description
        targetAmount
        targetDateAt
        vaultId
        category {
          name
          type
          id
        }
        isDefault
        createdAt
        updatedAt
        reconciledBudgetTotal
        reconciledInvoicesTotal
        reconciledTotal
        invoices {
          balanceDue
          description
          paid
          id
          reconciledTransactions {
            amount
            id
            invoiceId
            transactionId
          }
          amount
        }
      }
      season {
        id
        name
        startDateAt
        endDateAt
      }
      group {
        id
        name
        organizationId
        programId
        createdAt
        archivedAt
        hasAccount
      }
    }
  }
`;

export const GET_GROUPS_WITH_NAME_ID = gql`
  query SpendSimpleGroups {
    spendGroups {
      groups {
        id
        name
      }
    }
  }
`;

export const GET_GROUP_DEBIT_CARDS = gql`
  query SpendGroupDebitCards($spendGroupDebitCardsId: String) {
    spendGroupDebitCards(id: $spendGroupDebitCardsId) {
      cards {
        expiration
        id
        lastFour
        status
        userId
      }
      count
    }
  }
`;

export const GET_PARENT_INVOICES_CHANGE_PAYMENT = gql`
  query SpendGroupRosterInvoices(
    $filterValue: String
    $filterBy: GroupRosterFilterEnum
  ) {
    spendGroupRosters(filterValue: $filterValue, filterBy: $filterBy) {
      count
      groupRosters {
        invoices {
          description
          dueDate
          paymentMethodSource
          paymentMethodId
          balanceDue
          paid
          id
        }
        group {
          name
        }
        roster {
          name
        }
      }
    }
  }
`;

export const GET_PARENTS_INVOICES = gql`
  query SpendGroupRostersParentInvoices(
    $filterBy: GroupRosterFilterEnum
    $filterValue: String
  ) {
    spendGroupRosters(filterBy: $filterBy, filterValue: $filterValue) {
      count
      groupRosters {
        group {
          id
          name
        }
        season {
          name
        }
        invoices {
          balanceDue
          amount
          paidDate
          dueDate
          paid
          paymentMethodSource
          description
          id
          isAutoPayAuthorized
          isAutoPayStopped
          authorizedAt
          optedIn
          refundDate
          status
          isRefunded
          discountAmount
          isOptional
        }
        roster {
          id
          name
        }
        invite {
          status
        }
        userId
      }
    }
  }
`;

export const GET_GROUP_BANK_ACCOUNTS = gql`
  query SpendGroupBankAccounts($groupId: String) {
    spendGroupBankAccounts(groupId: $groupId) {
      externalAccounts {
        accountId
        availableBalance
        currentBalance
        mask
        name
        officialName
        subtype
        type
        institution {
          id
          name
        }
      }
      status
    }
  }
`;

export const GET_PARENT_SIGN_UP_GROUP_ROSTER = gql`
  query SpendParentSignUpGroupRosters {
    spendGroupRosters {
      groupRosters {
        roster {
          email
          groupRosters {
            invoices {
              amount
              isOptional
              optedIn
              description
              dueDate
              id
            }
          }
          id
          name
        }
        group {
          name
        }
        season {
          name
        }
      }
    }
  }
`;

export const GET_GROUP_PAYEES = gql`
  query SpendGroupPayees($groupId: String!) {
    spendGroupPayees(groupId: $groupId) {
      payees {
        id
        name
        address1
        address2
        city
        state
        zipCode
      }
    }
  }
`;

export const GET_GROUP_ROSTER_IDS = gql`
  query GetGroupRosterByIds(
    $filterBy: GroupRosterFilterEnum
    $filterValue: String
  ) {
    spendGroupRosters(filterBy: $filterBy, filterValue: $filterValue) {
      groupRosters {
        rosterId
      }
      count
    }
  }
`;

export const USER_SIGN_UP = gql`
  mutation SpendUserSignUp($input: SpendSignUpInput!) {
    spendUserSignUp(input: $input) {
      id
    }
  }
`;

export const GET_GROUPS_PUBLIC = gql`
  query SpendGroupsPublic($orgId: String!) {
    spendGroupsPublic(orgId: $orgId) {
      groups {
        id
        name
        seasons {
          id
          name
          isLinkEnabled
          paymentScheduleStatus
        }
      }
    }
  }
`;

export const GET_GROUPS_WITH_SEASON_ROSTER = gql`
  query SpendGroupsWithSeasonRoster($includeArchive: Boolean) {
    spendGroups(includeArchive: $includeArchive) {
      count
      groups {
        id
        name
        isArchived
        seasons {
          id
          name
          groupRoster {
            id
            roster {
              email
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_GROUP_COUNTERPARTIES = gql`
  query SpendGetGroupCounterparties($groupOrOrgId: String!) {
    spendGetGroupCounterparties(groupOrOrgId: $groupOrOrgId) {
      counterparties {
        id
        name
        bankName
        routingNumber
        accountLastFour
      }
    }
  }
`;
