/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import UserContext from "context/user-context";
import cuid from "cuid";
import {
  Maybe,
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  usePaymentsApiCustomerMutation,
} from "graphql/generated";
import { GET_GROUP } from "graphql/queries/group";
import { USER_BANK_ACCOUNTS } from "graphql/queries/user";
import useToast from "hooks/use-toast";
import React, { useContext, useEffect, useState } from "react";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import { SnapButton, SnapSelectMenu } from "suit";
import CustomModal from "..";
import { defaultSelectedPaymentOption } from "../make-payment/make-payment-helper";
import { extractBankLabel } from "helpers/banking";
import LinkBank from "shared-components/banking/link-bank";
import { OffSessionPaymentComponent } from "shared-components/payment-component/off-session-payment-component";

type AddChangePaymentType = {
  isOpen: boolean;
  toggle: () => void;
};
export type PlayerInfoType = {
  team: Maybe<string> | undefined;
  player: Maybe<string> | undefined;
  // selected: boolean;
};

export const AddPaymentModal = ({ isOpen, toggle }: AddChangePaymentType) => {
  const extPayId = cuid();
  const ActiveUser = useContext(UserContext);
  const [
    getPaymentMethods,
    { loading: loadingPaymentMethod, data: paymentMethodData },
  ] = usePaymentsApiCustomerMutation();
  const { loading: loadingGroup, data: groupData } = useQuery(GET_GROUP, {
    skip: !isOpen,
  });
  const { data: accountsData, loading: accountsLoading } = useQuery(
    USER_BANK_ACCOUNTS,
    {
      skip: !ActiveUser?._session?.userId || !isOpen,
    }
  );
  const [bankAccounts, setBankAccounts] = useState<SpendBankAccount[]>([]);
  const [pendingBankAccounts, setPendingBankAccounts] = useState<
    SpendBankAccount[]
  >([]);
  const [accountStatus, setAccountStatus] = useState("");
  const [selectedBankAccount, setSelectedBankAccount] = useState<
    SpendBankAccount | undefined
  >();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [bankOptions, setBankOptions] = useState<SnapSelectMenuOption[]>([]);
  const [playersInfo, setPlayerInfo] = useState<PlayerInfoType[]>([]);
  const [cardBlock, setCardBlock] = useState(false);
  const [step, setStep] = useState(0);
  const [paymentOptions, setPaymentOptions] = useState<SnapSelectMenuOption[]>(
    defaultSelectedPaymentOption
  );
  const [selectedPaymentTypeOption, setSelectedPaymentTypeOption] = useState<
    `Pay by: ${"Card" | "Bank"}` | undefined
  >();
  const [groupAccountId, setGroupAccountId] = useState("");
  const [paymentMethods, setPaymentMethods] = useState<
    PaymentsApiCustomerPaymentMethod[]
  >([]);
  const [isBtnActive, setIsBtnActive] = useState(false);

  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const resetFormAndClose = () => {
    setPaymentOptions(paymentOptions.map((op) => ({ ...op, selected: false })));
    setSelectedPaymentTypeOption(undefined);
    toggle();
  };
  useEffect(() => {
    if (paymentMethodData && paymentMethodData.paymentsApiCustomer) {
      setPaymentMethods(
        paymentMethodData.paymentsApiCustomer
          .paymentMethods as PaymentsApiCustomerPaymentMethod[]
      );
    }
  }, [loadingPaymentMethod, paymentMethodData]);
  useEffect(() => {
    if (groupData && groupData.spendGroup) {
      setGroupAccountId(groupData.spendGroup.accountId);
    }
  }, [loadingGroup, groupData]);

  useEffect(() => {
    if (ActiveUser?.getEmail() && isOpen) {
      getPaymentMethods();
    }
  }, [ActiveUser, isOpen, getPaymentMethods]);

  const [plaidConfig, setPlaidConfig] = useState<
    PlaidLinkOptions & { isSuccess?: boolean }
  >({
    onSuccess: (public_token, metadata): boolean => {
      return false;
    },
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: "",
  });
  const { open, ready } = usePlaidLink(plaidConfig);
  const openPlaid = () => {
    if (ready) {
      open();
    }
  };

  const paymentMethodCreate = () => {
    getPaymentMethods();
  };

  useEffect(() => {
    if (!accountsLoading && accountsData?.spendUserBankAccounts) {
      const tempAccounts = [
        ...accountsData.spendUserBankAccounts.externalAccounts,
      ];
      setAccountStatus(accountsData?.spendUserBankAccounts.status);
      setBankAccounts(tempAccounts);
      setPendingBankAccounts(
        tempAccounts.filter((t) => t.status === "pending_manual_verification")
      );

      setBankOptions(
        tempAccounts.map((tAccount, idx) => ({
          name: extractBankLabel(tAccount),
          selected: idx === 0,
        }))
      );
      setSelectedBankAccount(
        tempAccounts.length > 0 ? tempAccounts[0] : undefined
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsData, accountsLoading]);

  useEffect(() => {
    const isPayByBankAndNoBank =
      selectedPaymentTypeOption === "Pay by: Bank" &&
      selectedBankAccount == null;
    const isPayByCardAndPmEmpty =
      selectedPaymentTypeOption === "Pay by: Card" &&
      paymentMethods.length === 0;
    const hasNoAuthOrBtnActive = !isAuthorized || isBtnActive;
    if (step === 0 && (isPayByBankAndNoBank || isPayByCardAndPmEmpty)) {
      setIsNextDisabled(true);
    } else if (step === 1 && hasNoAuthOrBtnActive) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
    }
  }, [
    isAuthorized,
    isBtnActive,
    paymentMethods.length,
    selectedBankAccount,
    selectedPaymentTypeOption,
    step,
  ]);
  return (
    <>
      <CustomModal
        isOpen={isOpen}
        toggle={resetFormAndClose}
        title="Add Payment Method"
        customStyle="lg:w-[974px]"
      >
        <div className="mx-3 mt-2 modal-card lg:h-[500px] overflow-auto">
          {step === 0 && (
            <React.Fragment>
              <div className="flex mx-auto">
                <div className="mr-5 text-lg font-semibold">
                  Payment Method Type:
                </div>
                <SnapSelectMenu
                  className="lg:w-[30%]"
                  placeholder="- Select Payment Method -"
                  selectMenuOptions={[
                    {
                      name: "Pay by: Card",
                      selected: selectedPaymentTypeOption === "Pay by: Card",
                    },
                    {
                      name: "Pay by: Bank",
                      selected: selectedPaymentTypeOption === "Pay by: Bank",
                    },
                  ]}
                  onSnap-select-menu-updated={(e) => {
                    const op = e.detail.find((option) => option.selected)?.name;
                    if (!op) {
                      setSelectedPaymentTypeOption(undefined);
                    } else {
                      setSelectedPaymentTypeOption(
                        op === "Pay by: Card" ? "Pay by: Card" : "Pay by: Bank"
                      );
                    }
                  }}
                />
              </div>
              <div>
                {selectedPaymentTypeOption === "Pay by: Bank" && (
                  <>
                    <div className="lg:flex">
                      <LinkBank
                        openPlaid={openPlaid}
                        setPlaidConfig={setPlaidConfig}
                        type={"user"}
                        labelText={
                          accountStatus === "pending_manual_verification"
                            ? "Verify Account"
                            : "Add Account"
                        }
                        groupId={undefined}
                        onSuccess={() => {
                          resetFormAndClose();
                        }}
                      />
                      {accountStatus === "pending_manual_verification" && (
                        <p
                          className="self-center lg:mt-4 mt-10 text-red-500 font-bold cursor-pointer"
                          onClick={() => console.log("deletePlaidLink()")}
                        >
                          Delete Account
                        </p>
                      )}
                    </div>
                  </>
                )}
                {selectedPaymentTypeOption === "Pay by: Card" && (
                  <OffSessionPaymentComponent
                    returnUrl={`${document.baseURI}&externalId=${extPayId}`}
                    payerDetails={{
                      id: "",
                      name: ActiveUser?.getName() ?? "",
                      email: ActiveUser?.getEmail() ?? "",
                    }}
                    destination={groupAccountId}
                    externalPaymentId={extPayId}
                    paymentMethodCreate={paymentMethodCreate}
                  />
                )}
              </div>
            </React.Fragment>
          )}

          <div className="flex justify-end space-x-3 mt-5 m-3">
            <SnapButton
              icon="arrow-narrow-left-line"
              variant="tertiary"
              onClick={resetFormAndClose}
            >
              Back
            </SnapButton>
            <SnapButton
              variant="primary"
              disabled={isNextDisabled}
              onClick={resetFormAndClose}
            >
              <p>Done</p>
            </SnapButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
};
