import ProgramContext from "context/program-context";
import {
  Maybe,
  SpendBankTransaction,
  SpendBankTransactionDetail,
  SpendReconciledBudget,
} from "graphql/generated";
import { FormatMoney } from "helpers/format-money";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import { useContext, useEffect, useState } from "react";
import { SnapTable } from "suit";

type TransactionReconsiliationCardProps = {
  transaction: SpendBankTransaction | SpendBankTransactionDetail | undefined;
};

function TransactionReconciliationCard({
  transaction,
}: TransactionReconsiliationCardProps) {
  const Program = useContext(ProgramContext);
  const [budgetItems, setBudgetItems] = useState<
    Maybe<SpendReconciledBudget>[]
  >([]);

  useEffect(() => {
    if (transaction && transaction.reconciliation) {
      setBudgetItems(transaction.reconciliation.reconciledTo ?? []);
    }
  }, [transaction]);

  return (
    <>
      <div className="mr-auto mb-0 mt-4">
        <div className="flex font-semibold text-base">
          Reconciliation{" "}
          {Program?.organization?.nickname || Program?.organization?.legalName}
        </div>
        <SnapTable>
          <table className="ui celled table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Budget Item</th>
                <th>Season</th>
                <th className="text-right">Reconciled Amount</th>
              </tr>
            </thead>
            <tbody>
              {budgetItems &&
                budgetItems.map((budget, idx: number) => {
                  const amnt_xs_styles = " flex justify-between";
                  const amnt_md_styles =
                    "md:border-0 md:table-cell md:text-right";
                  return (
                    <tr key={"recon" + idx}>
                      <td data-label="Type" className={"capitalize"}>
                        <p>{budget?.type}</p>
                      </td>
                      <td data-label="Invoice Item" className="-mt-2">
                        <p>{budget?.budgetName}</p>
                      </td>
                      <td data-label="Season" className={`lg:table-cell`}>
                        <p>{budget?.seasonName}</p>
                      </td>
                      <td
                        data-label="Reconciled Amount"
                        className={`${amnt_xs_styles} ${amnt_md_styles}`}
                      >
                        {FormatMoney(budget?.amount ?? 0)}
                      </td>
                    </tr>
                  );
                })}

              <tr
                className="hidden md:table-row bg-gray-100 h-10"
                key={"recon-total"}
              >
                <td colSpan={4} className="text-right">
                  <span className="pr-4">Total </span>
                  {FormatMoney(
                    MapAndCalcSum(
                      transaction?.reconciliation?.reconciledTo || [],
                      "amount"
                    )
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="flex md:hidden justify-between">
            <div className="font-semibold text-gray-500">Total Amount</div>
            <div>{FormatMoney(transaction?.reconciliation?.amount ?? 0)}</div>
          </div>
        </SnapTable>
      </div>
    </>
  );
}

export default TransactionReconciliationCard;
