import { gql } from "@apollo/client";
export const GET_NOTES_AND_ATTACHMENTS_BY_PAYMENT_ID = gql`
  query GetNotesAndAttachments($id: String!) {
    spendTransactionNotesByPaymentId(id: $id) {
      notes {
        content
        createdAt
        id
        lastUpdatedByUserId
        paymentId
        updatedAt
      }
    }
    spendTransactionAttachmentsByPaymentId(id: $id) {
      attachments {
        createdAt
        description
        id
        lastUpdatedByUserId
        name
        isLegacy
        paymentId
        updatedAt
        url
      }
    }
  }
`;
export const GET_NOTES_BY_PAYMENT_ID = gql`
  query GetNotes($id: String) {
    spendTransactionNotesByPaymentId(id: $id) {
      notes {
        content
        createdAt
        id
        lastUpdatedByUserId
        paymentId
        updatedAt
      }
    }
  }
`;

export const GET_ATTACHMENT_BY_PAYMENT_ID = gql`
  query GetAttachments($id: String!) {
    spendTransactionAttachmentsByPaymentId(id: $id) {
      attachments {
        createdAt
        description
        id
        lastUpdatedByUserId
        name
        paymentId
        updatedAt
        url
      }
    }
  }
`;
export const GET_FILTERED_TRANSACTIONS = gql`
  query SpendTransactionsFiltered(
    $filters: [SpendTransactionFilter]
    $pagination: SpendPaginationInput
    $disableCache: Boolean
    $allAccounts: Boolean
    $sort: SpendSortInput
  ) {
    spendTransactionsFiltered(
      filters: $filters
      pagination: $pagination
      disableCache: $disableCache
      allAccounts: $allAccounts
      sort: $sort
    ) {
      count
      transactions {
        id
        processor
        externalId
        direction
        description
        type
        amount
        snapAmount
        effective
        correlationId
        source
        destination
        transactionType
        transactionStatus
        transactionNote
        metadata {
          account
          addenda
          customer
          description
          destination
          externalId
          payment
          processor
          source
          summary
          status
          tags {
            spendDestinationId
            spendExternalId
            spendInvoiceIds
            spendPaymentType
            spendOriginalPaymentId
            spendSourceId
            stopPaymentIssued
          }
        }
        reconciliation {
          amount
          createdAt
          id
          type
          updatedAt
          reconciledTo {
            amount
            description
            type
            invoiceId
            budgetId
            budgetName
            categoryName
            categoryId
            groupName
            groupId
            seasonName
            seasonId
          }
        }
        groupRoster {
          group {
            name
          }
          roster {
            name
          }
        }
        status
        attachments {
          id
          name
          url
        }
        payee {
          payeeId
          referenceId
          organizationName
          groupName
          payeeName
        }
        external {
          externalId
          invoiceDescription
          playerName
          groupName
          seasonName
        }
        creditMemo {
          id
          title
          note
          dateToApply
          creditApplied
          creditAmount
          isArchived
          groupName
          invoice {
            id
            description
            participantName
            rosterId
            groupName
            groupId
            status
            dueDate
            seasonId
            seasonName
            amount
            balanceDue
            discountAmount
          }
        }
      }
    }
  }
`;
export const GET_TRANSACTIONS_WHERE = gql`
  query SpendTransactionsWhered(
    $pagination: SpendPaginationInput
    $where: SpendTransactionWhereInput
    $disableCache: Boolean
  ) {
    spendTransactionsFiltered(
      pagination: $pagination
      where: $where
      disableCache: $disableCache
    ) {
      count
      transactions {
        id
        processor
        externalId
        description
        direction
        type
        amount
        snapAmount
        effective
        correlationId
        source
        destination
        hasAttachments
        transactionType
        transactionStatus
        transactionNote
        isReconciled
        totalReconciled
        totalApplied
        typeReconciled
        metadata {
          account
          addenda
          customer
          description
          destination
          externalId
          payment
          processor
          source
          summary
          status
          tags {
            spendDestinationId
            spendExternalId
            spendInvoiceIds
            spendPaymentType
            spendOriginalPaymentId
            spendSourceId
            stopPaymentIssued
          }
        }
        reconciliation {
          amount
          createdAt
          id
          type
          updatedAt
          budgetTransactions {
            amount
            id
            budgetItemId
            transactionId
            invoiceId
          }
          invoiceTransactions {
            amount
            id
            invoiceId
            transactionId
            rosterName
          }
          reconciledTo {
            amount
            description
            type
            invoiceId
            budgetId
            groupName
            seasonName
            seasonId
          }
        }
        groupRoster {
          group {
            name
          }
          roster {
            name
          }
        }
        status
        attachments {
          id
          name
          url
        }
        payee {
          payeeId
          referenceId
          organizationName
          groupName
          payeeName
        }
        external {
          externalId
          invoiceDescription
          playerName
          groupName
          seasonName
        }
      }
    }
  }
`;
export const GET_TRANSACTIONS_COMPLETED_WHERE = gql`
  query SpendTransactionsCompletedWhered(
    $pagination: SpendPaginationInput
    $where: SpendTransactionWhereInput
    $disableCache: Boolean
    $dateRange: dateRangeEnum
  ) {
    spendTransactionsFiltered(
      pagination: $pagination
      where: $where
      disableCache: $disableCache
      dateRange: $dateRange
    ) {
      count
      transactions {
        created
        id
        processor
        externalId
        description
        direction
        type
        amount
        snapAmount
        effective
        correlationId
        source
        destination
        hasAttachments
        transactionType
        transactionStatus
        transactionNote
        isReconciled
        totalReconciled
        totalApplied
        typeReconciled
        metadata {
          account
          addenda
          customer
          description
          destination
          checkNumber
          externalId
          payment
          processor
          source
          summary
          tags {
            spendDestinationId
            spendExternalId
            spendPaymentType
            spendOriginalPaymentId
            spendSourceId
            spendDisputedChargeId
            spendDisputeId
            spendGroupRosterId
            disputeId
            stopPaymentIssued
          }
        }
        reconciliation {
          amount
          createdAt
          id
          type
          updatedAt
          budgetTransactions {
            amount
            id
            budgetItemId
            transactionId
            invoiceId
          }
          invoiceTransactions {
            amount
            id
            invoiceId
            transactionId
            rosterName
          }
          reconciledTo {
            amount
            description
            type
            invoiceId
            budgetId
            groupName
            seasonName
            seasonId
          }
        }
        groupRoster {
          group {
            name
          }
          roster {
            name
          }
        }
        status
        attachments {
          id
          name
          url
        }
        payee {
          payeeId
          referenceId
          organizationName
          groupName
          payeeName
        }
        external {
          externalId
          invoiceDescription
          playerName
          groupName
          seasonName
        }
      }
    }
  }
`;
export const GET_TRANSACTIONS_PENDING_WHERE = gql`
  query SpendTransactionsPendingWhered(
    $pagination: SpendPaginationInput
    $where: SpendTransactionWhereInput
    $disableCache: Boolean
  ) {
    spendTransactionsFiltered(
      pagination: $pagination
      where: $where
      disableCache: $disableCache
    ) {
      count
      transactions {
        created
        id
        processor
        externalId
        description
        direction
        type
        amount
        snapAmount
        effective
        correlationId
        source
        destination
        hasAttachments
        transactionType
        transactionStatus
        transactionNote
        isReconciled
        totalReconciled
        totalApplied
        typeReconciled
        metadata {
          account
          addenda
          customer
          description
          destination
          externalId
          payment
          checkNumber
          processor
          source
          summary
          status
          tags {
            spendDestinationId
            spendExternalId
            spendInvoiceIds
            spendPaymentType
            spendOriginalPaymentId
            spendSourceId
            stopPaymentIssued
          }
        }
        reconciliation {
          amount
          createdAt
          id
          type
          updatedAt
          budgetTransactions {
            amount
            id
            budgetItemId
            transactionId
            invoiceId
          }
          invoiceTransactions {
            amount
            id
            invoiceId
            transactionId
            rosterName
          }
          reconciledTo {
            amount
            description
            type
            invoiceId
            budgetId
            groupName
            seasonName
            seasonId
          }
        }
        groupRoster {
          group {
            name
          }
          roster {
            name
          }
        }
        status
        attachments {
          id
          name
          url
        }
        payee {
          payeeId
          referenceId
          organizationName
          groupName
          payeeName
        }
        external {
          externalId
          invoiceDescription
          playerName
          groupName
          seasonName
        }
      }
    }
  }
`;
export const ATTACHMENT_RAW = gql`
  query SpendTransactionAttachmentRaw($input: SpendRawFileInput!) {
    spendTransactionAttachmentRaw(input: $input) {
      content
      fileName
    }
  }
`;
export const EXPORT_TRANSACTIONS = gql`
  query SpendTransactionsExport(
    $filters: [SpendTransactionFilter]
    $limit: Int
    $allAccounts: Boolean
  ) {
    spendTransactionsExport(
      filters: $filters
      limit: $limit
      allAccounts: $allAccounts
    ) {
      content
      fileName
    }
  }
`;

export const GET_GUARDIAN_TRANSACTIONS = gql`
  query SpendTransactionsFilteredForParentDash(
    $pagination: SpendPaginationInput
  ) {
    spendTransactionsFiltered(pagination: $pagination, disableCache: true) {
      transactions {
        amount
        correlationId
        destination
        effective
        externalId
        direction
        id
        metadata {
          destination
          source
          description
          status
          summary
          tags {
            spendDestinationId
            spendExternalId
            spendInvoiceIds
            spendOriginalPaymentId
            spendPaymentType
            spendSourceId
            spendUserId
            spendDisputedChargeId
            spendDisputeId
            spendGroupRosterId
            disputeId
            stopPaymentIssued
          }
          processor
          payment
          externalId
          addenda
        }
        groupRoster {
          group {
            id
            name
          }
          roster {
            id
            name
          }
        }
        external {
          externalId
          invoiceDescription
          playerName
          groupName
          seasonName
        }
        creditMemo {
          id
          title
          note
          dateToApply
          creditApplied
          creditAmount
          isArchived
          groupName
          invoice {
            id
            description
            participantName
            rosterId
            groupName
            groupId
            status
            dueDate
            seasonId
            seasonName
            amount
            balanceDue
            discountAmount
          }
        }
        processor
        snapAmount
        source
        status
        type
        transactionType
        transactionStatus
      }
      count
    }
  }
`;

export const GET_TRANSACTION_BY_ID = gql`
  query SpendTransactionByID($spendTransactionId: String) {
    spendTransaction(id: $spendTransactionId) {
      transactionDetail {
        __typename
        id
        canCancel
        amount
        description
        destination
        direction
        effective
        externalId
        processor
        type
        status
        snapAmount
        metadata {
          account
          addenda
          customer
          description
          destination
          externalId
          payment
          processor
          snapAmount
          source
          summary
          status
          tags {
            spendDestinationId
            spendExternalId
            spendInvoiceIds
            spendPaymentType
            spendAutoPay
            spendOriginalPaymentId
            spendUserId
            spendSourceId
            spendGroupRosterId
            spendDisputeId
            spendDisputedChargeId
            disputeTransferId
            disputeId
            stopPaymentIssued
          }
          originalTransaction
          returnedTransaction
        }
        history {
          date
          status
          transactionId
        }
        reconciliation {
          budgetTransactions {
            budgetItemId
            amount
            id
            transactionId
            invoiceId
          }
          invoiceTransactions {
            amount
            id
            invoiceId
            transactionId
            rosterName
            rosterId
            creditMemos {
              id
              title
              note
              dateToApply
              creditApplied
              creditAmount
              isArchived
            }
          }
          reconciledTo {
            amount
            description
            type
            invoiceId
            budgetId
            budgetName
            categoryName
            categoryId
            groupName
            seasonName
            groupId
            playerName
          }
          amount
          createdAt
          id
          paymentId
          type
          updatedAt
        }
        groupRoster {
          group {
            name
          }
          season {
            name
          }
        }
        disputeDetail {
          description
          id
          status
          originalMetadata {
            spendDestinationId
            spendExternalId
            spendGroupRosterId
            spendPaymentType
            spendAutoPay
            spendOriginalPaymentId
            spendUserId
            spendSourceId
            spendDisputeId
            spendDisputedChargeId
            submittedBy
            disputeTransferId
            disputeId
          }
        }
      }
    }
  }
`;

export const GET_LEGACY_TRANSACTIONS = gql`
  query SpendTransactionsLegacy(
    $pagination: SpendPaginationInput
    $groupId: String
  ) {
    spendTransactionsLegacy(pagination: $pagination, groupId: $groupId) {
      count
      transactions {
        amount
        attachments {
          createdAt
          description
          id
          isLegacy
          lastUpdatedByUserId
          name
          paymentId
          updatedAt
          url
        }
        correlationId
        created
        description
        destination
        direction
        effective
        externalId
        id
        metadata
        note {
          content
          createdAt
          id
          lastUpdatedByUserId
          paymentId
          updatedAt
        }
        processor
        reconciliation {
          reconciledTo {
            amount
            description
            type
            invoiceId
            budgetId
            budgetName
            categoryName
            categoryId
            groupName
            seasonName
            groupId
          }
          amount
          budgetTransactions {
            amount
            budgetItemId
            id
            transactionId
            invoiceId
          }
          createdAt
          id
          invoiceTransactions {
            amount
            id
            invoiceId
            rosterId
            rosterName
            transactionId
          }
          paymentId
          type
          updatedAt
        }
        snapAmount
        source
        status
        transactionNote
        type
      }
    }
  }
`;
