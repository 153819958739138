import { PieChartItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { useSpendOrganizationPieChartQuery } from "graphql/generated";
import { FormatMoney } from "helpers/format-money";
import LabelValue from "pages/groups/collections/label-value";
import { useEffect, useState } from "react";
import Spinner from "shared-components/spinner";
import { SnapPieChart } from "suit";

function PieChart() {
  const { loading: loadingPieChart, data: pieChartData } =
    useSpendOrganizationPieChartQuery();

  const [chartOptions, setChartOptions] = useState<PieChartItem[]>([]);
  useEffect(() => {
    if (!loadingPieChart && pieChartData?.spendOrganizationPieChart) {
      const transactionTotals = pieChartData.spendOrganizationPieChart;
      setChartOptions([
        {
          category: "Paid",
          value: transactionTotals?.paid ?? 0,
          color: "rgb(5,150,105)",
        },
        {
          category: "Processing",
          value: transactionTotals?.processing ?? 0,
          color: "rgb(217,119,6)",
        },
        {
          category: "Upcoming",
          value: transactionTotals?.upcoming ?? 0,
          color: "rgb(96,165,250)",
        },
        {
          category: "Past Due",
          value: transactionTotals?.pastDue ?? 0,
          color: "rgb(239,68,68)",
        },
        {
          category: "Credited",
          value: transactionTotals?.credited ?? 0,
          color: "rgb(71,85,105)",
        },
      ]);
    }
  }, [loadingPieChart, pieChartData]);

  const transactionTotals = pieChartData?.spendOrganizationPieChart;
  return (
    <div className="wrapper">
      <div className="card">
        <p className="text-lg font-medium">Program Overview</p>
        <div className="lg:grid grid-cols-3 mt-4">
          <div className="flex-col justify-between">
            <LabelValue
              color="green"
              label="Paid"
              value={FormatMoney(transactionTotals?.paid ?? 0)}
              valueColor={
                transactionTotals?.paid !== 0
                  ? "text-green-600"
                  : "text-gray-600"
              }
              labelColor={"text-gray-800"}
              bgColor={"bg-green-100"}
            />
            <LabelValue
              color="yellow"
              label="Processing"
              value={FormatMoney(
                (transactionTotals && transactionTotals.processing) || 0
              )}
              valueColor={
                transactionTotals?.processing !== 0
                  ? "text-yellow-600"
                  : "text-gray-600"
              }
              labelColor={"text-gray-800"}
              bgColor={"bg-yellow-100"}
              className={"mt-3"}
            />
            <LabelValue
              color="blue"
              label="Upcoming"
              value={FormatMoney(
                (transactionTotals && transactionTotals.upcoming) || 0
              )}
              valueColor={
                transactionTotals?.upcoming !== 0
                  ? "text-blue-600"
                  : "text-gray-600"
              }
              labelColor={"text-gray-800"}
              bgColor={"bg-blue-100"}
              className={"mt-3"}
            />
            <LabelValue
              color="red"
              label="Past Due"
              value={FormatMoney(
                (transactionTotals && transactionTotals.pastDue) || 0
              )}
              valueColor={
                transactionTotals?.pastDue !== 0
                  ? "text-red-600"
                  : "text-gray-600"
              }
              labelColor={"text-gray-800"}
              bgColor={"bg-red-100"}
              className={"mt-3"}
            />
            <LabelValue
              color="gray"
              label="Credited"
              value={FormatMoney(
                (transactionTotals && transactionTotals.credited) || 0
              )}
              valueColor={"text-gray-600"}
              labelColor={"text-gray-800"}
              className={"mt-3"}
              bgColor={"bg-gray-100"}
            />
          </div>
          <div className="lg:inline-block self-center hidden">
            {chartOptions.length === 0 && (
              <>
                <Spinner />
                <p className="flex justify-center">Retrieving data</p>
                <p className="flex justify-center">(May take 1 - 2 Minutes)</p>
              </>
            )}
            <SnapPieChart
              height={150}
              innerRadius={56}
              options={chartOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PieChart;
