import { SpendBankTransaction } from "graphql/generated";
import FixedModal from "./fixed-modal";
import { useMutation } from "@apollo/client";
import { useContext, useEffect } from "react";
import { STOP_PAYMENT } from "graphql/mutations/payment";
import ToastContext from "context/toast-context";
import { GET_ORGANIZATION_ACCOUNTS } from "graphql/queries/organization";

type RequestStopPaymentProps = {
  requestStopPaymentOpen: boolean;
  requestStopPaymentToggle: () => void;
  focusOnTransaction: SpendBankTransaction | undefined;
};

function RequestStopPayment({
  requestStopPaymentOpen,
  requestStopPaymentToggle,
  focusOnTransaction,
}: RequestStopPaymentProps) {
  const toast = useContext(ToastContext);

  const [stopPayment, { loading, data }] = useMutation(STOP_PAYMENT, {
    refetchQueries: [
      "SpendGroupsFiltered",
      "SpendTransactionsCompletedWhered",
      "SpendTransactionsPendingWhered",
      { query: GET_ORGANIZATION_ACCOUNTS, fetchPolicy: "network-only" },
    ],
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading && data && data.spendStopCheckPayment) {
      toast?.setToast({
        message: "Stop payment successfully issued",
        type: "success",
      });
      requestStopPaymentToggle();
    }
    // eslint-disable-next-line
  }, [loading, data]);

  return (
    <FixedModal
      isOpen={requestStopPaymentOpen}
      toggle={requestStopPaymentToggle}
      title={""}
      icon="exclamation-solid"
      iconColor="#FEE2E2"
      btn1={{
        text: "Yes",
        btnStyle: "danger",
        onClick: () => {
          const unitId = focusOnTransaction?.correlationId?.split("_")[1];
          stopPayment({
            variables: {
              checkId: unitId,
            },
          });
        },
      }}
      btn2={{
        text: "No",
        btnStyle: "tertiary",
        onClick: requestStopPaymentToggle,
      }}
    >
      <div className="text-center">
        <p className="text-lg font-medium">Request Stop Payment</p>
        <p className="text-sm text-gray-500">
          Are you sure you want to request a stop payment for this payment?
        </p>
      </div>
    </FixedModal>
  );
}

export default RequestStopPayment;
