import SplitIOContext from "context/splitio-context";
import React, { useContext, useEffect, useState } from "react";
import ProgramContext from "context/program-context";
import DisplayContext from "context/display-context";

const ChatWidgetController: React.FC = () => {
  const splits = useContext(SplitIOContext);
  const program = useContext(ProgramContext);
  const display = useContext(DisplayContext);
  const [widgetControlFlag, setWidgetControlFlag] = useState(false);

  useEffect(() => {
    const isWidgetControllerOn =
      splits?.isTreatmentOn(splits.act.se1682, {
        organizationId: program?.organization?.id,
      }) || false;
    setWidgetControlFlag(isWidgetControllerOn);
  }, [splits, program]);

  useEffect(() => {
    const handleScroll = () => {
      const widget = window.HubSpotConversations?.widget;
      const isWidgetVisible = document.getElementById(
        "hubspot-messages-iframe-container"
      );
      const isAtBottom =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight;
      if (isAtBottom && widget) {
        widget.remove();
        console.log("Chat widget hidden: User reached the bottom of the page.");
      } else if (widget && !isWidgetVisible) {
        widget.refresh();
        console.log("Chat widget shown: User not at the bottom.");
      }
    };
    window.hsConversationsOnReady = function () {
      // Ensure HubSpot SDK is available before attaching the scroll event
      if (window.HubSpotConversations?.widget) {
        console.log("HubSpot Conversations SDK is ready!");
        window.addEventListener("scroll", handleScroll);
      }
    };
    if (widgetControlFlag && display?.isMobile) {
      console.log("WidgetControlFlag", widgetControlFlag);
      window.hsConversationsOnReady();
    } else {
      const widget = window.HubSpotConversations?.widget;
      const isWidgetVisible = document.getElementById(
        "hubspot-messages-iframe-container"
      );
      if (widget && !isWidgetVisible) {
        widget.refresh();
      }
      window.removeEventListener("scroll", handleScroll);
    }
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [display?.isMobile, widgetControlFlag]);

  return <div className="h-0 w-0"></div>;
};

export default ChatWidgetController;
