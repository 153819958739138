import { FormatMoney, ParseMoney } from "helpers/format-money";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import { useState, useEffect } from "react";
import { SnapInput, SnapTable } from "suit";
import { SpendBudget, SpendGroup, SpendSeason } from "graphql/generated";
import { ApplyingBankTransaction } from "types/team-banking";

type TableProps = {
  selectedDetails: BudgetReconciliationForm;
  setSelectedDetails: React.Dispatch<
    React.SetStateAction<BudgetReconciliationForm>
  >;
  transactionAmount: number;
  className?: string;
};

export type BudgetReconciliationForm = {
  team?: SpendGroup;
  season?: SpendSeason;
  transaction?: ApplyingBankTransaction;
  distribution?: TransactionAmountDistribution[];
};

export type TransactionAmountDistribution = {
  budget: SpendBudget;
  invoiceDescription?: string;
  invoicePlayerName?: string;
  invoiceId?: string;
  amount: number;
};

function Table({
  selectedDetails,
  setSelectedDetails,
  className,
  transactionAmount,
}: TableProps) {
  const [initialAmounts] = useState<TransactionAmountDistribution[]>([
    ...(selectedDetails.distribution ?? []),
  ]);
  const [balance, setBalance] = useState<number>(
    (transactionAmount ?? 0) -
      MapAndCalcSum(selectedDetails.distribution ?? [], "amount")
  );
  useEffect(() => {
    setBalance(
      (transactionAmount ?? 0) -
        Math.abs(MapAndCalcSum(selectedDetails.distribution ?? [], "amount"))
    );
  }, [initialAmounts, selectedDetails, transactionAmount]);
  return (
    <SnapTable>
      <table className={`ui celled ${className}`}>
        <thead>
          <tr>
            <th className="w-[20%]">Category</th>
            <th>Budget</th>
            <th>Applied Invoice</th>
            <th>Season</th>
            <th className="action-cell">Reconciled Amount</th>
          </tr>
        </thead>
        <tbody>
          {selectedDetails.distribution?.map((amtBudget, idx) => {
            if (!amtBudget) {
              return undefined;
            }
            if (amtBudget.invoiceId) {
              return (
                <tr key={`distrib-table-${amtBudget.invoiceId}-${idx}`}>
                  <td className="cell-highlight" data-label="Category">
                    <p>{amtBudget.budget.category.name}</p>
                  </td>
                  <td className="cell-highlight" data-label="Budget">
                    <p>{amtBudget.budget.description}</p>
                  </td>
                  <td className="cell-highlight" data-label="Applied Invoice">
                    <p className="text-xs">
                      {amtBudget.invoicePlayerName}'s{" "}
                      {amtBudget.invoiceDescription}
                    </p>
                  </td>
                  <td className="cell-highlight" data-label="Season">
                    <p>{selectedDetails.season?.name}</p>
                  </td>
                  <td className="cell-highlight" data-label="Amount">
                    <div className="flex items-center justify-center">
                      {FormatMoney(amtBudget?.amount || 0)}
                    </div>
                  </td>
                </tr>
              );
            } else {
              return (
                <tr key={`distrib-table-${amtBudget.budget.id}`}>
                  <td data-label="Category">
                    <p>{amtBudget.budget.category.name}</p>
                  </td>
                  <td data-label="Budget">
                    <p>{amtBudget.budget.description}</p>
                  </td>
                  <td></td>
                  <td data-label="Season">
                    <p>{selectedDetails.season?.name}</p>
                  </td>
                  <td data-label="Amount">
                    <SnapInput
                      _id={`${amtBudget.budget.id}_${amtBudget.invoiceDescription}`}
                      _type="number"
                      _prepend="$"
                      value={`${((amtBudget.amount ?? 0) / 100).toFixed(2)}`}
                      onSnap-input-change={(e) => {
                        const distribution = selectedDetails.distribution?.find(
                          (target) =>
                            target.budget.id === amtBudget.budget.id &&
                            target.invoiceId == null
                        );
                        if (distribution) {
                          distribution.amount = ParseMoney(
                            e.detail.target.value
                          );
                          setSelectedDetails({ ...selectedDetails });
                        }
                      }}
                    />
                  </td>
                </tr>
              );
            }
          })}
          {selectedDetails.distribution && (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td className="pl-10">Balance Remaining</td>
              <td
                className={`flex justify-end items-center ${
                  balance !== 0 ? "text-red-500" : ""
                }`}
              >
                {FormatMoney(balance)}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default Table;
