const reconcileTypes = [
  "ach",
  "atm",
  "bill pay",
  "cardtransaction",
  "check deposit",
  "check payment",
  "debit card",
  "deposit",
  "fee",
  "invoice payment",
  "invoice refund",
  "payment",
  "send check",
  "transfer in",
  "transfer out",
  "withdrawal",
  "adjustmenttransaction",
  "wiretransaction",
];

const reconciledStates = ["Reconciled", "Partially Reconciled"];
const unreconciledStates = ["Unreconciled", "Partially Reconciled"];

export const transactionMenuItems = [
  {
    name: "Apply to Participant Invoice",
    text: "Apply to Participant Invoice",
    value: "Apply to Participant Invoice",
    type: reconcileTypes,
    key: unreconciledStates,
    secondaryKey: "Invoice",
    trx_types: ["Deposit", "Transfer In"],
    reconciledStates: unreconciledStates,
    menu_type: "Invoice",
  },
  {
    name: "Undo Apply to Participant Invoice",
    text: "Undo Apply to Participant Invoice",
    value: "Undo Apply to Participant Invoice",
    type: reconcileTypes,
    key: reconciledStates,
    secondaryKey: "Invoice",
    trx_types: ["Deposit", "Transfer In"],
    reconciledStates,
    menu_type: "Invoice",
  },
  {
    name: "Reconcile as Income",
    text: "Reconcile as Income",
    value: "Reconcile as Income",
    type: reconcileTypes,
    key: unreconciledStates,
    secondaryKey: "Invoice",
  },
  {
    name: "View/Edit Reconciled Income",
    text: "View/Edit Reconciled Income",
    value: "View/Edit Reconciled Income",
    type: reconcileTypes,
    key: reconciledStates,
    secondaryKey: "Reconciled Budget Income",
  },
  {
    name: "Reconcile as Expense",
    text: "Reconcile as Expense",
    value: "Reconcile as Expense",
    type: reconcileTypes,
    key: unreconciledStates,
    secondaryKey: "Invoice",
  },
  {
    name: "View/Edit Reconciled Expense",
    text: "View/Edit Reconciled Expense",
    value: "View/Edit Reconciled Expense",
    type: reconcileTypes,
    key: reconciledStates,
    secondaryKey: "Reconciled Budget Expense",
  },
  {
    name: "Add Income Budget Item",
    text: "Add Income Budget Item",
    value: "Add Income Budget Item",
    type: reconcileTypes,
    key: unreconciledStates,
  },
  {
    name: "Add Expense Budget Item",
    text: "Add Expense Budget Item",
    value: "Add Expense Budget Item",
    id: 8,
    type: reconcileTypes,
    key: unreconciledStates,
  },
  {
    name: "Request Cancel",
    text: "Request Cancel",
    value: "Request Cancel",
    id: 9,
    type: ["checkpayment", "ach"],
    key: ["Unreconciled"],
    isPending: true,
  },
  {
    name: "Request Stop Payment",
    text: "Request Stop Payment",
    value: "Request Stop Payment",
    id: 9,
    type: ["checkpayment"],
    key: ["Unreconciled"],
    isPending: false,
  },
  {
    name: "Dispute Transaction",
    text: "Dispute Transaction",
    value: "Dispute Transaction",
    id: 10,
    type: ["debit card"],
    key: ["Reconciled", "Unreconciled", "Partially Reconciled"],
  },
];
