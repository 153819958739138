import { useContext, useEffect, useState } from "react";

import {
  SpendBankAccount,
  useExternalAccountsQuery,
} from "../../graphql/generated";
import UserContext from "../../context/user-context";
import TableRowLabelValue from "shared-components/table-row-label-value";

type UserBankMethodProps = {};

function UserBankMethod(props: UserBankMethodProps) {
  const user = useContext(UserContext);

  const [bank, setBank] = useState<SpendBankAccount | undefined>();
  const [bankLabel, setBankLabel] = useState<string>();
  useExternalAccountsQuery({
    onCompleted: ({ spendUserBankAccounts }) => {
      if (spendUserBankAccounts?.externalAccounts?.[0]) {
        const bankAccount = spendUserBankAccounts?.externalAccounts[0];

        bankAccount &&
          setBank({ ...bankAccount, entityId: bankAccount.id || "" });
      }
    },
    skip: !user?.isParent(),
  });
  useEffect(() => {
    if (bank?.status === "pending_manual_verification") {
      setBankLabel(`Bank - Verification Required (${bank.entityId})`);
    } else {
      setBankLabel(bank ? `${bank.name} - ${bank.mask}` : "Bank");
    }
  }, [bank]);

  return <TableRowLabelValue label={"Method"} value={bankLabel} />;
}

export default UserBankMethod;
