import { extractBankLabel } from "helpers/banking";
import type {
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
} from "../../../graphql/generated";

function DefaultPaymentMethod({
  title = "Payment Method",
  bankAccount,
  paymentMethods,
}: {
  title?: string;
  bankAccount?: SpendBankAccount;
  paymentMethods?: PaymentsApiCustomerPaymentMethod;
}) {
  const bankAccountValue = bankAccount ? extractBankLabel(bankAccount) : null;
  const paymentMethod = bankAccountValue ?? paymentMethods?.identifier;
  return paymentMethod ? (
    <div>
      <h3 className="font-semibold text-lg mt-3 mb-4 md:mb-6">{title}</h3>
      <div className="w-full flex items-center border border-gray-200 rounded-xl p-4">
        <div className="text-sm pt-0.5 pr-1 text-gray-500 uppercase grow-0">
          {bankAccount ? "ACH" : "CARD"}
        </div>
        <div className="px-1 ml-2 grow">
          <p>{paymentMethod}</p>
        </div>
      </div>
    </div>
  ) : (
    <h3 className="font-semibold text-lg mt-3 mb-4 md:mb-6">
      Payment method failed to load.
    </h3>
  );
}

export default DefaultPaymentMethod;
