import { InvoiceCardView } from "../make-payment/invoice-card-view";
import { InvoiceTableView } from "../make-payment/invoice-table-view";
import { SelectedPlayer } from "../make-payment/selected-player";
import { SubHeaderView } from "../make-payment/sub-header-view";
import {
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroupRoster,
} from "graphql/generated";
import { SelectedInvoice } from "types/invoice";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import SelectPaymentMethod from "shared-components/select-payment-method/select-payment-method";

type Step1Props = {
  playerSelected: SpendGroupRoster | undefined;
  selectableInvoices: SelectedInvoice[];
  handleCheckId: (e: SelectedInvoice | null, more?: any) => void;
  handleOptStatus: (status: boolean, invoice: SelectedInvoice) => void;
  paymentTypeOptions: SnapSelectMenuOption[];
  setPaymentTypeOptions: React.Dispatch<
    React.SetStateAction<SnapSelectMenuOption[]>
  >;
  setSelectedPaymentTypeOption: React.Dispatch<
    React.SetStateAction<`Pay by: ${"Card" | "Bank"}` | undefined>
  >;
  bankOptions: SnapSelectMenuOption[];
  setCardOptions: React.Dispatch<React.SetStateAction<SnapSelectMenuOption[]>>;
  payByCard: boolean;
  widgetOpen: boolean;
  cards: PaymentsApiCustomerPaymentMethod[];
  groupAccountId: string;
  paymentMethodCreate: () => void;
  payByBank: boolean;
  bankAccounts: SpendBankAccount[];
  cardOptions: SnapSelectMenuOption[];
  setBankOptions: React.Dispatch<React.SetStateAction<SnapSelectMenuOption[]>>;
  accountStatus: string;
  setSelectedBankAccount: React.Dispatch<
    React.SetStateAction<SpendBankAccount | undefined>
  >;
  setSelectedCard: React.Dispatch<
    React.SetStateAction<PaymentsApiCustomerPaymentMethod | undefined>
  >;
};

export default function Step1({
  payByCard,
  payByBank,
  playerSelected,
  selectableInvoices,
  handleCheckId,
  handleOptStatus,
  paymentTypeOptions,
  setSelectedPaymentTypeOption,
  setPaymentTypeOptions,
  cardOptions, //cardOptions
  cards, //possible cards
  setSelectedCard, //selectedCard
  setCardOptions, //set card Options

  widgetOpen,
  groupAccountId,
  paymentMethodCreate,
  bankAccounts,
  bankOptions,
  setBankOptions,
  setSelectedBankAccount,
}: Step1Props) {
  return (
    <div className="mx-3 mt-2">
      <SelectedPlayer playerSelected={playerSelected} />
      <SubHeaderView text={"Invoices to Authorize for AutoPay"} />
      <div className="hidden lg:flex">
        <InvoiceTableView
          list={selectableInvoices}
          isReadonly={false}
          handleCheckId={handleCheckId}
          handleOptStatus={handleOptStatus}
          isAuthorizeFlow={true}
        />
      </div>
      <div>
        <InvoiceCardView
          list={selectableInvoices}
          isReadonly={false}
          handleCheckId={handleCheckId}
          handleOptStatus={handleOptStatus}
          isAuthorizeFlow={true}
        />
      </div>
      <p className="text-sm text-gray-400 mt-4 lg:mt-0">
        *This includes fees to support our platform's technology and security
        safeguards
      </p>
      <SelectPaymentMethod
        groupId={playerSelected?.groupId ?? undefined}
        paymentTypeArgs={{
          paymentTypeOptions,
          setPaymentTypeOptions,
          setSelectedPaymentTypeOption,
          paymentTypes: ["Pay by: Card", "Pay by: Bank"],
        }}
        bankArgs={{
          bankOptions,
          bankAccounts,
          setSelectedBankAccount,
          setBankOptions,
        }}
        cardArgs={{
          setCardOptions,
          cards,
          setSelectedCard,
          cardOptions,
          cardCreate: paymentMethodCreate,
        }}
        payByCard={payByCard}
        widgetOpen={widgetOpen}
        groupAccountId={groupAccountId}
        payByBank={payByBank}
      />
    </div>
  );
}
