import { useMutation, useQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import UserContext from "context/user-context";
import {
  Maybe,
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroupRoster,
  useExternalAccountsListQuery,
  usePaymentsApiCustomerMutation,
} from "graphql/generated";
import {
  GET_GROUP,
  GET_PARENT_INVOICES_CHANGE_PAYMENT,
} from "graphql/queries/group";
import { GET_GUARDIAN_HIGHLIGHT_2 } from "graphql/queries/user";
import useToast from "hooks/use-toast";
import React, { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import { SnapButton, SnapCheckboxButton } from "suit";
import { SelectedInvoice } from "types/invoice";
import CustomModal from "..";
import { defaultSelectedPaymentOption } from "../make-payment/make-payment-helper";
import { SubHeaderView } from "../make-payment/sub-header-view";
import { ChangePaymentCard } from "./change-payment-card";
import { ChangePaymentTable } from "./change-payment-table";
import { SelectedInvoicesCard } from "./selected-invoices-card";
import { SelectedInvoicesTable } from "./selected-invoices-table";
import { CHANGE_INVOICE_PAYMENT_METHOD } from "graphql/mutations/invoice";
import Spinner from "shared-components/spinner";
import { extractBankLabel } from "helpers/banking";
import SelectPaymentMethod from "shared-components/select-payment-method/select-payment-method";

type AddChangePaymentType = {
  isOpen: boolean;
  toggle: () => void;
};
export type PlayerInfoType = {
  team: Maybe<string> | undefined;
  player: Maybe<string> | undefined;
  invoices: Maybe<SelectedInvoice>[] | undefined;
  groupId: Maybe<string> | undefined;
  // selected: boolean;
};

export const ChangePaymentModal = ({
  isOpen,
  toggle,
}: AddChangePaymentType) => {
  const ActiveUser = useContext(UserContext);
  const [widgetOpen, setWidgetOpen] = useState(false);
  // eslint-disable-next-line
  const [
    getPaymentMethods,
    { loading: loadingPaymentMethod, data: paymentMethodData },
  ] = usePaymentsApiCustomerMutation();
  const { data: groupData } = useQuery(GET_GROUP, {
    skip: !isOpen,
  });
  const { data: getGroupData, loading: loadingGroupData } = useQuery(
    GET_PARENT_INVOICES_CHANGE_PAYMENT,
    {
      variables: {
        filterBy: "memberId",
        filterValue: ActiveUser?._session?.userId,
      },
      skip: !ActiveUser?._session?.userId || !isOpen,
    }
  );
  const { data: accountsData, loading: accountsLoading } =
    useExternalAccountsListQuery({
      skip: !ActiveUser?._session?.userId || !isOpen,
    });
  const [bankAccounts, setBankAccounts] = useState<SpendBankAccount[]>([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState<
    SpendBankAccount | undefined
  >();
  const [bankOptions, setBankOptions] = useState<SnapSelectMenuOption[]>([]);

  const [cards, setCards] = useState<PaymentsApiCustomerPaymentMethod[]>([]);
  const [cardOptions, setCardOptions] = useState<SnapSelectMenuOption[]>([]);
  const [selectedCard, setSelectedCard] = useState<
    PaymentsApiCustomerPaymentMethod | undefined
  >();
  const [paymentTypeOptions, setPaymentTypeOptions] = useState<
    SnapSelectMenuOption[]
  >(defaultSelectedPaymentOption);
  const [selectedPaymentTypeOption, setSelectedPaymentTypeOption] = useState<
    `Pay by: ${"Card" | "Bank"}` | undefined
  >();

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [playersInfo, setPlayerInfo] = useState<PlayerInfoType[]>([]);
  const [selectedInvoices, setSelectedInvoices] = useState<PlayerInfoType[]>(
    []
  );
  const [cardBlock, setCardBlock] = useState(false);
  const [isSelectBoxChecked, setSelectBoxChecked] = useState(false);
  const [step, setStep] = useState(0);
  const { isToastOpen, ...toast } = useToast();
  const [isBtnActive, setIsBtnActive] = useState(false);

  const [isNextDisabled, setIsNextDisabled] = useState(false);
  useEffect(() => {
    const amounts = selectedInvoices.flatMap((selectedInvoice) =>
      selectedInvoice.invoices?.map((inv) => inv?.balanceDue)
    );
    setCardBlock(
      selectedPaymentTypeOption === "Pay by: Card" &&
        amounts.some((am) => am && am <= 50)
    );
  }, [selectedPaymentTypeOption, selectedInvoices]);
  const paymentMethodCreate = () => {
    setWidgetOpen(false);
    getPaymentMethods();
  };
  useEffect(() => {
    if (paymentMethodData && paymentMethodData.paymentsApiCustomer) {
      const tempCards = (paymentMethodData?.paymentsApiCustomer
        ?.paymentMethods || []) as PaymentsApiCustomerPaymentMethod[];
      setWidgetOpen(tempCards.length === 0);
      setCards(tempCards);
      setCardOptions(
        tempCards.map((tCard, idx) => {
          const { month, year } = tCard?.expiration || {
            month: null,
            year: null,
          };
          const expires = month && year ? `(${month}/${year % 100})` : "";
          return {
            name: `${tCard.identifier} ${expires}`,
            value: tCard.id,
            selected: idx === 0,
          };
        })
      );
      const defaultCard = tempCards?.at(0);
      setSelectedCard(defaultCard);
    }
  }, [loadingPaymentMethod, paymentMethodData]);
  // useEffect(() => {
  //   if (groupData && groupData.spendGroup) {
  //     setGroupAccountId(groupData.spendGroup.accountId);
  //   }
  // }, [loadingGroup, groupData]);

  useEffect(() => {
    if (ActiveUser?.getEmail() && isOpen) {
      getPaymentMethods();
    }
  }, [ActiveUser, isOpen, getPaymentMethods]);

  const handlePlayerData = (playerArray: SpendGroupRoster[]) => {
    const selectedInvoiceIds = selectedInvoices.flatMap((gr) =>
      gr.invoices?.map((inv) => inv!.id)
    );
    let newInfo = playerArray
      ?.map((player) => {
        return {
          team: player.group?.name,
          player: player.roster?.name,
          groupId: player.groupId,
          invoices: player.invoices
            ?.filter((invoice) => invoice?.paid === false)
            .map((invoice) => ({
              ...invoice,
              selected: selectedInvoiceIds.includes(invoice?.id),
            })),
        };
      })
      .filter((player) => player.invoices?.length !== 0);

    setPlayerInfo(newInfo);
  };

  const handleSelectedInvoices = (invoiceIdx: number, playerIdx: number) => {
    let tempPlayersInvoices = [...playersInfo];
    let selectInvoice: Maybe<SelectedInvoice> =
      tempPlayersInvoices[playerIdx].invoices![invoiceIdx];
    let newSelectedInvoice: SelectedInvoice = {
      ...selectInvoice,
      selected: !selectInvoice?.selected,
    };

    tempPlayersInvoices[playerIdx].invoices?.splice(
      invoiceIdx,
      1,
      newSelectedInvoice
    );
    setPlayerInfo(tempPlayersInvoices);
  };

  useEffect(() => {
    let invoiceSelected = playersInfo
      .map((player) => {
        return {
          team: player.team,
          player: player.player,
          invoices: player.invoices?.filter(
            (invoice) => invoice?.selected === true
          ),
          groupId: player.groupId,
        };
      })
      .filter((x) => x.invoices?.length !== 0);

    setSelectedInvoices(invoiceSelected);
  }, [playersInfo]);

  const handleSelectAll = (isChecked: boolean) => {
    let tempPlayersInvoices = [...playersInfo];
    if (!isChecked) {
      let allSelectedPlayers = tempPlayersInvoices.map((player) => {
        return {
          ...player,
          invoices: player.invoices?.map((invoice) => {
            return {
              ...invoice,
              selected: true,
            };
          }),
        };
      });
      setPlayerInfo(allSelectedPlayers);
    } else {
      let allSelectedPlayers = tempPlayersInvoices.map((player) => {
        return {
          ...player,
          invoices: player.invoices?.map((invoice) => {
            return {
              ...invoice,
              selected: false,
            };
          }),
        };
      });
      setPlayerInfo(allSelectedPlayers);
    }
  };

  useEffect(() => {
    if (!loadingGroupData && getGroupData && getGroupData?.spendGroupRosters) {
      handlePlayerData(getGroupData?.spendGroupRosters.groupRosters);
    }
    if (!accountsLoading && accountsData?.spendUserBankAccountsList) {
      const tempAccounts = [
        ...(accountsData.spendUserBankAccountsList.externalAccounts || []),
      ];
      setBankAccounts(tempAccounts);
      setBankOptions(
        tempAccounts.map((tAccount, idx) => ({
          name: extractBankLabel(tAccount),
          value: tAccount.entityId,
          selected: idx === 0,
        }))
      );
      setSelectedBankAccount(tempAccounts?.at(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getGroupData, loadingGroupData, accountsData, accountsLoading]);
  const [changePaymentMethod] = useMutation(CHANGE_INVOICE_PAYMENT_METHOD, {
    refetchQueries: [
      {
        query: GET_PARENT_INVOICES_CHANGE_PAYMENT,
        variables: {
          filterBy: "memberId",
          filterValue: ActiveUser?._session?.userId,
        },
      },
      {
        query: GET_GUARDIAN_HIGHLIGHT_2,
      },
    ],
  });
  const handleChangeInvoicePayment = () => {
    let invoicesIds = selectedInvoices.flatMap((player) =>
      player.invoices?.map((invoice) => invoice?.id)
    );
    let paymentData = {
      paymentMethodId: "",
      paymentMethodSource: "",
    };
    if (selectedPaymentTypeOption === "Pay by: Bank") {
      paymentData = {
        paymentMethodId: selectedBankAccount?.id ?? "",
        paymentMethodSource: "ACH",
      };
    } else {
      paymentData = {
        paymentMethodId: selectedCard?.id ?? "",
        paymentMethodSource: "CARD",
      };
    }
    changePaymentMethod({
      variables: {
        input: {
          ids: invoicesIds,
          paymentMethodId: paymentData.paymentMethodId,
          paymentMethodSource: paymentData.paymentMethodSource,
        },
      },
    })
      .then(() => {
        resetFormAndClose();
      })
      .catch((e) => {
        toast.setToastProps({
          message: e.message || "Unable to save payment method.",
          type: "danger",
        });
      })
      .finally(() => {
        setIsBtnActive(false);
      });
  };
  useEffect(() => {
    const hasNoPaymentOrNoInvoices =
      selectedPaymentTypeOption === undefined || selectedInvoices.length === 0;
    const isPayByBankAndNoBank =
      selectedPaymentTypeOption === "Pay by: Bank" &&
      selectedBankAccount == null;
    const isPayByCardAndPmEmpty =
      selectedPaymentTypeOption === "Pay by: Card" && cards.length === 0;
    const hasNoAuthOrBtnActive = !isAuthorized || isBtnActive;
    if (
      step === 0 &&
      (hasNoPaymentOrNoInvoices ||
        isPayByBankAndNoBank ||
        isPayByCardAndPmEmpty)
    ) {
      setIsNextDisabled(true);
    } else if (step === 1 && hasNoAuthOrBtnActive) {
      setIsNextDisabled(true);
    } else {
      setIsNextDisabled(false);
    }
  }, [
    isAuthorized,
    isBtnActive,
    cards.length,
    selectedBankAccount,
    selectedInvoices.length,
    selectedPaymentTypeOption,
    step,
  ]);
  const resetFormAndClose = () => {
    setSelectedInvoices([]);
    setPaymentTypeOptions(
      paymentTypeOptions.map((op) => ({ ...op, selected: false }))
    );
    setSelectedPaymentTypeOption(undefined);
    toggle();
  };
  return (
    <>
      <CustomModal
        isOpen={isOpen}
        toggle={resetFormAndClose}
        title="Change Payment Method"
        customStyle="lg:w-[974px]"
      >
        <div className="mx-3 mt-2 modal-card lg:h-[500px] overflow-auto">
          {step === 0 && (
            <React.Fragment>
              <SubHeaderView text={"Invoices"} hideDivider={true} />
              <div className="hidden lg:flex">
                <ChangePaymentTable
                  players={playersInfo}
                  handleSelectedInvoices={handleSelectedInvoices}
                  handleSelectAll={handleSelectAll}
                  setSelectBoxChecked={setSelectBoxChecked}
                  isSelectBoxChecked={isSelectBoxChecked}
                />
              </div>
              <div>
                <ChangePaymentCard
                  players={playersInfo}
                  handleSelectedInvoices={handleSelectedInvoices}
                  handleSelectAll={handleSelectAll}
                  setSelectBoxChecked={setSelectBoxChecked}
                  isSelectBoxChecked={isSelectBoxChecked}
                />
              </div>
              <SelectPaymentMethod
                title={"Payment Method"}
                payByBank={selectedPaymentTypeOption === "Pay by: Bank"}
                payByCard={selectedPaymentTypeOption === "Pay by: Card"}
                widgetOpen={widgetOpen}
                groupAccountId={groupData.spendGroup.id || ""}
                paymentTypeArgs={{
                  paymentTypeOptions: paymentTypeOptions,
                  setPaymentTypeOptions: setPaymentTypeOptions,
                  paymentTypes: ["Pay by: Bank", "Pay by: Card"],
                  setSelectedPaymentTypeOption: setSelectedPaymentTypeOption,
                }}
                bankArgs={{
                  bankOptions,
                  setBankOptions,
                  bankAccounts,
                  setSelectedBankAccount,
                }}
                cardArgs={{
                  cards,
                  setCardOptions,
                  cardOptions,
                  setSelectedCard,
                  cardCreate: paymentMethodCreate,
                  cardBlock,
                }}
              />
            </React.Fragment>
          )}
          {step === 1 && (
            <React.Fragment>
              <SubHeaderView text={"Summary"} hideDivider={true} />
              <p className="mt-4">Invoices</p>
              <SelectedInvoicesTable
                selectedPlayerInvoice={selectedInvoices}
                selectedPaymentTypeOption={selectedPaymentTypeOption}
              />
              <SelectedInvoicesCard
                selectedPlayerInvoice={selectedInvoices}
                selectedPaymentTypeOption={selectedPaymentTypeOption}
              />
              <Divider />
              <div className="flex mt-5">
                <p>Payment Method</p>
                {selectedPaymentTypeOption === "Pay by: Bank" ? (
                  <p className=" ml-10">{bankAccounts.at(0)?.name}</p>
                ) : (
                  <p className=" ml-10">{cards.at(0)?.identifier}</p>
                )}
                {/* <p className=" ml-10">{selectedPaymentTypeOption}</p> */}
              </div>
              <Divider isVisibleOnMobile />
              <SnapCheckboxButton
                className="mt-5"
                label="I authorize Snap! Spend to charge my payment method according to the above schedule."
                checked={isAuthorized}
                onClick={(e) => setIsAuthorized(e.currentTarget.checked)}
              />
              <Divider isVisibleOnMobile />
              <div className="mt-5">
                <ul>
                  <li>
                    The payment method noted above will be applied to all
                    invoices listed above.
                  </li>
                  <li>
                    You will be charged on the billing date listed above for
                    each invoice.
                  </li>
                </ul>
              </div>
            </React.Fragment>
          )}

          <div className="flex justify-end space-x-3 mt-5 m-3">
            <SnapButton
              icon="arrow-narrow-left-line"
              variant="tertiary"
              onClick={() => {
                if (step === 1) {
                  setStep(0);
                } else {
                  resetFormAndClose();
                }
              }}
            >
              Back
            </SnapButton>
            <SnapButton
              icon={step === 0 ? "arrow-narrow-right-line" : undefined}
              variant="primary"
              disabled={isNextDisabled}
              onClick={() => {
                if (
                  step === 0 &&
                  selectedPaymentTypeOption !== undefined &&
                  selectedInvoices.length !== 0
                ) {
                  setStep(1);
                } else if (step === 1 && isAuthorized) {
                  setIsBtnActive(true);
                  handleChangeInvoicePayment();
                }
              }}
            >
              {step === 0 && <p>Next</p>}
              {step === 1 && isBtnActive ? (
                <Spinner size="small" className="mx-6" />
              ) : (
                step === 1 && "Authorize Billing"
              )}
            </SnapButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
};
