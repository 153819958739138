import { useEffect, useState } from "react";
import CustomModal from "..";
import { SnapButton } from "suit";
import DotProgress from "shared-components/dot-progress";
import { SubHeaderView } from "../make-payment/sub-header-view";
import { useQuery } from "@apollo/client";
import {
  GET_GROUP_ROSTERS,
  GET_GUARDIAN_GROUP_ROSTERS,
} from "graphql/queries/group";
import {
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroupRoster,
  useExternalAccountsListQuery,
  usePaymentsApiCustomerMutation,
  useSpendAuthorizeAutoPayMutation,
} from "graphql/generated";
import { SelectedInvoice } from "types/invoice";
import { GET_GUARDIAN_HIGHLIGHT_2 } from "graphql/queries/user";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { defaultSelectedPaymentOption } from "../make-payment/make-payment-helper";
import { useContextStrict } from "helpers/context-strict";
import Step0 from "./step-0";
import Step1 from "./step-1";
import Step2 from "./step-2";
import { GET_GUARDIAN_TRANSACTIONS } from "graphql/queries/transactions";
import Spinner from "shared-components/spinner";
import ToastContext from "context/toast-context";
import { extractBankLabel } from "helpers/banking";

type AuthorizeAutoPayProps = {
  isOpen: boolean;
  toggle: () => void;
  groupRosterId?: string;
};

export default function AuthorizeAutoPay({
  isOpen,
  toggle,
  groupRosterId,
}: AuthorizeAutoPayProps) {
  const toast = useContextStrict(ToastContext);
  const [step, setStep] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAuthorizingAutoPay, setIsAuthorizingAutoPay] = useState(false);
  const [widgetOpen, setWidgetOpen] = useState(false);
  const [groupAccountId, setGroupAccountId] = useState("");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [players, setPlayers] = useState<SpendGroupRoster[] | undefined>([]);
  const [isDisable, setIsDisable] = useState(true);
  const [selectableInvoices, setSelectableInvoices] = useState<
    SelectedInvoice[]
  >([]);
  const [playerSelected, setPlayerSelected] = useState<
    SpendGroupRoster | undefined
  >();
  const [showNoInvoicesMessage, setShowNoInvoiceMessage] =
    useState<boolean>(false);

  // payment type selection
  const [selectedPaymentTypeOption, setSelectedPaymentTypeOption] = useState<
    `Pay by: ${"Card" | "Bank"}` | undefined
  >();
  const [paymentTypeOptions, setPaymentTypeOptions] = useState<
    SnapSelectMenuOption[]
  >(defaultSelectedPaymentOption);
  // bank selection states
  const [bankAccounts, setBankAccounts] = useState<SpendBankAccount[]>([]);
  const [bankOptions, setBankOptions] = useState<SnapSelectMenuOption[]>([]);
  const [accountStatus, setAccountStatus] = useState("");
  const [selectedBankAccount, setSelectedBankAccount] = useState<
    SpendBankAccount | undefined
  >();

  //card selection states
  const [cards, setCards] = useState<PaymentsApiCustomerPaymentMethod[]>([]);
  const [cardOptions, setCardOptions] = useState<SnapSelectMenuOption[]>([]);
  const [selectedCard, setSelectedCard] = useState<
    PaymentsApiCustomerPaymentMethod | undefined
  >();

  const { data: getGroupData } = useQuery(GET_GUARDIAN_GROUP_ROSTERS);
  const { data: accountsData, loading: accountsLoading } =
    useExternalAccountsListQuery();
  const [
    getPaymentMethods,
    { loading: loadingPaymentMethod, data: paymentMethodData },
  ] = usePaymentsApiCustomerMutation();

  const [
    authorizeAutoPay,
    { loading: loadingAuthorizeAutoPay, data: authorizeAutoPayData },
  ] = useSpendAuthorizeAutoPayMutation({
    refetchQueries: [
      { query: GET_GUARDIAN_HIGHLIGHT_2 },
      { query: GET_GUARDIAN_TRANSACTIONS },
      { query: GET_GROUP_ROSTERS },
      { query: GET_GUARDIAN_GROUP_ROSTERS },
    ],
    fetchPolicy: "network-only",
  });

  const payByBank = selectedPaymentTypeOption === "Pay by: Bank";
  const payByCard = selectedPaymentTypeOption === "Pay by: Card";

  useEffect(() => {
    if (
      !loadingAuthorizeAutoPay &&
      authorizeAutoPayData &&
      authorizeAutoPayData.spendAuthorizeAutoPay
    ) {
      toast.setToastProps({
        message: "AutoPay Successfully Enabled",
        type: "success",
      });
      toggle();
      setIsAuthorizingAutoPay(false);
    }

    if (
      !loadingAuthorizeAutoPay &&
      authorizeAutoPayData &&
      !authorizeAutoPayData.spendAuthorizeAutoPay
    ) {
      toast.setToastProps({
        message: "Error Enabling AutoPay",
        type: "danger",
      });
    }
    // eslint-disable-next-line
  }, [loadingAuthorizeAutoPay, authorizeAutoPayData]);

  useEffect(() => {
    if (paymentMethodData && paymentMethodData.paymentsApiCustomer) {
      setWidgetOpen(
        paymentMethodData.paymentsApiCustomer.paymentMethods.length === 0
      );
      const tempCards = (paymentMethodData?.paymentsApiCustomer
        ?.paymentMethods || []) as PaymentsApiCustomerPaymentMethod[];
      setCards(tempCards);
      setCardOptions(
        tempCards.map((tCard, idx) => {
          const { month, year } = tCard?.expiration || {
            month: null,
            year: null,
          };
          const expires = month && year ? `(${month}/${year % 100})` : "";
          return {
            name: `${tCard.identifier} ${expires}`,
            value: tCard.id,
            selected: idx === 0,
          };
        })
      );
      const defaultCard = tempCards?.at(0);
      setSelectedCard(defaultCard);
    }
  }, [loadingPaymentMethod, paymentMethodData]);

  useEffect(() => {
    if (getGroupData?.spendGuardianGroupRosters?.groupRosters) {
      // only show players with invoices that are not paid
      setPlayers(
        getGroupData.spendGuardianGroupRosters.groupRosters.filter(
          (groupRoster: SpendGroupRoster) => {
            if (
              groupRoster &&
              groupRoster.invoices &&
              !groupRoster.isArchived &&
              groupRoster.invoices.length > 0 &&
              groupRoster.joinedAt
            ) {
              if (
                groupRoster.invoices.some(
                  (invoice) => !invoice?.paid && !invoice?.authorizedAt
                )
              ) {
                if (groupRosterId) {
                  return groupRoster.id === groupRosterId;
                }
                return true;
              }
            }
            return false;
          }
        )
      );
    }
  }, [getGroupData, groupRosterId]);

  useEffect(() => {
    if (!accountsLoading && accountsData?.spendUserBankAccountsList) {
      const tempAccounts =
        accountsData.spendUserBankAccountsList.externalAccounts || [];
      setBankAccounts(tempAccounts);
      setBankOptions(
        tempAccounts.map((tAccount, idx) => ({
          name: extractBankLabel(tAccount),
          value: tAccount.entityId,
          selected: idx === 0,
        }))
      );
      const defaultBank = tempAccounts.at(0);
      setAccountStatus(defaultBank?.status || "");
      setSelectedBankAccount(defaultBank);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsLoading, accountsData?.spendUserBankAccountsList]);

  useEffect(() => {
    setShowNoInvoiceMessage((players?.length ?? 0) === 0);
  }, [players]);

  const selectPlayer = (player: SpendGroupRoster) => {
    setPlayerSelected(player);
    // show invoices that are not paid
    if (player && player.invoices) {
      const unpaidInvoices = player.invoices
        .filter(
          (invoice) =>
            !invoice?.paid &&
            !invoice?.authorizedAt &&
            invoice?.optedOutAt == null
        )
        .sort((a, b) =>
          a?.dueDate! > b?.dueDate! ? 1 : a?.dueDate! < b?.dueDate! ? -1 : 0
        )
        .map((invoice) => ({ ...invoice, selected: true }));
      setSelectableInvoices(unpaidInvoices);
      setSelectedPaymentTypeOption(undefined);
      setPaymentTypeOptions(defaultSelectedPaymentOption);
      setIsDisable(false);
      if (player.group?.accountId) {
        setGroupAccountId(player.group?.accountId);
      } else {
        toast.setToastProps({
          message: "Cant find group account id",
          type: "danger",
        });
      }
      getPaymentMethods();
    }
  };

  const handleCheckId = (e: SelectedInvoice | null, more?: any) => {
    let tempList = [...selectableInvoices];
    if (e === null) {
      tempList = tempList.map((sInv) => ({
        ...sInv,
        selected: more,
      }));
      setSelectableInvoices(tempList);
    } else {
      const index = tempList.findIndex((inv) => inv.id === e?.id);

      tempList.splice(index, 1, { ...e, selected: more });
      setSelectableInvoices(tempList);
    }
  };

  const handleOptStatus = (status: boolean, invoice: SelectedInvoice) => {
    let idx = selectableInvoices.findIndex((i) => i.id === invoice?.id);
    let tempList = [...selectableInvoices];
    tempList.splice(idx, 1, { ...invoice, optedIn: status });
    setSelectableInvoices(tempList);
  };

  const paymentMethodCreate = () => {
    setWidgetOpen(false);
    getPaymentMethods();
  };

  const handleAuthorizeAutoPay = () => {
    const filteredInvoices = selectableInvoices.filter(
      (i) => i.optedIn || !i.isOptional
    );

    if (!filteredInvoices.length) {
      toast.setToast({
        message: "No invoices to authorize",
        type: "danger",
      });
    } else if (!isAuthorized) {
      toast.setToast({
        message: "Please authorize the invoices before proceeding",
        type: "danger",
      });
    } else {
      setIsAuthorizingAutoPay(true);
      authorizeAutoPay({
        variables: {
          input: {
            invoiceIds: filteredInvoices.map(({ id }) => id!),
            groupRosterId: playerSelected!.id!,
            paymentMethodSource: payByBank ? "ACH" : "CARD",
            cardId: payByCard ? selectedCard?.id : undefined,
            counterpartyId: payByBank ? selectedBankAccount?.id : undefined,
          },
        },
      });
    }
  };

  const handleReset = () => {
    setIsAuthorized(false);
    setSelectableInvoices([]);
    setIsAuthorizingAutoPay(false);
    toggle();
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={handleReset}
      title="Authorize For AutoPay"
      customStyle="lg:w-[974px]"
    >
      <div className="modal-card lg:h-[500px] overflow-auto">
        {showNoInvoicesMessage && (
          <div className="mx-3">
            <h2 className="font-semibold text-center mt-4">No Invoices Due</h2>
          </div>
        )}
        {!showNoInvoicesMessage && (
          <div className="mx-3">
            <DotProgress state={step > 2 ? 2 : step} />
            <SubHeaderView text={"Choose Participant"} hideDivider={true} />
            {step === 0 && (
              <Step0
                players={players}
                playerSelected={playerSelected}
                errors={errors}
                setErrors={setErrors}
                selectPlayer={selectPlayer}
              />
            )}
          </div>
        )}

        {step === 1 && (
          <Step1
            playerSelected={playerSelected}
            selectableInvoices={selectableInvoices}
            widgetOpen={widgetOpen}
            handleCheckId={handleCheckId}
            handleOptStatus={handleOptStatus}
            groupAccountId={groupAccountId}
            paymentTypeOptions={paymentTypeOptions}
            setSelectedPaymentTypeOption={setSelectedPaymentTypeOption}
            setCardOptions={setCardOptions}
            setSelectedCard={setSelectedCard}
            payByCard={payByCard}
            cards={cards}
            cardOptions={cardOptions}
            paymentMethodCreate={paymentMethodCreate}
            payByBank={payByBank}
            bankAccounts={bankAccounts}
            bankOptions={bankOptions}
            setBankOptions={setBankOptions}
            accountStatus={accountStatus}
            setSelectedBankAccount={setSelectedBankAccount}
            setPaymentTypeOptions={setPaymentTypeOptions}
          />
        )}
        {step === 2 && (
          <Step2
            playerSelected={playerSelected}
            selectableInvoices={selectableInvoices}
            handleCheckId={handleCheckId}
            handleOptStatus={handleOptStatus}
            paymentMethod={selectedCard}
            selectedBankAccount={selectedBankAccount}
            selectedPaymentTypeOption={selectedPaymentTypeOption}
            isAuthorized={isAuthorized}
            setIsAuthorized={setIsAuthorized}
          />
        )}
        <div className="flex justify-end space-x-3 mt-5 m-3">
          {step === 0 && (
            <SnapButton variant="tertiary" onClick={handleReset}>
              Cancel
            </SnapButton>
          )}
          {step > 0 && (
            <SnapButton
              icon="arrow-narrow-left-line"
              variant="tertiary"
              onClick={() => {
                step > 0 && setStep(step - 1);
                setIsDisable(false);
              }}
            >
              Back
            </SnapButton>
          )}
          {step < 2 && (
            <SnapButton
              icon="arrow-narrow-right-line"
              variant="primary"
              disabled={
                step === 1 ? selectedPaymentTypeOption === undefined : isDisable
              }
              onClick={() => {
                if (!isDisable && step === 0) {
                  setStep(step + 1);
                  setIsDisable(true);
                }
                if (step === 1 && selectedPaymentTypeOption !== undefined) {
                  setStep(step + 1);
                }
              }}
            >
              Next
            </SnapButton>
          )}
          {step >= 2 && (
            <SnapButton
              variant="primary"
              disabled={!isAuthorized || isAuthorizingAutoPay}
              onClick={() => {
                handleAuthorizeAutoPay();
              }}
            >
              {!isAuthorizingAutoPay ? (
                "Authorize For AutoPay"
              ) : (
                <Spinner size="small" className="w-14" />
              )}
            </SnapButton>
          )}
        </div>
      </div>
    </CustomModal>
  );
}
