import { SpendBankTransactionDetail } from "graphql/generated";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import { SnapBadge, SnapTable } from "suit";
import { DateFormatSupported, FormatDate } from "helpers/format-date";

type TransactionHistoryCardProps = {
  transaction: SpendBankTransactionDetail | undefined;
};

function TransactionHistoryCard({ transaction }: TransactionHistoryCardProps) {
  const formatHistoryStatus = (status: string): string => {
    switch (status) {
      case "Markedforreturn":
        return "Marked For Return";
      case "Indelivery":
        return "In Delivery";
      case "Awaitingimages":
      case "awaitingimages":
        return "Awaiting Images";
      case "Pendingreview":
      case "pendingreview":
        return "Pending Review";
      case "Stoppaymentrequested":
        return "Stop Payment Requested";
      case "Inproduction":
        return "In Production";
      default:
        return status;
    }
  };
  return (
    <>
      <div className="mr-auto mb-0 mt-4">
        <div className="hidden">{transaction?.id}</div>
        <div className="flex font-semibold text-base">
          Transaction Status History
        </div>
        <SnapTable>
          <table className="ui celled table">
            <thead>
              <tr>
                <th>Date</th>
                <th className="numeric-cell">Gross Amount</th>
                <th className="numeric-cell">Net Amount</th>
                <th className="numeric-cell">Status</th>
              </tr>
            </thead>
            <tbody>
              {transaction &&
                transaction.history?.map((history, idx: number) => {
                  return (
                    <tr key={idx}>
                      <td
                        data-label="Date"
                        className="flex justify-between md:table-cell h-20"
                      >
                        {FormatDate(
                          history?.date ?? "",
                          DateFormatSupported.Numbers
                        )}
                      </td>
                      {/* Doesnt exist  */}
                      <td className="numeric-cell">
                        {FormatMoney(transaction.amount ?? 0)}
                      </td>
                      <td className="numeric-cell">
                        {FormatMoney(
                          (transaction.amount ?? 0) -
                            (transaction?.snapAmount ?? 0)
                        )}
                      </td>
                      <td data-label="Status">
                        <SnapBadge
                          color={getBadgeColor(history?.status ?? "Unknown")}
                          className="float-right"
                        >
                          {formatHistoryStatus(history?.status ?? "Unknown")}
                        </SnapBadge>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </SnapTable>
      </div>
    </>
  );
}

export default TransactionHistoryCard;
