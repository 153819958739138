import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import SplitIOContext from "context/splitio-context";
import {
  DateRangeEnum,
  ExternalTransferDirectionEnum,
  SpendBankTransaction,
} from "graphql/generated";
import { useContextStrict } from "helpers/context-strict";
import { getBadgeColor } from "helpers/status-color";
import { getTransactionTitle } from "helpers/transaction";
import useModal from "hooks/use-modal";
import {
  transactionDateRangeFilters,
  transactionsFilters,
} from "pages/programs/banking/metadata/filter-dropdown-options/transaction-status";
import { transactionTypeFilters } from "pages/programs/banking/metadata/filter-dropdown-options/transaction-type";
import { useContext, useEffect, useState } from "react";
import DataCard from "shared-components/data-card";
import Divider from "shared-components/divider";
import Filter from "shared-components/filter";
import RequestCancel from "shared-components/fixed-modal/request-cancel";
import AddBudgetItem from "shared-components/modal/reconciliation/add-budget-item";
import ApplyToPlayerInvoice from "shared-components/modal/reconciliation/apply-to-player-invoice";
import ReconcileAs from "shared-components/modal/reconciliation/reconcile-as";
import UndoApplyToPlayerInvoice from "shared-components/modal/reconciliation/undo-apply-to-player-invoice";
import TransactionDetails from "shared-components/modal/transaction-details.tsx";
import { SearchInput } from "shared-components/search-input";
import ShowingResults from "shared-components/showing-results";
import { SpinnerContainer } from "shared-components/spinner";
import {
  SnapActionSheet,
  SnapBadge,
  SnapCheckboxButton,
  SnapDropDown,
  SnapIcon,
  SnapPagination,
} from "suit";
import { LabelValueObject } from "types/label-value-object";
import { ITEMS_PER_PAGE } from "../../../constants";
import DisputeTransaction from "./modals/dispute-transaction";
import GroupContext from "context/group-context";

type TransactionsProps = {
  canUpdateBudgetItem: boolean;
  pendingTransactions: SpendBankTransaction[];
  completedTransactions: SpendBankTransaction[];
  totalCompleted: number;
  completedPage: number;
  setCompletedPage: React.Dispatch<React.SetStateAction<number>>;
  whereFilters: {
    type: string[] | null;
    reconciled: boolean | null;
    hasAttachment: boolean | null;
    nameIncludes: string | null;
    status: string[];
    direction: ExternalTransferDirectionEnum | null;
  };
  setWhereFilters: React.Dispatch<
    React.SetStateAction<{
      type: string[] | null;
      reconciled: boolean | null;
      hasAttachment: boolean | null;
      nameIncludes: string | null;
      status: string[];
      direction: ExternalTransferDirectionEnum | null;
    }>
  >;
  setSelectedDateRange: React.Dispatch<React.SetStateAction<DateRangeEnum>>;
  loadingPending: boolean;
  loadingCompleted: boolean;
  prepLeftData: (transaction: SpendBankTransaction) => LabelValueObject[];
  prepRightData: (transaction: SpendBankTransaction) => LabelValueObject[];
  menuItemFilter: (transaction: SpendBankTransaction) => any[];
  groupIdOrgId: string;
  groupIsArchived: boolean;
  type: "Program" | "Group";
};

function Transactions({
  canUpdateBudgetItem,
  pendingTransactions,
  completedTransactions,
  totalCompleted,
  completedPage,
  setCompletedPage,
  whereFilters,
  setWhereFilters,
  setSelectedDateRange,
  loadingPending,
  loadingCompleted,
  prepLeftData,
  prepRightData,
  menuItemFilter,
  groupIdOrgId,
  groupIsArchived,
  type,
}: TransactionsProps) {
  const group = useContext(GroupContext);
  const splits = useContextStrict(SplitIOContext);
  const [
    spe1754FilterTransactionsDateRange,
    setSPE1754FilterTransactionsDateRange,
  ] = useState(false);
  useEffect(() => {
    setSPE1754FilterTransactionsDateRange(
      splits.isTreatmentOn(splits.act.spe1754)
    );
  }, [splits]);

  const [focusOnTransaction, setFocusOnTransaction] = useState<
    SpendBankTransaction | undefined
  >(undefined);
  const {
    isOpen: transactionDetailsOpen,
    toggle: transactionDetailsToggle,
    tabSelectedValue,
    setSelectedTab,
  } = useModal();
  const { isOpen: filterActionsOpen, toggle: filterActionsToggle } = useModal();
  const { isOpen: applyToPlayerOpen, toggle: applyToPlayerToggle } = useModal();
  const { isOpen: unApplyToPlayerOpen, toggle: unApplyToPlayerToggle } =
    useModal();
  const { isOpen: reconcileIncomeOpen, toggle: reconcileIncomeToggle } =
    useModal();
  const { isOpen: reconcileExpenseOpen, toggle: reconcileExpenseToggle } =
    useModal();
  const { isOpen: incomeBudgetOpen, toggle: incomeBudgetToggle } = useModal();
  const { isOpen: expenseBudgetOpen, toggle: expenseBudgetToggle } = useModal();
  const { isOpen: requestCancelOpen, toggle: requestCancelToggle } = useModal();
  const { isOpen: disputeTransactionOpen, toggle: disputeTransactionToggle } =
    useModal();
  const [selectedTran, setSelectedTran] = useState<
    SpendBankTransaction | any | undefined
  >(undefined);

  const [selectedModal, setSelectedModal] = useState(0);

  const totalTransactions = pendingTransactions.length + totalCompleted;
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] =
    useState<DropdownMenuItem[]>(transactionsFilters);
  const [dateRangeFilters] = useState<DropdownMenuItem[]>(
    transactionDateRangeFilters
  );
  const [transactionFilters, setTransactionFilters] = useState<
    DropdownMenuItem[]
  >(transactionTypeFilters);
  const [hidePending, setHidePending] = useState(false);

  const setQueryFilterType = (type: string, items: any[]) => {
    let filterType = whereFilters?.type;
    let reconciled = whereFilters?.reconciled;
    let hasAttachment = whereFilters?.hasAttachment;
    let nameIncludes = whereFilters?.nameIncludes;
    let status = whereFilters?.status;
    let direction = whereFilters?.direction;

    if (type === "Types") {
      let tempFilters = transactionFilters.map((filter) => {
        let item = { ...filter };
        if (item.name === items[0].name) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        return item;
      });
      setTransactionFilters(tempFilters);
      if (items[0].value === "") {
        filterType = null;
      } else {
        filterType = items[0].value;
      }
    } else if (type === "Filters") {
      if (items[0].selected) {
        reconciled = false;
      } else {
        reconciled = null;
      }

      if (items[1].selected) {
        direction = ExternalTransferDirectionEnum.Debit;
        hasAttachment = true;
      } else {
        direction = null;
        hasAttachment = null;
      }

      if (items[2].selected) {
        status = ["Settled"];
      } else {
        status = ["Settled", "Canceled", "Returned"];
      }

      setHidePending(items[3].selected);
    } else {
      if (items[0].value === "") {
        nameIncludes = null;
      } else {
        nameIncludes = items[0].value;
      }
    }

    setWhereFilters({
      type: filterType ?? null,
      reconciled: reconciled ?? null,
      hasAttachment: hasAttachment ?? null,
      nameIncludes: nameIncludes ?? null,
      status: status ?? [],
      direction: direction ?? null,
    });
  };

  useEffect(() => {
    setQueryFilterType("search", [{ name: searchText, value: searchText }]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const modalTogglerFromMenuTitle = (menuTitle: any): void => {
    switch (menuTitle) {
      case "Apply to Participant Invoice":
        applyToPlayerToggle();
        break;
      case "Undo Apply to Participant Invoice":
        unApplyToPlayerToggle();
        break;
      case "Reconcile as Income":
        reconcileIncomeToggle();
        break;
      case "Reconcile as Expense":
        reconcileExpenseToggle();
        break;
      case "Add Income Budget Item":
        incomeBudgetToggle();
        break;
      case "Add Expense Budget Item":
        expenseBudgetToggle();
        break;
      case "View/Edit Reconciled Income":
        reconcileIncomeToggle();
        break;
      case "View/Edit Reconciled Expense":
        reconcileExpenseToggle();
        break;
      case "Request Cancel":
        requestCancelToggle();
        break;
      case "Dispute Transaction":
        disputeTransactionToggle();
        break;
    }
  };
  const prepTitleExtra = (transaction: SpendBankTransaction) => {
    const reconciledAmount = Math.abs(transaction.totalReconciled ?? 0);
    const amount = (transaction.amount ?? 0) - (transaction.snapAmount ?? 0);
    const partiallyReconciled = amount > reconciledAmount;
    const isReturned = transaction.transactionStatus === "Returned";
    return (
      <div className="flex">
        {isReturned && (
          <SnapBadge color={getBadgeColor("Returned")} class="ml-5">
            Returned
          </SnapBadge>
        )}
        {!transaction.isReconciled && !isReturned && (
          <SnapBadge color={getBadgeColor("Unreconciled")} class="ml-5">
            Unreconciled
          </SnapBadge>
        )}
        {transaction.isReconciled && partiallyReconciled && (
          <SnapBadge color={getBadgeColor("partially_reconciled")} class="ml-5">
            Partially Reconciled
          </SnapBadge>
        )}
        {transaction.attachments && transaction.attachments.length > 0 && (
          <SnapIcon icon="paper-clip-solid" color="#2563EB" />
        )}
      </div>
    );
  };

  return (
    <div className="mt-4">
      <div className="mb-4">
        <SearchInput setSearchValue={setSearchText} />
      </div>
      <div className="lg:flex ml-2 lg:ml-0">
        <Filter
          dropdownOptions={transactionFilters}
          handleSelect={(e) => setQueryFilterType("Types", [e.detail])}
        />
        <div className="hidden lg:flex lg:ml-6">
          <p className="text-xs font-medium self-center text-gray-500 mr-2">
            Filter:
          </p>
          <SnapDropDown
            buttonText="Select Filters"
            options={filters}
            multi
            leftHang
            onSnap-dropdown-updated={(e) => {
              setQueryFilterType("Filters", e.detail as any[]);
              setFilters(e.detail as DropdownMenuItem[]);
            }}
          />
        </div>
        {spe1754FilterTransactionsDateRange && (
          <div className="flex lg:ml-6 lg:mt-0 mt-4">
            <p className="text-xs font-medium self-center text-gray-500 mr-2">
              Date Range:
            </p>
            <SnapDropDown
              buttonText="Select Filters"
              options={dateRangeFilters}
              leftHang
              onSnap-dropdown-item-selected={(e) => {
                setSelectedDateRange(e.detail.value as DateRangeEnum);
              }}
            />
          </div>
        )}
        <div className="lg:hidden flex mt-10" onClick={filterActionsToggle}>
          <SnapIcon icon="filter-solid" size="sm" color="#3B82F6" />
          <p className="text-base font-bold text-blue-600">Filters</p>
        </div>
        {filterActionsOpen && (
          <SnapActionSheet header="Filter" onClick={filterActionsToggle}>
            <Divider isVisibleOnMobile className="mt-0" />
            {filters.map((filter, idx) => {
              return (
                <div key={filter.name + idx} className="mx-4 mt-6">
                  <SnapCheckboxButton
                    checked={filter.selected}
                    label={filter.name}
                    onClick={() => {
                      let selectedFilter = {
                        ...filter,
                        selected: !filter.selected,
                      };
                      let tempFilters = [...filters];
                      tempFilters.splice(idx, 1, selectedFilter);
                      setQueryFilterType("Filters", tempFilters);
                      setFilters(tempFilters);
                    }}
                  />
                </div>
              );
            })}
          </SnapActionSheet>
        )}
      </div>
      <Divider isVisibleOnMobile />
      <ShowingResults
        totalNumOfResults={totalTransactions}
        numOfResultsBeingDisplayed={
          totalTransactions <= 10
            ? (completedTransactions.length ?? 0) + pendingTransactions.length
            : ITEMS_PER_PAGE * completedPage +
                10 +
                pendingTransactions.length >=
              totalTransactions
            ? totalTransactions
            : ITEMS_PER_PAGE * completedPage + 10 + pendingTransactions.length
        }
        startingNumOfResults={
          totalTransactions === 0 ? 0 : ITEMS_PER_PAGE * completedPage + 1
        }
      />
      {!hidePending && pendingTransactions.length > 0 && (
        <>
          <p className="text-sm font-medium text-gray-500 mb-4">
            Pending
            <span className="ml-2">{pendingTransactions.length}</span>
          </p>
          <Divider className="mb-4" />
          <div className={"relative"}>
            {pendingTransactions.map((transaction, idx) => {
              return (
                <DataCard
                  title={getTransactionTitle(transaction)}
                  key={transaction.id || idx}
                  titleExtra={prepTitleExtra(transaction)}
                  titleAction={() => {
                    setSelectedTran(transaction);
                    transactionDetailsToggle();
                  }}
                  kvLeft={prepLeftData(transaction)}
                  kvRight={prepRightData(transaction)}
                  action={canUpdateBudgetItem && !groupIsArchived ? 1 : 0}
                  menuItems={menuItemFilter(transaction)}
                  menuClickListener={(title) => {
                    setFocusOnTransaction(transaction);
                    modalTogglerFromMenuTitle(title);
                  }}
                />
              );
            })}
            <SpinnerContainer loading={loadingPending} />
          </div>
        </>
      )}

      <br className={"mt-4 block"} />
      <p className="text-sm font-medium text-gray-500 mb-4">
        Complete
        <span className="ml-2 inline-block">{totalCompleted}</span>
      </p>
      <Divider className="mb-4" />
      <div className={"relative"}>
        {completedTransactions.map((transaction, idx) => {
          return (
            <DataCard
              title={getTransactionTitle(transaction)}
              key={transaction.id || idx}
              titleExtra={prepTitleExtra(transaction)}
              titleAction={() => {
                setSelectedTran(transaction);
                transactionDetailsToggle();
              }}
              kvLeft={prepLeftData(transaction)}
              kvRight={prepRightData(transaction)}
              action={canUpdateBudgetItem && !groupIsArchived ? 1 : 0}
              menuItems={menuItemFilter(transaction)}
              menuClickListener={(title) => {
                setFocusOnTransaction(transaction);
                modalTogglerFromMenuTitle(title);
              }}
            />
          );
        })}
        <SpinnerContainer loading={loadingCompleted} />
      </div>
      <div className="pt-5">
        <SnapPagination
          itemCount={totalCompleted}
          currentPage={completedPage}
          pageSize={ITEMS_PER_PAGE}
          onSnap-pagination-page-changed={(e) => {
            setCompletedPage(e.detail);
          }}
        />
      </div>
      {focusOnTransaction && applyToPlayerOpen && (
        <ApplyToPlayerInvoice
          applyToPlayerOpen={applyToPlayerOpen}
          applyToPlayerToggle={applyToPlayerToggle}
          transaction={focusOnTransaction}
          startAt={type === "Group" ? 1 : 0}
          team={type === "Group" ? group?.activeGroup : undefined}
        />
      )}
      {focusOnTransaction && unApplyToPlayerOpen && (
        <UndoApplyToPlayerInvoice
          undoApplyToPlayerOpen={unApplyToPlayerOpen}
          undoApplyToPlayerToggle={unApplyToPlayerToggle}
          transaction={focusOnTransaction}
        />
      )}
      {focusOnTransaction && (reconcileIncomeOpen || reconcileExpenseOpen) && (
        <ReconcileAs
          type={type}
          transaction={focusOnTransaction}
          directionType={reconcileIncomeOpen ? "Income" : "Expense"}
          reconcileOpen={reconcileIncomeOpen || reconcileExpenseOpen}
          reconcileToggle={() => {
            if (reconcileIncomeOpen) reconcileIncomeToggle();
            else reconcileExpenseToggle();
          }}
        />
      )}
      {focusOnTransaction && (incomeBudgetOpen || expenseBudgetOpen) && (
        <AddBudgetItem
          type={type}
          directionType={incomeBudgetOpen ? "Income" : "Expense"}
          budgetItemOpen={incomeBudgetOpen || expenseBudgetOpen}
          budgetItemToggle={() => {
            if (incomeBudgetOpen) incomeBudgetToggle();
            else expenseBudgetToggle();
          }}
          transaction={focusOnTransaction}
        />
      )}
      {focusOnTransaction && requestCancelOpen && (
        <RequestCancel
          requestCancelOpen={requestCancelOpen}
          requestCancelToggle={requestCancelToggle}
          focusOnTransaction={focusOnTransaction}
          type="Program"
        />
      )}

      {focusOnTransaction && disputeTransactionOpen && (
        <DisputeTransaction
          disputeTransactionOpen={disputeTransactionOpen}
          disputeTransactionToggle={disputeTransactionToggle}
        />
      )}

      {selectedTran && transactionDetailsOpen && (
        <TransactionDetails
          isOpen={transactionDetailsOpen}
          toggle={transactionDetailsToggle}
          tabSelectedValue={tabSelectedValue}
          selectedTran={selectedTran}
          selectedModal={selectedModal}
          setSelectedModal={setSelectedModal}
          setSelectedTab={setSelectedTab}
          canEditNotesAndAttachments={canUpdateBudgetItem}
          perspectiveId={groupIdOrgId ?? undefined}
        />
      )}
    </div>
  );
}

export default Transactions;
