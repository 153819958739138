import {
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroup,
  SpendInvoice,
  SpendSettings,
} from "graphql/generated";
import Divider from "shared-components/divider";
import DotProgress from "shared-components/dot-progress";
import { SnapCheckboxButton, SnapIcon } from "suit";
import { PaymentTimingValue } from "../../../types/invoice";
import DefaultPaymentMethod from "./default-payment-method";
import ParentInviteInfo from "../parent-invite-info";

type ConfirmManuallyPaymentsProps = {
  settings: SpendSettings;
  group: SpendGroup;
  invoices: SpendInvoice[];
  playerName: string;
  paymentTiming: PaymentTimingValue | undefined;
  selectedPaymentTypeOption: "Pay by: Card" | "Pay by: Bank" | undefined;
  total: number;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
  agreed: boolean;
  setAgreed: React.Dispatch<React.SetStateAction<boolean>>;
  invoicesToPay: SpendInvoice[];
  bankAccount: SpendBankAccount | undefined;
  paymentMethods: PaymentsApiCustomerPaymentMethod | undefined;
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  dotState: number;
  dotMarker: number;
};

function ConfirmManuallyPayments({
  settings,
  group,

  playerName,
  selectedPaymentTypeOption,
  bankAccount,
  agreed,
  setAgreed,
  paymentMethods,
  isAuthorized,
  setIsAuthorized,
  dotState,
  dotMarker,
}: ConfirmManuallyPaymentsProps) {
  return (
    <div>
      <DotProgress state={dotState} markerCount={dotMarker} />
      <h2 className="lg:text-2xl font-semibold">Confirmation</h2>
      <ParentInviteInfo group={group} playerName={playerName} />
      <Divider isVisibleOnMobile className={"mt-6"} />

      <DefaultPaymentMethod
        paymentMethods={paymentMethods}
        bankAccount={
          selectedPaymentTypeOption === "Pay by: Bank" ? bankAccount : undefined
        }
      />

      <SnapCheckboxButton
        label="I authorize Snap! Spend to use my default payment method for future payments."
        className="mt-4 md:mt-6"
        checked={isAuthorized}
        onClick={() => setIsAuthorized(!isAuthorized)}
      />
      <Divider isVisibleOnMobile />
      {group.isRequireAgreement && (
        <>
          <p className="mt-4">Agreement with {group.name}</p>
          <div className="flex justify-between border border-gray-200 rounded-lg pt-2 pb-10 px-3 bg-gray-100 mt-1">
            <p>{settings.signUpAgreement?.content}</p>
            <SnapIcon
              icon="lock-closed-solid"
              size="sm"
              color="#94A3B8"
              className="items-start"
            />
          </div>
          <SnapCheckboxButton
            label={`I agree to the ${settings.signUpAgreement?.name} above.`}
            className="mt-4"
            checked={agreed}
            onClick={() => setAgreed(!agreed)}
          />
          <Divider isVisibleOnMobile />
        </>
      )}
      <h3 className="font-medium mt-4 md:mt-6">Summary</h3>
      <ul className="list-disc ml-6 mt-4 text-gray-500">
        <>
          <li>
            Future invoice payments will default to you selected method and can
            be changed at any time.
          </li>
          <li>A payment confirmations will be sent to your email.</li>
        </>
      </ul>
    </div>
  );
}

export default ConfirmManuallyPayments;
