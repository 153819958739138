import { gql } from "@apollo/client";

export const CREATE_USER_PLAID_LINK = gql`
  query SpendUserBankLinkTokenCreate($id: String) {
    spendUserBankLinkTokenCreate(id: $id) {
      expiration
      requestId
      linkToken
    }
  }
`;

export const CREATE_GROUP_PLAID_LINK = gql`
  query SpendGroupBankLinkTokenCreate(
    $spendGroupBankLinkTokenCreateId: String
  ) {
    spendGroupBankLinkTokenCreate(id: $spendGroupBankLinkTokenCreateId) {
      expiration
      linkToken
      requestId
    }
  }
`;

//TODO - whats the difference this call and create_user_plaid_link?
export const CREATE_ORGANIZATION_PLAID_LINK = gql`
  query SpendOrganizationBankLinkTokenCreate {
    spendOrganizationBankLinkTokenCreate {
      linkToken
      expiration
      requestId
    }
  }
`;
