import { gql } from "@apollo/client";
export const DASHBOARD_GROUP_OVERVIEW = gql`
  query SpendGroupsOverviewDashboard($pagination: SpendPaginationInput) {
    spendGroupsOverviewDashboard(pagination: $pagination) {
      overview {
        groupId
        name
        seasonName
        seasonStartAt
        seasonEndAt
        balance
        collected
        pastDue
        sharedAccount
        cards {
          expiration
          id
          lastFour
          status
        }
      }
      count
    }
  }
`;

export const ORGANIZATION = gql`
  query SpendOrganization {
    spendOrganization {
      email
      id
      isVerified
      legalName
      nickname
      logo
      orgId
      phone
      userId
      website
      hasLinkedAccount
      externalTransferOutEnabled
      owner {
        id
      }
      debitCards {
        activated
        assigned
        shipped
        total
      }
      city
      state
      street
      street2
      zip
      cardBaseFee
      cardPercent
      spendBaseFee
      spendPercent
      achBaseFee
      achPercent
      legacyAccountId
      groupBanksEnabled
      budgets {
        category {
          id
          type
          createdAt
          isDefault
          isHidden
          name
          organizationId
        }
        createdAt
        description
        id
        isDefault
        targetAmount
        targetDateAt
        updatedAt
      }
      groups {
        name
        id
        isArchived
        hasAccount
        sharedAccount
      }
      checkImageAccess {
        id
        checkType
        checkId
      }
    }
  }
`;

export const GET_ORGANIZATION_DEBIT_CARDS = gql`
  query SpendOrganizationDebitCards {
    spendOrganizationDebitCards {
      cards {
        expiration
        id
        lastFour
        status
        userId
      }
      count
    }
  }
`;

export const GET_ORGANIZATION_ACCOUNTS = gql`
  query SpendOrganizationAccounts {
    spendOrganizationAccounts {
      accounts {
        available
        balance
        id
        name
        status
        type
        groupStatus
        groupId
      }
      count
      groupsBalance {
        active
        archived
      }
      totalBalance
    }
  }
`;

export const GET_ORG_BANK_ACCOUNTS = gql`
  query SpendOrganizationBankAccounts {
    spendOrganizationBankAccounts {
      externalAccounts {
        accountId
        availableBalance
        currentBalance
        mask
        name
        officialName
        subtype
        type
        institution {
          id
          name
        }
      }
      status
    }
  }
`;
export const GET_ORGANIZATION_SETTINGS = gql`
  query SpendOrgSettings {
    spendSettings {
      enableGroupBanks
      enableProgramAgreements
      notifyBankActivityAdmins
      notifyDueFrequencies
      notifyFailedAchAdmins
      notifyPastDueNonUsers
      notifyUpcomingNonUsers
      pastDueFrequency
      signUpAgreement {
        content
        name
        createdAt
      }
      signUpLink
      internalTransferApproval
      internalTransferLimit
      externalTransferApproval
      externalTransferLimit
      sendCheckApproval
      sendCheckLimit
      debitCardApproval
      fileUploadEnabled
    }
  }
`;

export const EXPORT_ACCOUNT_REPORT = gql`
  query SpendAccountExport($status: String!) {
    spendAccountExport(status: $status) {
      content
      fileName
    }
  }
`;

export const GET_ORG_PAYEES = gql`
  query SpendOrganizationPayees($orgId: String!) {
    spendOrganizationPayees(orgId: $orgId) {
      payees {
        id
        name
        address1
        address2
        city
        state
        zipCode
      }
    }
  }
`;

export const GET_ORG_ACCOUNT_LIMITS = gql`
  query SpendOrganizationAccountLimits($groupId: String) {
    spendOrganizationAccountLimits(groupId: $groupId) {
      ach {
        limits {
          dailyDebit
          dailyCredit
          monthlyDebit
          monthlyCredit
        }
      }
      bookTransfer {
        limits {
          dailyDebit
          dailyCredit
          monthlyDebit
          monthlyCredit
        }
      }
      card {
        limits {
          dailyWithdrawal
          dailyDeposit
          dailyPurchase
          dailyCardTransaction
        }
      }
      checkDeposit {
        limits {
          daily
          monthly
        }
      }
      wire {
        limits {
          dailyTransfer
          monthlyTransfer
          dailyTransferSoft
          monthlyTransferSoft
        }
      }
      checkPayment {
        limits {
          dailySent
          monthlySent
        }
      }
    }
  }
`;

export const GET_ORG_COUNTERYPARTIES = gql`
  query SpendGetOrganizationCounterparties($groupOrOrgId: String!) {
    spendGetOrganizationCounterparties(groupOrOrgId: $groupOrOrgId) {
      counterparties {
        id
        name
        bankName
        accountLastFour
        routingNumber
      }
    }
  }
`;

export const GET_PROGRAM_PIE_CHART = gql`
  query SpendOrganizationPieChart {
    spendOrganizationPieChart {
      paid
      processing
      upcoming
      pastDue
      credited
    }
  }
`;
export const GET_CHECK_VIEW = gql`
  query SpendOrganizationCheckImage($input: SpendCheckImageRequest!) {
    spendOrganizationCheckImage(input: $input) {
      imageContent
      imageName
      imageFound
      imageId
    }
  }
`;
