import { useMutation } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import { SpendRole } from "graphql/generated";
import { SET_DEFAULT_ROLE } from "graphql/mutations/role";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnapSelectMenu } from "suit";
import CustomModal from "..";
import { removeDupsById } from "helpers/remove-dups-by-id";
import UserContext from "context/user-context";
import SeasonContext from "context/season-context";

type SwitchRoleProps = {
  isOpen: boolean;
  toggle: () => void;
  currentRole: SpendRole | undefined | null;
  roles: SpendRole[] | null | undefined;
};

function SwitchRole({ isOpen, toggle, currentRole, roles }: SwitchRoleProps) {
  const user = useContext(UserContext);
  const group = useContext(GroupContext);
  const season = useContext(SeasonContext);
  const navigate = useNavigate();

  const [changeRole, { loading, data }] = useMutation(SET_DEFAULT_ROLE);
  const [orgOptions, setOrgOptions] = useState<SnapSelectMenuOption[]>([]);
  const [groupOptions, setGroupOptions] = useState<SnapSelectMenuOption[]>([]);
  const [roleOptions, setRoleOptions] = useState<SnapSelectMenuOption[]>([]);

  useEffect(() => {
    if (!loading && data && data.spendUserRoleSet) {
      group?.setAndStoreActiveGroup(undefined);
      season?.setAndStoreActiveSeason(undefined);
      navigate("/dashboard");
      window.location.reload();
    }
    // eslint-disable-next-line
  }, [loading, data]);

  useEffect(() => {
    let orgs: SnapSelectMenuOption[] = [];

    roles?.forEach((role) => {
      let option: SnapSelectMenuOption = {
        name: role.organizationName ?? "",
        value: role.organizationId ?? "",
        selected: role.organizationId === currentRole?.organizationId,
      };
      orgs.push(option);
    });
    orgs = orgs.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.value === value.value)
    );
    setOrgOptions(orgs);
    // eslint-disable-next-line
  }, [roles]);

  useEffect(() => {
    handleChangeGroupOptions(
      orgOptions.find((org) => org.selected)?.value ?? ""
    );
    // eslint-disable-next-line
  }, [orgOptions]);

  const handleChangeRole = () => {
    const selectedRole = roleOptions.find((r) => r.selected);
    const selectedOrg = orgOptions.find((o) => o.selected);
    if (selectedOrg?.value !== user?.getOrgId()) {
      localStorage.removeItem("Authorization-Token-Data");
    }
    changeRole({
      variables: {
        roleId: selectedRole?.value ?? "",
      },
    });
  };

  const formatRoleName = (name: string) => {
    return name
      .split("_")
      .map((n) => `${n[0]?.toUpperCase()}${n.substring(1)}`)
      .join(" ");
  };

  const generateDistinctRoles = (roles: SpendRole[], orgId: string) => {
    const distLength = -4;
    let groupOptions: (SnapSelectMenuOption & { appendName?: string })[] = [];
    for (const r of roles || []) {
      if (r.groupId && r.groupName && r.organizationId === orgId) {
        const idx = groupOptions.findIndex(
          (go) => go.name.toLowerCase() === r.groupName?.toLowerCase()
        );
        let matchedAppendValue: string | undefined;
        let matchedId = false;
        if (idx >= 0) {
          const prevAddedGroupId = groupOptions[idx].value;
          if (prevAddedGroupId !== r.groupId) {
            const prevSliceId =
              prevAddedGroupId?.slice(distLength) ?? undefined;
            const matchedGroupId = r.groupId.slice(distLength);
            const prevAppendName = ` (${prevSliceId})`;
            groupOptions[idx].appendName = prevAppendName;
            matchedAppendValue = ` (${matchedGroupId})`;
          } else {
            matchedId = true;
          }
        }
        if (!matchedId) {
          groupOptions.push({
            name: r.groupName,
            value: r.groupId,
            selected: r.id === currentRole?.id,
            appendName: matchedAppendValue ?? "",
          });
        }
      }
    }
    return groupOptions;
  };

  const handleChangeGroupOptions = (orgId: string) => {
    let groupOptions = generateDistinctRoles(roles || [], orgId);
    groupOptions = removeDupsById(
      groupOptions.map((options) => {
        return {
          ...options,
          id: options.value,
        };
      })
    );
    let selectedItem: SnapSelectMenuOption | undefined;
    if (groupOptions.length === 1) {
      groupOptions[0].selected = true;
      selectedItem = groupOptions[0];
    } else {
      selectedItem = groupOptions.find((g) => g.selected);
    }

    setGroupOptions(
      groupOptions
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        .map((o) => ({
          ...o,
          name: `${o.name}${o.appendName}`,
        }))
    );
    handleChangeRoleOptions(selectedItem?.value ?? "");
  };
  const handleChangeRoleOptions = (groupId: string) => {
    let selectedOrg = orgOptions.find((org) => org.selected);
    let newRoleOptions =
      roles
        ?.filter(
          (role) =>
            role.organizationId === selectedOrg?.value &&
            (role.groupId === groupId || role.name.includes("program"))
        )
        .map((role) => {
          return {
            name: formatRoleName(role.name),
            value: role.id,
            selected: role.id === currentRole?.id,
          };
        }) ?? [];
    newRoleOptions = newRoleOptions.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.value === value.value)
    );
    if (newRoleOptions.length === 1) {
      newRoleOptions[0].selected = true;
    }
    setRoleOptions(newRoleOptions);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Switch Role"}
      btn1={{
        text: "Submit",
        btnStyle: "primary",
        onClick: handleChangeRole,
      }}
      btn2={{
        text: "Cancel",
        onClick: toggle,
      }}
      customStyle="lg:h-[50%]"
    >
      <div className="modal-card">
        <div className="flex justify-between">
          <p>Current Role</p>
          <p>{formatRoleName(currentRole?.name ?? "")}</p>
        </div>
        <SnapSelectMenu
          label="Organization"
          className="mt-4"
          selectMenuOptions={orgOptions}
          onSnap-select-menu-updated={(e) => {
            setOrgOptions(e.detail);
            let selectedItem = e.detail.find((o) => o.selected);
            handleChangeGroupOptions(selectedItem?.value ?? "");
          }}
        />
        <SnapSelectMenu
          label="Group"
          className="mt-4"
          selectMenuOptions={groupOptions}
          onSnap-select-menu-updated={(e) => {
            setGroupOptions(e.detail);
            let selectedItem = e.detail.find((o) => o.selected);
            handleChangeRoleOptions(selectedItem?.value ?? "");
          }}
        />
        <SnapSelectMenu
          label="Role"
          className="mt-4"
          selectMenuOptions={roleOptions}
          onSnap-select-menu-updated={(e) => {
            setRoleOptions(e.detail);
          }}
        />
      </div>
    </CustomModal>
  );
}

export default SwitchRole;
