import { useMutation, useQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import SeasonContext from "context/season-context";
import {
  Maybe,
  SpendBudget,
  SpendCategory,
  SpendInvoiceCreateMutationVariables,
  SpendPaymentSchedule,
} from "graphql/generated";
import { GET_BUDGETS_SIMPLE } from "graphql/queries/budgets";
import { PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON } from "graphql/queries/payment-schedule";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";
import { emptyStringCheck } from "helpers/empty-string-check";
import { ParseMoney } from "helpers/format-money";
import { isNullOrEmpty } from "helpers/null-or-empty";
import { useContext, useEffect, useState } from "react";
import DatePicker from "shared-components/date-picker";
import InputMask from "shared-components/input-mask";
import CustomModal, { BtnState } from "shared-components/modal";
import { SnapCheckboxButton, SnapInput, SnapSelectMenu } from "suit";
import { addInvoiceError } from "types/errors";
import { collectionData } from "types/programs";
import { SpendPaymentScheduleWithFees } from "../participants-roster";
import ToastContext from "context/toast-context";
import { CREATE_INVOICE } from "graphql/mutations/invoice";
import { calcTotalPlusFee } from "shared-components/modal/make-payment/make-payment-helper";

type AddInvoiceProps = {
  isAddPlayerInvoiceOpen: boolean;
  addPlayerInvoiceToggle: () => void;
  selectedItems: collectionData[];
  deselectAllAction: () => void;
};

function AddInvoice({
  isAddPlayerInvoiceOpen,
  addPlayerInvoiceToggle,
  selectedItems,
  deselectAllAction,
}: AddInvoiceProps) {
  const program = useContext(ProgramContext);
  const group = useContext(GroupContext)?.activeGroup;
  const season = useContext(SeasonContext);
  const toast = useContext(ToastContext);

  const spendPercent = program?.getSpendPercent() || 0;
  const spendBaseFee = program?.getSpendBaseFee() || 0;

  const [btnState, setBtnState] = useState<BtnState>(BtnState.BASE);
  const [currentInvoices, setCurrentInvoices] = useState<
    SpendPaymentScheduleWithFees[]
  >([]);
  const [dropdownCat, setDropdownCat] = useState<SnapSelectMenuOption[]>([]);
  const [amount, setAmount] = useState("");
  const [newInvoice, setNewInvoice] = useState<SpendPaymentSchedule>({
    groupId: group?.id,
    seasonId: season?.selectedSeason?.id,
    isOptional: false,
  });
  const [hasErrorObj, setHasErrorObj] = useState<addInvoiceError>({
    descriptionError: false,
    dateError: false,
    amountError: false,
    budgetError: false,
    duplicateNameError: false,
  });

  const { loading: loadingBudgets, data: dataBudgets } = useQuery(
    GET_BUDGETS_SIMPLE,
    {
      variables: {
        groupId: group?.id ?? "",
        seasonId: season?.selectedSeason?.id,
      },
    }
  );
  const { loading: loadingPaymentSchedule, data: paymentScheduleData } =
    useQuery(PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON, {
      variables: {
        seasonId: season?.selectedSeason?.id,
        groupIdOrSeasonId: season?.selectedSeason?.id,
      },
    });
  const [addInvoice] = useMutation(CREATE_INVOICE);

  useEffect(() => {
    if (dataBudgets && dataBudgets.spendBudgetsSummary) {
      let selectOptions: SnapSelectMenuOption[] = [];
      dataBudgets.spendBudgetsSummary.summaryByCategory.forEach(
        (cat: SpendCategory) => {
          cat?.budgets?.forEach((budget: Maybe<SpendBudget>) => {
            if (cat.type === "income") {
              selectOptions.push({
                name: `${cat?.name} | ${budget?.description}`,
                value: `${budget?.id}`,
                selected: false,
              });
            }
          });
        }
      );
      setDropdownCat(selectOptions);
    }
    // eslint-disable-next-line
  }, [loadingBudgets, dataBudgets]);

  useEffect(() => {
    const CalculateFee = (amountDue: number): number => {
      return amountDue * spendPercent + spendBaseFee;
    };

    if (!loadingPaymentSchedule && paymentScheduleData?.spendPaymentSchedules) {
      const invoicesUnordered = paymentScheduleData.spendPaymentSchedules.map(
        (schedule: SpendPaymentSchedule) => {
          return {
            ...schedule,
            received:
              (schedule.amountDue ?? 0) - CalculateFee(schedule.amountDue ?? 0),
            feeAmount: CalculateFee(schedule?.amountDue ?? 0),
            hasError: false,
          };
        }
      );
      invoicesUnordered.sort((a: any, b: any) =>
        a.description > b.description ? 1 : -1
      );
      setCurrentInvoices(invoicesUnordered);
    }
    // eslint-disable-next-line
  }, [loadingPaymentSchedule, paymentScheduleData]);

  const handleAddInvoices = () => {
    const errorState = { ...hasErrorObj };
    const parsedAmount = ParseMoney(amount);
    if (emptyStringCheck(newInvoice.description)) {
      errorState.descriptionError = true;
    }
    if (isNullOrEmpty(newInvoice.dueDate)) {
      errorState.dateError = true;
    }
    if (parsedAmount <= 0.5) {
      errorState.amountError = true;
    }
    if (newInvoice.budgetItemId == null) {
      errorState.budgetError = true;
    }
    if (
      currentInvoices.some(
        (inv) =>
          inv.description?.trim().toLocaleLowerCase() ===
          newInvoice.description?.trim().toLocaleLowerCase()
      )
    ) {
      errorState.duplicateNameError = true;
    }
    setHasErrorObj({ ...errorState });
    let {
      descriptionError,
      dateError,
      amountError,
      budgetError,
      duplicateNameError,
    } = errorState;
    if (
      descriptionError ||
      dateError ||
      amountError ||
      budgetError ||
      duplicateNameError
    ) {
      let message = "Please fill in all the input fields";
      if (amountError) {
        message = "Invoice amount must be greater than $0.50";
      } else if (duplicateNameError) {
        message =
          "The description provided is not unique among this season's invoices. Please use a different description.";
      }
      toast?.setToastProps({
        message,
        type: "danger",
      });
      toast?.toggleToast();
    } else {
      setBtnState(BtnState.DISABLED);
      selectedItems.forEach((item) => {
        let data: SpendInvoiceCreateMutationVariables = {
          input: {
            amount: parsedAmount,
            paid: false,
            dueDate: newInvoice.dueDate,
            optedIn: newInvoice.isOptional === false,
            balanceDue: calcTotalPlusFee(
              parsedAmount,
              program?.organization?.achPercent ?? 0,
              program?.organization?.achBaseFee ?? 0
            ),
            description: newInvoice.description,
            isOptional: newInvoice.isOptional ?? false,
            budgetItemId: newInvoice.budgetItemId,
            groupRosterId: item.groupRosterId,
            note: newInvoice.note,
          },
        };
        addInvoice({
          variables: {
            input: data.input,
          },
        });
      });
      toast?.setToast({
        message: "Invoice is being added",
        type: "success",
      });
      addPlayerInvoiceToggle();
      deselectAllAction();
      setBtnState(BtnState.BASE);
    }
  };

  return (
    <CustomModal
      isOpen={isAddPlayerInvoiceOpen}
      toggle={addPlayerInvoiceToggle}
      title={"Add Invoice"}
      btn1={{
        text: "Submit",
        onClick: handleAddInvoices,
        btnState: btnState,
      }}
      btn2={{
        text: "Cancel",
        onClick: addPlayerInvoiceToggle,
      }}
    >
      <div className="modal-card">
        <SnapInput
          _id={""}
          label="Invoice Description"
          maxlength="250"
          displayCharCount
          placeholder="Enter a short description"
          helpText=" "
          value={newInvoice.description || ""}
          onSnap-input-change={(e) => {
            setNewInvoice({
              ...newInvoice,
              description: e.detail.target.value,
            });
            setHasErrorObj({ ...hasErrorObj, descriptionError: false });
          }}
          error={hasErrorObj.descriptionError}
        />
        <div className="lg:grid grid-cols-2 mt-4">
          <DatePicker
            label={"Date"}
            className={"lg:mr-4"}
            date={getDatePickerValue(newInvoice.dueDate ?? "")}
            setDate={(e) => {
              const dateValue = setDatePickerValue(e);
              setNewInvoice({
                ...newInvoice,
                dueDate: dateValue,
              });
              setHasErrorObj({ ...hasErrorObj, dateError: false });
            }}
            hasError={hasErrorObj.dateError}
          />
          <div className="lg:mt-0 mt-4">
            <InputMask
              amount={amount}
              setAmount={setAmount}
              hasError={false}
              onChange={() =>
                setHasErrorObj({ ...hasErrorObj, amountError: false })
              }
            />
          </div>
        </div>
        <SnapCheckboxButton
          label="Allow participants to opt out of this payment for this invoice"
          className="mt-4"
          onSnap-checkbox-updated={(e) => {
            setNewInvoice({
              ...newInvoice,
              isOptional: e.detail.checked,
            });
          }}
        />
        <div className="mt-4">
          <SnapInput
            _id={""}
            textarea
            label="Note to Parents"
            onSnap-input-change={(e) => {
              setNewInvoice({
                ...newInvoice,
                note: e.detail.target.value,
              });
            }}
            value={newInvoice.note ?? ""}
          />
        </div>
        <div className="mt-4 lg:flex">
          <SnapSelectMenu
            modalTitle="Income Budget Items"
            className="lg:w-[100%]"
            label="Income Budget Item"
            placeholder="Select a Budget Item"
            selectMenuOptions={dropdownCat}
            onSnap-select-menu-updated={({ detail }) => {
              setDropdownCat(detail);
              setNewInvoice({
                ...newInvoice,
                budgetItemId: detail.find(
                  (det: { selected: boolean }) => det.selected
                )?.value,
              });
              setHasErrorObj({ ...hasErrorObj, budgetError: false });
            }}
            error={hasErrorObj.budgetError}
          />
        </div>
      </div>
    </CustomModal>
  );
}

export default AddInvoice;
