import { useLazyQuery, useMutation } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import {
  InvoiceFilterEnum,
  Maybe,
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroup,
  SpendInvoice,
  SpendPendingInvite,
  SpendSeason,
  SpendSettings,
  usePaymentsApiCustomerMutation,
  useSpendPaymentMethodCreateMutation,
  useSpendSignupGroupIdLazyQuery,
  useSpendUserRoleSetMutation,
  useSpendUserSignupUpdateMutation,
} from "graphql/generated";
import { CREATE_SESSION, DELETE_SESSION } from "graphql/mutations/session";
import { USER_SIGN_UP } from "graphql/queries/group";
import { PARENT_SIGNUP_INVOICES } from "graphql/queries/invoices";
import { GET_SETTINGS } from "graphql/queries/settings";
import { FormatMoney } from "helpers/format-money";
import { calcDiscount } from "helpers/invoices";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import useModal from "hooks/use-modal";
import useToast from "hooks/use-toast";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  calcTotalPlusFee,
  defaultSelectedPaymentOption,
} from "shared-components/modal/make-payment/make-payment-helper";
import SessionTimeout from "shared-components/modal/session-timeout";
import ToastMessage from "shared-components/toast-message";
import { SnapButton, SnapLink } from "suit";
import { signUpErrors } from "types/errors";
import ReviewInvoices from "./review-invoices";
import SignUp from "./sign-up";
import { useContextStrict } from "helpers/context-strict";
import Spinner, { SpinnerContainer } from "shared-components/spinner";
import { PaymentTimingValue } from "../../types/invoice";
import UserContext from "context/user-context";
import NewAuthorizePayments from "./new-authorize-payments";
import { UPDATE_USER_INVITE_STATUS } from "graphql/mutations/invite";

type DisplaySignUpFlowProps = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

function DisplaySignupFlow({ setIsLoading }: DisplaySignUpFlowProps) {
  const navigate = useNavigate();
  const userContext = useContextStrict(UserContext);

  const groupContext = useContext(GroupContext);
  const location = useLocation();
  const { isOpen: isSessionTimeoutOpen, toggle: toggleSessionTimeout } =
    useModal();
  const { isToastOpen, toggleToast, ...toast } = useToast();

  const [step, setStep] = useState(0);
  const [btnText, setBtnText] = useState("Next");
  const [participantName, setParticipantName] = useState("");
  const [groups, setGroups] = useState<SpendGroup[]>([]);
  const [groupOptions, setGroupOptions] = useState<SnapSelectMenuOption[]>([]);
  const [seasons, setSeasons] = useState<
    Maybe<Maybe<SpendSeason>[]> | undefined
  >([]);
  const [seasonOptions, setSeasonOptions] = useState<SnapSelectMenuOption[]>(
    []
  );
  const [signUpErrors, setSignUpErrors] = useState<signUpErrors>({
    nameError: false,
    groupError: false,
    seasonError: false,
  });
  const [group, setGroup] = useState<SpendGroup>({});
  const [invoices, setInvoices] = useState<SpendInvoice[]>([]);
  const [settings, setSettings] = useState<SpendSettings>({});
  const [paymentTiming, setPaymentTiming] = useState<
    PaymentTimingValue | undefined
  >(undefined);
  // options
  const [selectedPaymentTypeOption, setSelectedPaymentTypeOption] = useState<
    `Pay by: ${"Card" | "Bank"}` | undefined
  >();
  const [paymentTypeOptions, setPaymentTypeOptions] = useState<
    SnapSelectMenuOption[]
  >(defaultSelectedPaymentOption);

  //bank
  const [selectedBankAccount, setSelectedBankAccount] = useState<
    SpendBankAccount | undefined
  >();

  //card
  const [cards, setCards] = useState<PaymentsApiCustomerPaymentMethod[]>([]);
  const [cardOptions, setCardOptions] = useState<SnapSelectMenuOption[]>([]);
  const [selectedCard, setSelectedCard] = useState<
    PaymentsApiCustomerPaymentMethod | undefined
  >();

  const [total, setTotal] = useState(0);
  const [invoicesToPay, setInvoicesToPay] = useState<SpendInvoice[]>([]);
  const [accountId, setAccountId] = useState("");
  const [userAgreed, setUserAgreed] = useState(false);
  const [authorizedAt, setIsAuthorizedAt] = useState<string | undefined>();
  const [optInList, setOptInList] = useState<string[]>([]);
  const [email, setEmail] = useState("");
  const [widgetOpen, setWidgetOpen] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [season, setSeason] = useState<SpendSeason | undefined>(undefined);
  const [invite, setInvite] = useState<SpendPendingInvite | undefined>(
    undefined
  );
  const [submitting, setSubmitting] = useState(false);
  const [triedToProcessPayment, setTriedToProcessPayment] = useState(false);
  const [canCancelInvite, setCanCancelInvite] = useState(false);
  let modalPromptTimer: NodeJS.Timeout | null = null;

  const [getInvoices, { loading: loadingInvoices, data: invoiceData }] =
    useLazyQuery(PARENT_SIGNUP_INVOICES);
  const [getSettings, { loading: loadingSettings, data: settingsData }] =
    useLazyQuery(GET_SETTINGS);
  const [getGroupById, { loading: loadingGroupById, data: GroupByIdData }] =
    useSpendSignupGroupIdLazyQuery();
  const [
    getPaymentMethods,
    { loading: loadingPaymentMethod, data: paymentMethodData },
  ] = usePaymentsApiCustomerMutation();
  const [updateUserStatus] = useSpendUserSignupUpdateMutation({
    onCompleted: ({ spendUserSignupUpdate }) => {
      console.log("completed user status update", spendUserSignupUpdate);
      navigate("/dashboard");
      setTimeout(() => {
        // @ts-ignore
        window.location.reload(false);
      }, 1000);
    },
    onError: (error) => {
      setSubmitting(false);
    },
  });

  const [createPaymentMethod] = useSpendPaymentMethodCreateMutation({
    onCompleted: ({ spendPaymentMethodCreate }) => {
      console.log(
        "completed payment method creation",
        spendPaymentMethodCreate
      );
      updateUserStatus({
        variables: {
          input: {
            status: "completed",
            groupId: group.id ?? "",
            seasonId: season?.id ?? "",
            inviteId: invite?.id,
          },
        },
        refetchQueries: [],
      });
    },
    onError: (error) => {
      const message = error.message || "(UNW)";
      console.log(message);
      toast.setToastProps({
        message:
          "Unable to process your payment: Please contact support to assist.",
        type: "danger",
      });
      toggleToast();
      setTriedToProcessPayment(false);
      setSubmitting(false);
    },
  });

  const [
    createAccount,
    { loading: loadingAcct, data: acctData, error: acctError },
  ] = useMutation(USER_SIGN_UP);
  const [
    deleteSession,
    { loading: loadingDeleteSession, data: deleteSessionData },
  ] = useMutation(DELETE_SESSION);
  const [createSession, { loading: loadingSession, data: sessionData }] =
    useMutation(CREATE_SESSION);

  const [
    updateUserInviteStatus,
    { loading: loadingInviteUpdate, data: updateInviteStatus },
  ] = useMutation(UPDATE_USER_INVITE_STATUS);
  const [changeRole] = useSpendUserRoleSetMutation();

  useEffect(() => {
    if (!loadingInviteUpdate && updateInviteStatus) {
      navigate("/dashboard");
      window.location.reload();
    }
    // eslint-disable-next-line
  }, [loadingInviteUpdate, updateInviteStatus]);

  useEffect(() => {
    if (!loadingDeleteSession && deleteSessionData) {
      createSession();
    }
    // eslint-disable-next-line
  }, [loadingDeleteSession, deleteSessionData]);

  useEffect(() => {
    if (!loadingAcct && acctError) {
      toast.setToastProps({
        message: acctError.message,
        type: "danger",
      });
      toggleToast();
      setSigningUp(false);
      setSignUpErrors({
        ...signUpErrors,
        nameError: true,
      });
    }

    if (!loadingAcct && acctData && acctData.spendUserSignUp) {
      if (userContext._session?.id) {
        deleteSession();
      } else {
        createSession();
      }
    }
    // eslint-disable-next-line
  }, [loadingAcct, acctData]);

  useEffect(() => {
    if (!loadingSession && sessionData && sessionData.spendSessionCreate) {
      getGroupById({
        variables: {
          spendGroupByIdId: sessionData.spendSessionCreate.role.groupId,
        },
      });
      const sessionInviteId = sessionData.spendSessionCreate.inviteId;
      const pendingInvites = sessionData.spendSessionCreate?.pendingInvites;
      let invite: SpendPendingInvite;
      if (sessionInviteId) {
        const foundInvite = pendingInvites.find(
          (i: SpendPendingInvite) => i.id === sessionInviteId
        );
        invite = foundInvite;
      } else {
        const lastInvite = pendingInvites.at(pendingInvites.length - 1);
        invite = lastInvite;
      }
      setInvite(invite);

      getInvoices({
        variables: {
          filterBy: InvoiceFilterEnum.InviteId,
          filterValue: invite.id,
          parentSignUp: true,
        },
      });
      getSettings();
      setStep(1);
    }
    // eslint-disable-next-line
  }, [loadingSession, sessionData]);

  useEffect(() => {
    setIsAuthorizedAt(isAuthorized ? new Date().toISOString() : undefined);
    // eslint-disable-next-line
  }, [isAuthorized]);

  useEffect(() => {
    if (paymentMethodData && paymentMethodData.paymentsApiCustomer) {
      setWidgetOpen(
        paymentMethodData.paymentsApiCustomer.paymentMethods.length === 0
      );
      setCards(
        paymentMethodData.paymentsApiCustomer
          .paymentMethods as PaymentsApiCustomerPaymentMethod[]
      );
    }
  }, [loadingPaymentMethod, paymentMethodData]);

  useEffect(() => {
    if (
      !loadingInvoices &&
      !loadingGroupById &&
      invoiceData &&
      invoiceData.spendInvoices &&
      GroupByIdData &&
      GroupByIdData.spendGroupById
    ) {
      let invoice = invoiceData.spendInvoices.invoices as SpendInvoice[];
      const email = userContext.getEmail();
      setInvoices(invoice);
      setEmail(email);
      setInvoices(invoice);
      getPaymentMethods();
      setGroup(GroupByIdData.spendGroupById);
      const foundSeason = GroupByIdData.spendGroupById.seasons?.find(
        (season) => season?.id === invite?.seasonId
      );
      if (foundSeason) {
        setSeason(foundSeason);
      }
      const inviteCount = userContext._session?.pendingInvites?.length ?? 0;
      if (inviteCount > 1) {
        setCanCancelInvite(true);
      }
      groupContext?.setAndStoreActiveGroup(GroupByIdData.spendGroupById);
      setAccountId(GroupByIdData.spendGroupById.accountId ?? "");
    }
    if (settingsData && settingsData.spendSettings) {
      setSettings(settingsData.spendSettings);
      setIsLoading(false);
    }
    setSigningUp(false);
    // eslint-disable-next-line
  }, [
    loadingGroupById,
    GroupByIdData,
    loadingInvoices,
    invoiceData,
    loadingSettings,
    settingsData,
    setIsLoading,
  ]);

  useEffect(() => {
    let tempInvoices =
      invoices &&
      invoices.filter(
        (invoices) =>
          !invoices.isOptional ||
          invoices.optedIn ||
          optInList.includes(invoices.id ?? "")
      );
    let updatedInvoices = tempInvoices.map((invoice) => {
      return {
        ...invoice,
        balanceDue:
          selectedPaymentTypeOption === "Pay by: Bank"
            ? calcTotalPlusFee(
                invoice.balanceDue ?? 0,
                group?.organizationFees?.achPercent ?? 0,
                group?.organizationFees?.achBaseFee ?? 0
              )
            : calcTotalPlusFee(
                invoice.balanceDue ?? 0,
                group?.organizationFees?.cardPercent ?? 0,
                group?.organizationFees?.cardBaseFee ?? 0
              ),
      };
    });
    let tempTotal = 0;
    updatedInvoices.forEach((invoice) => {
      tempTotal += invoice.balanceDue ?? 0;
    });
    setInvoicesToPay(updatedInvoices);
    setTotal(tempTotal);
    // eslint-disable-next-line
  }, [selectedPaymentTypeOption, optInList.join(",")]);

  useEffect(() => {
    const amount = MapAndCalcSum(
      invoices.filter(
        (inv) => inv.isOptional === false || optInList.includes(inv.id!)
      ),
      "balanceDue"
    );
    if (
      group.minimumDiscountPurchase == null ||
      group.discountAmount == null ||
      group.discountCutOffDate == null ||
      group.enableDiscount !== true ||
      !(
        amount >= group.minimumDiscountPurchase &&
        new Date(group.discountCutOffDate).getTime() >= new Date().getTime()
      )
    ) {
      setShowDiscount(false);
    } else {
      setShowDiscount(true);
    }
  }, [invoices, group, optInList]);

  useEffect(() => {
    restartAutoReset();

    window.addEventListener("mousemove", restartAutoReset);

    return () => {
      if (modalPromptTimer) {
        clearTimeout(modalPromptTimer);
        window.removeEventListener("mousemove", restartAutoReset);
      }
    };
    // eslint-disable-next-line
  }, [location.pathname]);

  const restartAutoReset = () => {
    if (modalPromptTimer) {
      clearTimeout(modalPromptTimer);
    }
    modalPromptTimer = setTimeout(() => {
      toggleSessionTimeout();
    }, 60000 * 15);
  };

  const paymentMethodCreate = () => {
    setWidgetOpen(false);
    getPaymentMethods();
  };

  const handleUpdateInvoice = (updatedInvoice: SpendInvoice, idx: number) => {
    let tempInvoices = [...invoices];
    tempInvoices.splice(idx, 1, updatedInvoice);
    setInvoices(tempInvoices);
  };

  const handlePrev = () => {
    if (step !== 0) {
      setStep(step - 1);
    }
    if (showDiscount && paymentTiming === "All" && step === 1) {
      const updatedInvoicesToPay = invoicesToPay.map((inv) => ({
        ...inv,
        balanceDue: inv.balanceDue! + (inv.discountAmount || 0),
        discountAmount: 0,
      }));
      setTotal(total + (group.discountAmount || 0));
      setInvoicesToPay(updatedInvoicesToPay);
    }
  };

  const handleNext = () => {
    if (step === 0) {
      const selectedGroup = groups.find(
        (group) =>
          group.id === groupOptions.find((group) => group.selected)?.value
      );
      const selectedSeason = seasons?.find(
        (season) =>
          season?.id === seasonOptions.find((season) => season.selected)?.value
      );
      const hasNameError = participantName === "";
      const hasGroupError = !selectedGroup;
      const hasSeasonError = !selectedSeason;
      if (hasNameError || hasGroupError || hasSeasonError) {
        setSignUpErrors({
          nameError: hasNameError,
          groupError: hasGroupError,
          seasonError: hasSeasonError,
        });
        toast.setToastProps({
          message: "Please fill in all the input fields",
          type: "danger",
        });
        toggleToast();
      } else {
        setSigningUp(true);
        createAccount({
          variables: {
            input: {
              orgId: location.pathname.split(
                "/signup/parents/organization/"
              )[1],
              groupId: selectedGroup?.id,
              seasonId: selectedSeason?.id,
              participantName,
            },
          },
        });
      }
    } else {
      if (!selectedPaymentTypeOption) {
        toast.setToastProps({
          message: "Please Select a Payment Method",
          type: "danger",
        });
        toggleToast();
      } else if (
        !selectedBankAccount &&
        selectedPaymentTypeOption === "Pay by: Bank"
      ) {
        toast.setToastProps({
          message: "Please Select an Account",
          type: "danger",
        });
        toggleToast();
      } else if (
        !selectedCard &&
        selectedPaymentTypeOption === "Pay by: Card"
      ) {
        toast.setToastProps({
          message: "Please Select a Card",
          type: "danger",
        });
        toggleToast();
      } else if (!paymentTiming) {
        toast.setToastProps({
          message: "Please select a Payment Timing",
          type: "danger",
        });
        toggleToast();
      } else {
        if (step + 1 <= 2) {
          setStep(step + 1);
        }
        if (step + 1 === 2) {
          let totalOverride = total;
          if (showDiscount && paymentTiming === "All") {
            const discounts = group.discountAmount
              ? calcDiscount(group.discountAmount, invoicesToPay)
              : [];
            const invoicesToPayUpdate = invoicesToPay.map((inv) => {
              const discountValue =
                discounts.find((id) => id.invoiceId === inv.id)
                  ?.discountAmount || 0;
              return {
                ...inv,
                balanceDue: (inv.balanceDue ?? 0) - discountValue,
                discountAmount: discountValue,
              };
            });
            totalOverride = total - (group.discountAmount || 0);
            setTotal(totalOverride);
            setInvoicesToPay(invoicesToPayUpdate);
          }
          setBtnText(
            paymentTiming === "All"
              ? `Pay ${FormatMoney(totalOverride)} Now`
              : paymentTiming === "AutoPay"
              ? "Authorize AutoPay"
              : "Confirm"
          );
        } else if (step === 2) {
          if (!isAuthorized) {
            toast.setToastProps({
              message: "Please authorize payment",
              type: "danger",
            });
            toggleToast();
          } else if (group.isRequireAgreement && !userAgreed) {
            toast.setToastProps({
              message: "Please review and accept the agreement",
              type: "danger",
            });
            toggleToast();
          } else {
            submitPaymentMethod();
          }
        }
      }
    }
  };

  const submitPaymentMethod = async () => {
    setSubmitting(true);

    const paymentMethodSource =
      selectedPaymentTypeOption === "Pay by: Bank" ? "ACH" : "CARD";
    let paymentMethodId =
      paymentMethodSource === "CARD"
        ? selectedCard?.id
        : selectedBankAccount?.id || "";

    if (!triedToProcessPayment) {
      setTriedToProcessPayment(true);
      const invoiceIds = invoicesToPay.reduce((acc, invoice) => {
        if (invoice.id) {
          acc.push(invoice.id);
        }
        return acc;
      }, [] as string[]);
      await createPaymentMethod({
        variables: {
          input: {
            amount: total,
            isAutoPayAuthorized: paymentTiming === "AutoPay",
            paymentMethodSource: paymentMethodSource,
            paymentMethodId: paymentMethodId ?? "",
            paymentMethodTiming: paymentTiming,
            invoiceIds,
            hasApprovedAgreement: userAgreed,
            authorizedAt: authorizedAt,
            discountAmounts: showDiscount
              ? calcDiscount(group.discountAmount, invoicesToPay)
              : undefined,
            inviteId: invite?.id,
          },
        },
      });
    } else {
      toast.setToastProps({
        message: "Please change your payment method or try again later.",
        type: "danger",
      });
      toggleToast();
    }
  };

  const handleCancelInvite = async () => {
    await updateUserInviteStatus({
      variables: {
        spendUserInviteStatusUpdateId: invite?.id,
        status: "canceled",
      },
    });
    const role = userContext
      ?.getAllRoles()
      ?.find((role) => role.isNotSignedUp === false);
    if (role) {
      changeRole({
        variables: {
          roleId: role.id,
        },
      });
    }
  };

  return (
    <div className={`bg-gray-100 pt-4 lg:pb-4 pb-6 px-6 h-full`}>
      {isToastOpen && (
        <ToastMessage
          message={toast.message}
          isToastOpen={isToastOpen}
          toggleToast={toggleToast}
          type={toast.type}
        />
      )}
      <div
        className={`flex flex-col bg-white border border-gray-300 rounded-lg h-full pt-6 lg:pt-16 px-4 mb-14`}
      >
        <div className="flex self-end">
          <SnapLink
            href="https://helpdesk.snapraise.com/support-center/guardian-quick-start-guide-spend"
            target="_blank"
          >
            Review our support center article for assistance
          </SnapLink>
        </div>
        {!signingUp ? (
          <>
            {
              {
                0: (
                  <SignUp
                    setParticipantName={setParticipantName}
                    groups={groups}
                    setGroups={setGroups}
                    groupOptions={groupOptions}
                    setGroupOptions={setGroupOptions}
                    setSeasons={setSeasons}
                    seasonOptions={seasonOptions}
                    setSeasonOptions={setSeasonOptions}
                    signUpErrors={signUpErrors}
                    setSignUpErrors={setSignUpErrors}
                  />
                ),
                1: (
                  <ReviewInvoices
                    paymentTiming={paymentTiming}
                    setPaymentTiming={setPaymentTiming}
                    group={group}
                    invoices={invoices}
                    playerName={participantName}
                    handleUpdateInvoice={handleUpdateInvoice}
                    paymentTypeOptions={paymentTypeOptions}
                    setPaymentTypeOptions={setPaymentTypeOptions}
                    selectedPaymentTypeOption={selectedPaymentTypeOption}
                    setSelectedPaymentTypeOption={setSelectedPaymentTypeOption}
                    setSelectedBankAccount={setSelectedBankAccount}
                    accountId={accountId}
                    optInList={optInList}
                    setOptInList={setOptInList}
                    email={email}
                    getPaymentMethods={getPaymentMethods}
                    paymentMethodCreate={paymentMethodCreate}
                    widgetOpen={widgetOpen}
                    dotState={1}
                    dotMarker={3}
                    showDiscount={showDiscount}
                    seasonName={season?.name ?? ""}
                    invoicesLoading={loadingInvoices}
                    cardOptions={cardOptions}
                    cards={cards}
                    setSelectedCard={setSelectedCard}
                    setCardOptions={setCardOptions}
                  />
                ),
                2: (
                  <NewAuthorizePayments
                    settings={settings}
                    group={group}
                    invoices={invoices}
                    playerName={participantName}
                    paymentTiming={paymentTiming}
                    selectedPaymentTypeOption={selectedPaymentTypeOption}
                    total={total}
                    setTotal={setTotal}
                    agreed={userAgreed}
                    setAgreed={setUserAgreed}
                    bankAccount={selectedBankAccount}
                    invoicesToPay={invoicesToPay}
                    paymentMethods={selectedCard}
                    isAuthorized={isAuthorized}
                    setIsAuthorized={setIsAuthorized}
                    dotState={2}
                    dotMarker={3}
                  />
                ),
              }[step]
            }
          </>
        ) : (
          <div className="h-screen">
            <SpinnerContainer loading={signingUp} />
          </div>
        )}
        <div
          className={`flex justify-center mt-auto pt-4 mb-6 lg:justify-end lg:mt-4 mx-4 lg:mx-0`}
        >
          {step === 1 && canCancelInvite && (
            <SnapButton
              variant="danger"
              className="mr-auto w-full lg:w-48"
              fullWidth
              onClick={handleCancelInvite}
            >
              Cancel Invite
            </SnapButton>
          )}
          {step === 2 && (
            <SnapButton
              icon="arrow-left-line"
              variant="tertiary"
              className="mr-4 w-full lg:w-48"
              fullWidth
              disabled={submitting}
              onClick={handlePrev}
            >
              Back
            </SnapButton>
          )}
          {step !== 2 ? (
            <SnapButton
              icon="arrow-right-line"
              iconPosition="right"
              variant="primary"
              className="w-full lg:w-48"
              fullWidth
              onClick={handleNext}
            >
              Next
            </SnapButton>
          ) : (
            <div className="relative">
              {submitting && (
                <Spinner
                  size={"small"}
                  className={"absolute right-2 top-1/2 -translate-y-1/2"}
                />
              )}
              <SnapButton
                variant="primary"
                className="w-full lg:w-48"
                fullWidth
                onClick={handleNext}
                disabled={submitting || !isAuthorized}
              >
                {btnText}
              </SnapButton>
            </div>
          )}
        </div>
      </div>
      {isSessionTimeoutOpen && (
        <SessionTimeout
          isSessionTimeoutOpen={isSessionTimeoutOpen}
          toggleSessionTimeout={toggleSessionTimeout}
        />
      )}
    </div>
  );
}

export default DisplaySignupFlow;
