import {
  PaymentsApiCustomerPaymentMethod,
  SpendBankAccount,
  SpendGroup,
  SpendInvoice,
  SpendSettings,
} from "graphql/generated";
import { FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { MapAndCalcSum } from "helpers/map-and-reduce";
import LabelValue from "pages/groups/collections/label-value";
import { useState } from "react";
import Divider from "shared-components/divider";
import DotProgress from "shared-components/dot-progress";
import { SnapCheckboxButton, SnapIcon, SnapTable } from "suit";
import { PaymentTimingValue } from "../../../types/invoice";
import DefaultPaymentMethod from "./default-payment-method";
import ParentInviteInfo from "../parent-invite-info";

type ConfirmAutopayPaymentsProps = {
  settings: SpendSettings;
  group: SpendGroup;
  invoices: SpendInvoice[];
  playerName: string;
  paymentTiming: PaymentTimingValue | undefined;
  selectedPaymentTypeOption: "Pay by: Card" | "Pay by: Bank" | undefined;
  total: number;
  setTotal: React.Dispatch<React.SetStateAction<number>>;
  agreed: boolean;
  setAgreed: React.Dispatch<React.SetStateAction<boolean>>;
  invoicesToPay: SpendInvoice[];
  bankAccount: SpendBankAccount | undefined;
  paymentMethods: PaymentsApiCustomerPaymentMethod | undefined;
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  dotState: number;
  dotMarker: number;
};

function ConfirmAutopayPayments({
  settings,
  group,
  invoices,
  invoicesToPay,
  playerName,
  total,
  selectedPaymentTypeOption,
  bankAccount,
  agreed,
  setAgreed,
  paymentMethods,
  isAuthorized,
  setIsAuthorized,
  dotState,
  dotMarker,
}: ConfirmAutopayPaymentsProps) {
  const [discountTotal] = useState(
    MapAndCalcSum(invoicesToPay, "discountAmount")
  );

  return (
    <div>
      <DotProgress state={dotState} markerCount={dotMarker} />
      <h2 className="lg:text-2xl font-semibold">Authorize AutoPay</h2>

      <ParentInviteInfo group={group} playerName={playerName} />

      <Divider isVisibleOnMobile className={"my-6"} />

      {invoices?.length && invoicesToPay?.length ? (
        <>
          <p className="mt-4 font-medium lg:text-lg lg:font-semibold">
            Invoices
          </p>
          <div className="lg:hidden">
            {invoicesToPay.map((invoice, idx) => {
              return (
                <div
                  key={invoice.id + `${idx}`}
                  className="border border-gray-200 p-4 rounded-lg mt-4 break-all"
                >
                  <p>{invoice.description}</p>
                  <p className="text-sm">{FormatDate(invoice.dueDate)}</p>
                  <Divider isVisibleOnMobile />
                  <LabelValue
                    label={"Amount"}
                    value={FormatMoney(
                      (invoice.balanceDue || 0) + (invoice.discountAmount || 0)
                    )}
                    labelColor={"text-gray-500"}
                    valueColor={""}
                    className="mt-4"
                  />
                </div>
              );
            })}

            {discountTotal > 0 && (
              <LabelValue
                label={"Discount"}
                value={`-${FormatMoney(discountTotal)}`}
                labelColor={"text-gray-500"}
                valueColor={""}
                className="mt-10 mx-4"
              />
            )}
            <LabelValue
              label={"Total"}
              value={FormatMoney(total)}
              labelColor={"text-gray-500"}
              valueColor={""}
              className="mt-5 mx-4"
            />
          </div>
          <SnapTable>
            <table className="ui celled hidden lg:table">
              <thead>
                <tr>
                  <th className="w-[25%]">Description</th>
                  <th align="left">Billing Date</th>
                  <th align="right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {invoicesToPay.map((invoice, idx) => {
                  return (
                    <tr key={invoice.id + `${idx}`} className="break-all">
                      <td className="py-4">
                        <p>{invoice.description}</p>
                      </td>
                      <td>{FormatDate(invoice.dueDate)}</td>
                      <td align="right">
                        {FormatMoney(
                          (invoice.balanceDue || 0) +
                            (invoice.discountAmount || 0)
                        )}
                      </td>
                    </tr>
                  );
                })}
                <tr className="bg-gray-100">
                  <td></td>
                  <td></td>
                  <td className="flex flex-col justify-center items-end pr-4 h-18 pb-5 gap-4">
                    {discountTotal > 0 && (
                      <p className="text-gray-500 font-medium">
                        DISCOUNT
                        <span className="ml-10 text-gray-800">
                          -{FormatMoney(discountTotal)}
                        </span>
                      </p>
                    )}
                    <p className="text-gray-500 font-medium">
                      TOTAL
                      <span className="ml-10 text-gray-800">
                        {FormatMoney(total)}
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </SnapTable>
          <Divider isVisibleOnMobile className="mt-6" />
        </>
      ) : null}

      <DefaultPaymentMethod
        paymentMethods={paymentMethods}
        bankAccount={
          selectedPaymentTypeOption === "Pay by: Bank" ? bankAccount : undefined
        }
      />
      <div className="flex my-4">
        <div className=" w-52">
          <p className="text-gray-500">Payment Timing</p>
        </div>
        <div>
          <p className="text-end">Autopay</p>
        </div>
      </div>
      <SnapCheckboxButton
        label={
          invoicesToPay?.length
            ? "I authorize Snap! Spend to charge my payment method according to the above schedule."
            : "I authorize Snap! Spend to charge my payment method."
        }
        className="mt-4"
        checked={isAuthorized}
        onClick={() => setIsAuthorized(!isAuthorized)}
      />
      <Divider isVisibleOnMobile />
      {group.isRequireAgreement && (
        <>
          <p className="mt-4">Agreement with {group.name}</p>
          <div className="flex justify-between border border-gray-200 rounded-lg pt-2 pb-10 px-3 bg-gray-100 mt-1">
            <p>{settings.signUpAgreement?.content}</p>
            <SnapIcon
              icon="lock-closed-solid"
              size="sm"
              color="#94A3B8"
              className="items-start"
            />
          </div>
          <SnapCheckboxButton
            label={`I agree to the ${settings.signUpAgreement?.name} above.`}
            className="mt-4"
            checked={agreed}
            onClick={() => setAgreed(!agreed)}
          />
          <Divider isVisibleOnMobile />
        </>
      )}
      <h3 className="font-medium mt-4 md:mt-6">Summary</h3>
      <ul className="list-disc ml-6 mt-4 text-gray-500">
        <li>
          You will be charged on the billing date listed above for each invoice.
        </li>
        <li>
          You can easily manage payments at any time from your Snap! Spend
          account.
        </li>
        <li>
          You will be sent a reminder email three days before each billing date.
        </li>
        <li>
          It can take up to 5 business days for the funds to be withdrawn from
          your checking account.
        </li>
      </ul>
    </div>
  );
}

export default ConfirmAutopayPayments;
